import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";
import PlayerToken from "../playerToken";

export default class Wheel extends Game {

    constructor(
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        super(house, gameSpecPubkey);
    }

    static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
        const game = new Wheel(house, gameSpecPubkey);
        await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)
        return game;
    }

    async soloBetIx(
        ownerOrAuth: PublicKey,
        playerToken: PlayerToken,
        inputs: object,
        wager: number,
        clientSeed: number[]
    ): Promise<TransactionInstruction> {
        // INPUTS ARE 
        // {
        //     reelIdx: number,
        //     numSpins: number,
        //     wagerPerSpin: number
        // }

        const instanceRequest = {
            wheel: {
                reelIdx: inputs.reelIdx,
                numSpins: inputs.numSpins,
            }
        };

        // const betRequests = {
        //     wheel: {
        //         wagerPerSpin: new anchor.BN(wager)
        //     }
        // };
        const wagerPerSpin = new anchor.BN(wager);
        const betRequests = Array(inputs.numSpins).fill(wagerPerSpin).map((wager, spinIdx) => ({
            wheel: {
                spinIdx,
                wager
            },
        }));

        const numberOfBets = inputs.numSpins;

        return await this.soloPlayIx(
            ownerOrAuth,
            playerToken,
            numberOfBets,
            instanceRequest,
            betRequests,
            null,
            clientSeed
        );
    }
    get state() {
        return this.baseState
    }
    get gameConfig() {
        return this.state ? this.state.config.wheel : null;
    }
    get maxMultiplier() {
        return this.gameConfig != null
            ? Number(this.gameConfig.maxMultiplierPerMillion) / 1_000_000
            : null;
    }
    get houseEdge() {
        return this.gameConfig ? Number(this.gameConfig.edgePerMillion) / 1_000_000 : null;
    }

    get multiplierRoundingUnit() {
        return this.gameConfig ? Number(this.gameConfig.roundingDenominator) / 1_000_000 : null;
    }
    veryifyGameInstanceResultGivenRandomness(
        randomness: number[],
        tableSize: number,
        numberSelected: number
    ) {

        var drawnNumbers: number[] = [];

        for (let i = 0; i < numberSelected; i++) {

            const twoByteBuffer = Buffer.from(randomness.slice((i * 2), (i + 1) * 2));
            const r = twoByteBuffer.readUInt16LE(0);

            var v = (r % (tableSize - i)) + 1;

            while (drawnNumbers.includes(v)) {
                v += 1;
            }

            drawnNumbers.push(v);

        };

        return {
            drawnNumbers: drawnNumbers
        }
    };

    getBetMetas(bets: object[]) {
        let wager = 0
        let profit = 0
        let payout = 0
        let edgePercentage = 0

        const reelMaxMultipliersPerMillion = this.gameConfig?.reelMaxMultiplierPerMillion
        const reelEdgesPerMillion = this.gameConfig?.reelEdgesPerMillion

        bets.forEach((bet) => {
            const maxMultiplier = reelMaxMultipliersPerMillion?.[bet?.reelIdx] / Math.pow(10, 6);
            const edge = reelEdgesPerMillion?.[bet?.reelIdx] / Math.pow(10, 6);
            edgePercentage = edge;

            wager += bet.wager;

            const betMaxPayout = maxMultiplier * bet.wager;
            payout += betMaxPayout;
            const betMaxProfit = betMaxPayout - bet.wager;
            profit += betMaxProfit;
        })

        return {
            bets: bets,
            wager: wager,
            profit: profit,
            payout: payout,
            edgePercentage: edgePercentage
        }
    }
}