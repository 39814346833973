import { useMemo } from "react";
import { useErrorMessageValidation } from "./useErrorMessageValidation";
import useIpAddressCheck from "../useIpAddressCheck";

export const useCasinoErrorHandling = () => {
  const networkValidation = useErrorMessageValidation();
  const houseValidation = useErrorMessageValidation();
  const platformValidation = useErrorMessageValidation();
  const walletValidation = useErrorMessageValidation();
  const gameValidation = useErrorMessageValidation();
  const playerValidation = useErrorMessageValidation();

  // CHECK FOR IP RESTRICTION
  const { loactionValidation } = useIpAddressCheck();

  return useMemo(() => {
    return {
      networkValidation: networkValidation,
      houseValidation: houseValidation,
      platformValidation: platformValidation,
      walletValidation: walletValidation,
      gameValidation: gameValidation,
      locationValidation: loactionValidation,
      playerValidation: playerValidation
    };
  }, [
    networkValidation.errorMessages,
    houseValidation.errorMessages,
    platformValidation.errorMessages,
    walletValidation.errorMessages,
    gameValidation.errorMessages,
    loactionValidation.errorMessages,
    playerValidation.errorMessages
  ]);
};
