import { FC } from "react";
import Icon, { IconSizes } from "../icon/Icon";


export interface TokenAmountProps {
    token: string;
    amount: number | string;
    iconSize?: IconSizes;
}

export const TokenAmount: FC<TokenAmountProps> = ({ token, amount, iconSize = "xs" }) => {
    return (
        <div className="flex items-center gap-1">
            <Icon size={iconSize} iconUrl={`/static/tokens/${token}.png`} className="mb-1" />
            <span className="text-sm">{amount}</span>
        </div>
    );
};
