import translations from "../../config/translation.json";
import game_list from "../../config/game_list.json";
import token_info from "../../config/token_info.json";
import game_info_list from "../../config/game_info_list.json";
import token_info_mb from "../../config/token_info_mb.json";
import game_list_mb from "../../config/game_list_mb.json";
import supported_translations from "../../config/supported_translations.json";

import { AverLanguage } from "../../types/language";
import { ICasinoToken } from "../../types/token";
import { NetworkType, defaultNetwork } from "../chain/network";
import { IPlatformGame } from "../../types/game";
import { APP_NETWORK_TYPE } from "../../types/chain";

import zeebro_jsons_devnet from "../../config/metplex/zeebros_json_devnet.json"
import zeebro_jsons_mainnet from "../../config/metplex/zeebros_json_mainnet.json"

export const supportedLanguages = (language?: AverLanguage) => {
  if (!language) return supported_translations;
  return supported_translations.find((lang) => lang.code === language);
};

export const translateSequence = (sequence: string, language: AverLanguage): string => {
  try {
    return translations[sequence.toUpperCase()][language.toUpperCase()];
  } catch (e) {
    console.warn(`Issue retrieving translation for ${sequence} in ${language}.`, e);
    return sequence;
  }
};

export const getPlatformTokens = (environment: NetworkType, chain: APP_NETWORK_TYPE): ICasinoToken[] => {
  switch (chain) {
    case APP_NETWORK_TYPE.SOLANA:
      return token_info[environment];
    case APP_NETWORK_TYPE.MB_AND_SOLANA:
      return token_info_mb[environment];
    default:
      throw new Error(`Not a recognised chain config --> ${chain}`)
  }
};

export const getPlatformTokenMetaByPubkey = (chain: APP_NETWORK_TYPE): Map<string, ICasinoToken> => {
  const tokens = getPlatformTokens(defaultNetwork, chain);
  const metaByPubkey = new Map<string, ICasinoToken>();
  tokens.forEach((token) => {
    metaByPubkey.set(token.pubkey, token);
  });

  return metaByPubkey;
};

export const getChainName = (chain: APP_NETWORK_TYPE) => {
  switch (chain) {
    case APP_NETWORK_TYPE.MB_AND_SOLANA:
      return "Solana"
    case APP_NETWORK_TYPE.SOLANA:
      return "Solana"
  }
}

const getGamesInfo = (gamesCredentials: { enum: string }[]) => {
  return gamesCredentials.map((game) =>
    ({ ...game, ...game_info_list[game.enum], isComingSoon: !game.gameSpecPubkey || game.isComingSoon })
  ).sort((a, b) => a.sortOrder - b.sortOrder)
    .sort((a, b) => a.isNew && !b.isNew1 ? -1 : !a.isNew && b.isNew1 ? 1 : 0)
    .sort((a, b) => a.isComingSoon && !b.isComingSoon ? 1 : !a.isComingSoon && b.isComingSoon ? -1 : 0)
}

export const getPlatformGames = (chain: APP_NETWORK_TYPE): IPlatformGame[] => {
  const environment = defaultNetwork

  // SHOW GAMES WITH LOCAL STORAGE VARIABLE ON SOLANA
  const showTestFeatures = localStorage.getItem('zeebit-test-features') != null;

  return getGamesInfo(game_list_mb[environment].map((game) => {
    if (game.gameSpecPubkey.length == 0) {
      return game
    }
    return game
  }))
};

interface IPeriodsBeginningTimestamps {
  beginningOfMonth: number;
  beginningOfDay: number;
  beginningOfWeek: number;
}
export const getTimestampsOfBeginningOfPeriods = (): IPeriodsBeginningTimestamps => {
  const currentDate = new Date();

  const beginningOfMonth = new Date(currentDate);
  beginningOfMonth.setUTCDate(1);
  beginningOfMonth.setUTCHours(0, 0, 0, 0);
  const beginningOfMonthTimestamp = beginningOfMonth.getTime();

  const beginningOfWeek = new Date(currentDate);
  const diff = (currentDate.getUTCDay() + 6) % 7;
  beginningOfWeek.setUTCDate(currentDate.getUTCDate() - diff);
  beginningOfWeek.setUTCHours(0, 0, 0, 0);
  const beginningOfWeekTimestamp = beginningOfWeek.getTime();

  currentDate.setUTCHours(0, 0, 0, 0);
  const beginningOfDayTimestamp = currentDate.getTime();

  return {
    beginningOfMonth: beginningOfMonthTimestamp,
    beginningOfWeek: beginningOfWeekTimestamp,
    beginningOfDay: beginningOfDayTimestamp,
  };
};

export const getS3StaticFolderUrl = (url: string): string => {
  return url
}
// SHORT TERM FIX TO USE MAGIC EDEN IMAGE URLS AS IPFS IS DOWN
export const getMetaplexZeebroJsonByMint = () => {
  const zeebrosMap: any = defaultNetwork == NetworkType.MAINNET ? zeebro_jsons_mainnet : { ...zeebro_jsons_devnet, ...zeebro_jsons_mainnet }

  // TODO - UPDATE THE SOURCE JSON FILE
  // Object.keys(zeebrosMap).forEach((key) => {
  //   const url: string = zeebrosMap[key].image

  //   zeebrosMap[key] = {
  //     "image": formatToMagicEdenImage(url)
  //   }
  // })

  return zeebrosMap
}