import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ProgramContext } from "./ProgramContext";
import BetStream from "../sdk/betStream";
import { Program } from "@coral-xyz/anchor";
import { IBetHistory, IGameHistory } from "../sdk/types";
import { NetworkContext } from "./NetworkContext";
import { APP_NETWORK_TYPE } from "../types/chain";

export interface IBetMeta {
  id: string | undefined;
  gameResult: IGameHistory | undefined;
  betResult: IBetHistory | undefined;
}

export interface IBetstreamingContext {
  betStream: BetStream | undefined;
}

export const BetstreamingContext = createContext<IBetstreamingContext>({} as IBetstreamingContext);

interface Props {
  children: any;
}

export const BetstreamingProvider = ({ children }: Props) => {
  const { meta } = useContext(ProgramContext);

  const [betStream, setBetStream] = useState<BetStream>();
  const { chain, client } = useContext(NetworkContext)

  useEffect(() => {
    async function loadBetstream(
      baseProgram: Program,
      erProgram: Program,
      chain: APP_NETWORK_TYPE
    ) {
      try {
        const newStream = new BetStream(baseProgram, erProgram, chain);
        setBetStream(newStream);
      } catch (err) {
        console.warn("Error loading the betstream", err);
      }
    }

    if (
      meta == null ||
      meta.zeebitV2ErProgram == null ||
      meta.zeebitV2Program == null || 
      chain == null
    ) {
      setBetStream(undefined);
      return;
    }

    loadBetstream(meta.zeebitV2Program, meta.zeebitV2ErProgram, chain);
  }, [meta]);

  return (
    <BetstreamingContext.Provider
      value={useMemo(
        () => ({
          betStream: betStream,
        }),
        [betStream],
      )}
    >
      {children}
    </BetstreamingContext.Provider>
  );
};
