import React, { FC, useMemo } from "react";

export type IconSizes = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
export interface IconProps {
  icon?: React.ReactNode;
  iconUrl?: string;
  size?: IconSizes;
  weight?: "thin" | "regular";
  inheritStroke?: boolean;
  inheritFill?: boolean;
  className?: string;
}

const Icon: FC<IconProps> = ({
  icon,
  size = "md",
  iconUrl,
  weight = "regular",
  inheritStroke = true,
  inheritFill = false,
  className,
}) => {
  const iconSize = useMemo(() => {
    switch (size) {
      case "xxxl":
        return "w-[86px] h-[86px]";
      case "xxl":
        return "w-[30px] h-[30px]";
      case "xl":
        return "w-[20px] h-[20px]";
      case "lg":
        return "w-[18px] h-[18px]";
      case "md":
        return "w-[16px] h-[16px]";
      case "xs":
        return "w-[12px] h-[12px]";
      default:
        return "w-[14px] h-[14px]";
    }
  }, [size]);
  const weightClasses = useMemo(() => {
    switch (weight) {
      case "regular":
        return "[&>svg]:stroke-2 ";
      default:
        return "";
    }
  }, [weight]);
  return (
    <div
      className={`flex justify-center items-center  [&>svg]:w-full [&>svg]:h-full [&>svg>*]:w-full [&>svg>*]:h-full [&>img]:w-full [&>img]:h-full
      ${weightClasses}
      ${inheritStroke ? "[&>svg]:stroke-current" : ""}
      ${inheritFill ? "[&>svg]:fill-current" : ""}
      ${weight && "[&>svg]:stroke-" + weight === "regular" ? 2 : 1} ${iconSize} ${className}`}
    >
      {icon}
      {iconUrl && <img src={iconUrl} />}
    </div>
  );
};

export default Icon;
