import React, { useContext, useEffect, useRef } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { BrowserPreferenceContext } from "../contexts/BrowserPreferenceContext";
import { SideBar } from "../components/left-nav/SideBar";
import { TopNav } from "../components/top-nav/TopNav";
import { BottomNav } from "../components/bottom-nav/BottomNav";
import { NavContext } from "../contexts/NavContext";
import { SCROLLBAR_CLASSES_BLACK } from "../styles/commonClasses";
import { BannerContext } from "../contexts/BannerContext";
import { Banner } from "../components/banners/Banner";
import { useModal } from "react-modal-hook";
import { GeoblockModal } from "../modals/GeoblockModal";
import { ErrorHandlingContext } from "../contexts/ErrorHandlingContext";
import { ErrorType } from "../types/error";
import {
  SessionExpiringModal,
  ZEEBIT_SHOULD_HIDE_SESSION_EXPIRING_MODAL,
} from "../components/modals/SessionExpiringModal";
import { PlayerTokenContext } from "../contexts/PlayerTokenContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useOnboardingHandler } from "../hooks/useOnboardingHandler";
import { NetworkContext, WrappedWalletContext } from "../contexts";
import { APP_NETWORK_TYPE } from "../types/chain";
import TopBanner from "../components/top-nav/TopBanner";
import { getPlatformGames } from "../utils/config/utils";
import { SessionAuthorityContext } from "../contexts/SessionAuthorityContext";

export const MainContainer = () => {
  const { theme } = useContext(BrowserPreferenceContext);
  const { rightPanelOpen, leftPanelOpen } = useContext(NavContext);
  const { warnings } = useContext(BannerContext);
  const { locationValidation } = useContext(ErrorHandlingContext);
  const { playerToken } = useContext(PlayerTokenContext);
  const [shouldHideSessionExpiringModal] = useLocalStorage(
    ZEEBIT_SHOULD_HIDE_SESSION_EXPIRING_MODAL,
    false
  );

  // CHECK IF GEOBLOCKED
  const [showGeoblockModal, hideGeoblockModal] = useModal(
    ({ in: open }) => (
      <GeoblockModal
        open={open}
        hideModal={() => {
          hideGeoblockModal();
        }}
      />
    ),
    []
  );

  const [showSessionExpiringModal, hideSessionExpiringModal] = useModal(
    ({ in: open }) => (
      <SessionExpiringModal
        visible={open}
        hideModal={hideSessionExpiringModal}
        expirationDate={playerToken?.sessionAuthorityExpiryDate}
      />
    ),
    [playerToken?.sessionAuthorityExpiryDate]
  );

  useEffect(() => {
    let timerId = null;

    if (
      playerToken?.sessionAuthorityExpiryDate &&
      new Date(playerToken?.sessionAuthorityExpiryDate).getTime() >
        Date.now() &&
      !shouldHideSessionExpiringModal
    ) {
      const expiryTimestamp = new Date(
        playerToken?.sessionAuthorityExpiryDate
      ).getTime();
      const timeToExpire = expiryTimestamp - Date.now() - 59_000;

      if (timeToExpire < 0) {
        showSessionExpiringModal();
      } else {
        timerId = setTimeout(() => showSessionExpiringModal(), timeToExpire);
      }
    } else {
      hideSessionExpiringModal();
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [playerToken?.sessionAuthorityExpiryDate]);

  useEffect(() => {
    if (locationValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      showGeoblockModal();
    }
  }, [locationValidation]);

  useOnboardingHandler();

  // SET THE CHAIN IF COMING FROM THE URL PARAM
  const [queryParameters] = useSearchParams();
  const { chain, setChain } = useContext(NetworkContext);

  // IF THE CHAIN CHANGES AND WE ARE ON A GAME, GO TO HOMEPAGE
  const location = useLocation();
  const navigate = useNavigate();
  const previousChain = useRef<APP_NETWORK_TYPE>();

  useEffect(() => {
    if (chain == null) {
      return;
    }

    const pathname = location.pathname;

    if (pathname?.includes("game") && previousChain.current != null) {
      // GET THE GAME CONFIG FOR THE NEW CHAIN
      const newGames = getPlatformGames(chain);
      const oldGames = getPlatformGames(previousChain.current);
      const pathParts = pathname.split(`/`);
      const gameSpecPubkey = pathParts[2];

      const oldGame = oldGames.find((game) => {
        return game.gameSpecPubkey == gameSpecPubkey;
      });

      const newGame = newGames.find((game) => {
        return game.name == oldGame?.name;
      });

      if (newGame != null) {
        navigate(`game/${newGame.gameSpecPubkey}`);
      } else {
        navigate(`/`);
      }
    }

    previousChain.current = chain;
  }, [chain]);

  const { allowsAutoSigning, setAllowsAutoSigning } = useContext(
    SessionAuthorityContext
  );
  const { isWeb3AuthWallet } = useContext(WrappedWalletContext);
  // IF WE ARE ON THE MB CHAIN, WE MUST ALLOW AUTO SIGNING IF NOT WEB3 AUTH WALLET
  useEffect(() => {
    const needsAutoSigning = isWeb3AuthWallet == false;

    if (needsAutoSigning && allowsAutoSigning == false) {
      setAllowsAutoSigning(true);
    }
  }, [chain, isWeb3AuthWallet, allowsAutoSigning]);

  return (
    <>
      {/* TOP NAV ACCROSS FULL WIDTH */}
      <TopBanner />
      <TopNav />

      <div>
        {/* LEFT SIDEBAR OR EXPANDABLE ON MOBILE */}
        {/* <SideBar /> */}

        <div
          className={`
          ${rightPanelOpen ? "md:pr-right-bar-width" : "md:pr-0"}
          ${leftPanelOpen ? "md:pl-left-bar-width" : "md:pl-0"} 
          transition-all`}
        >
          <main className="relative">
            {/* WARNING BANNERS */}
            {warnings?.map((warning, index) => (
              <Banner
                key={index}
                type={warning.bannerType}
                onClick={warning.onBannerClick}
              >
                {warning.message}
              </Banner>
            ))}
            <div
              className={`
                scroll-fix max-h-screen-minus-bar overflow-y-scroll bg-global-bg p-4 pr-3 sm:p-6 md:h-screen md:max-h-screen lg:px-6 lg:py-5
                ${SCROLLBAR_CLASSES_BLACK}
              `}
            >
              <Outlet />
            </div>
          </main>
        </div>

        <BottomNav />
      </div>

      <ToastContainer
        className="max-sm:mx-3 max-sm:mb-20 "
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
      />
    </>
  );
};
