export enum Wallets {
  PHANTOM = 'Phantom',
  BACKPACK = 'Backpack',
  SOLFLARE = 'Solflare',
  METAMASK = 'MetaMask',
  NIGHTLY = 'Nightly',
  OKX_WALLET = 'OKX Wallet',
  GOOGLE = 'Google',
  TWITTER = 'Twitter',
  DISCORD = 'Discord',
}
