import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { ParsedTransactionWithMeta, PublicKey } from "@solana/web3.js";
import { IBetMeta } from "../contexts/BetstreamingContext";
import { IGameHistory, IRandomnessResponse } from "./types";
// import { NetworkType, defaultNetwork } from "../utils/chain/network";
import { APP_NETWORK_TYPE } from "../types/chain";

export interface IGameMeta {
  gameResult: IGameHistory | undefined;
  bets: IBetMeta[];
}

export interface IClaimableTotal {
  valueBase?: number,
  valueUsd?: number,
  tokenAmountSpread?: number,
  tokenAmountUpFront?: number,
  valueBaseUi?: number,
  valueUsdUi?: number,
  tokenAmountSpreadUi?: number,
  tokenAmountUpFrontUi?: number,
  tokenIcon?: string
}

export enum ClaimableStatus {
  NOTHING_CLAIMED = 'nothingClaimed',
  NOTHING_TO_CLAIM = 'nothingToClaim',
  ACCRUING = 'accruing',
  CLAIMABLE = 'claimable',
  FOREFIT = 'forefit',
  CLAIMED = 'claimed'
}

export interface IClaimable {
  token: string,
  type: string,
  valueBase: number,
  tokenAmountSpread: number,
  tokenAmountUpFront: number,
  valueBaseUi?: number,
  valueUsdUi?: number,
  tokenAmountSpreadUi?: number,
  tokenAmountUpFrontUi?: number,
  spreadDays?: number
  tokenIcon?: string
  status?: ClaimableStatus
  startDate: Date,
  endDate: Date,
  tooltip?: string
}

export interface IClaimableMeta {
  totals: IClaimableTotal,
  claimables: IClaimable[]
  startDay: Date
  status: ClaimableStatus
}

export enum CollectableStatus {
  NOTHING_COLLECTED = 'nothingCollected',
  NOTHING_TO_COLLECT = 'nothingToCollect',
  COLLECTABLE = 'collectable',
  COLLECTABLE_IN_FUTURE = 'collectableInFuture',
  FOREFIT = 'forefit',
  COLLECTED = 'collected'
}

export interface ICollectable {
  amount: number
  token: string
  amountUi: number
  amountUsdUi: number
  startDay?: Date
  status?: CollectableStatus
  remaining?: {
    amount: number
    token: string
    amountUi: number
    amountUsdUi: number
    numberDays: number
  } | undefined
  tooltip?: string
}

export interface IRewardTransactionMeta {
  rakebackBoost?: {
    boostRate: number
    boostUntil: Date
  },
  levelUp?: {
    newRankId: number
    benefits: object
    newRank?: IPlatformRank
    valueBaseUi?: number
    valueUsdUi?: number
    tokenIcon: string
    claimable?: IClaimable | undefined
  },
  collected?: ICollectable,
  claimed?: IClaimable[]
  claimedTotals?: IClaimableTotal
  referral?: IClaimable
}

export default class BetStream {
  private _baseProgram: Program;
  private _baseEventParser: anchor.EventParser;
  private _erProgram: Program;
  private _erEventParser: anchor.EventParser;
  private _randomnessParser: anchor.EventParser; // USED TO CHECK FOR RANDOMNESS PROOF
  private _chain: APP_NETWORK_TYPE;

  constructor(
    baseProgram: Program,
    erProgram: Program,
    chain: APP_NETWORK_TYPE
  ) {
    this._baseProgram = baseProgram;
    this._baseEventParser = new anchor.EventParser(
      baseProgram.programId,
      new anchor.BorshCoder(baseProgram.idl),
    );
    this._erProgram = erProgram;
    this._erEventParser = new anchor.EventParser(
      erProgram.programId,
      new anchor.BorshCoder(erProgram.idl),
    );
    this._chain = chain;
  }

  get chain() {
    return this._chain;
  }

  get baseProgram() {
    return this._baseProgram;
  }

  get baseEventParser() {
    return this._baseEventParser;
  }

  get erProgram() {
    return this._erProgram;
  }

  get erEventParser() {
    return this._erEventParser;
  }

  get program() {
    return this.erProgram
  }

  get eventParser() {
    return this.erEventParser;
  }

  async loadHistory(
    pubkeyFilter: PublicKey, // gameInstance, gameSpec, player, casinoProgram...
    maxNumberOfBets: number = 10,
    filter?: Function,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {

    let lastTransactionSeen = undefined;
    let numberOfBets = 0;
    let finished = false;

    let metas: IBetMeta[] = [];
    let gameResultByIdentifier = new Map<string, IGameHistory>()

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfBets < maxNumberOfBets && finished == false) {
      try {
        let transactionSignatures = await this.program.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );


        // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

        if (transactionSignatures.length <= 50) {
          finished = true;
        }

        // PARSE IN BATCHES OF 50
        const TRANSACTIONS_PER_CHUNK = 50
        const NUMBER_OF_CHUNKS = Math.ceil(transactionSignatures.length / 50)

        for (let i = 0; i < NUMBER_OF_CHUNKS; i++) {
          const transactionSignaturesThisChunk = transactionSignatures.slice(i * TRANSACTIONS_PER_CHUNK, (i + 1) * TRANSACTIONS_PER_CHUNK);
          const transactionLogs = await this.program.provider.connection.getParsedTransactions(
            transactionSignaturesThisChunk.map((txnSig) => txnSig.signature),
            {
              maxSupportedTransactionVersion: 0,
              commitment: finalityLevel
            },
          );

          transactionLogs.forEach((txnLogs: ParsedTransactionWithMeta | null) => {
            // DONT INCLUDE ERROR TXNS
            if (txnLogs == null || txnLogs.meta?.err != null) {
              return
            }
 
            const { bets, gameResult } = this.parseTxLogs(txnLogs);

            // FOR MULTIPLAYER GAMES WE NEED TO TRACK THE GAME RESULTS, AND ADD AT THE END
            if (gameResult != null) {
              gameResultByIdentifier.set(gameResult.identifier.toString(), gameResult)
            }

            const betsFiltered = bets.filter((meta) => {
              // NEED A BET RESULT AT LEAST
              if (meta.betResult == null) {
                return false
              }

              // HIDE SLOTS ON MAINNET
              return filter != null ? filter(meta): true
            });

            if (betsFiltered.length > 0) {
              metas.push(...betsFiltered);
              numberOfBets = metas.length;
            }
          });

          if (numberOfBets >= maxNumberOfBets) {
            break;
          }
        }

        // DONT INCLUDE ERRORED BETS
        if (numberOfBets >= maxNumberOfBets) {
          break;
        }

        if (iteration >= maxIterations) {
          break;
        }

        if (transactionSignatures.length > 0) {
          lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
        }

        iteration += 1;
      } catch (err) {
        console.error("Issue with betstream", { err })
        finished = true
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records

    metas = metas.sort(
      (a, b) => new Date(a.betResult?.timestamp * 1000) - new Date(b.betResult?.timestamp * 1000),
    );

    if (metas.length > maxNumberOfBets) {
      metas = metas.slice(-maxNumberOfBets);
    }

    // FOR ANY METAS THAT ARE MISSING THE GAME RESULT, WE ADD THAT IN NOW
    metas = metas.map((meta) => {
      if (meta.gameResult == null && meta.betResult != null) {
        meta.gameResult = gameResultByIdentifier.get(meta.betResult?.identifier.toString())
      }

      return meta
    })

    return metas;
  }

  async loadGameHistory(
    pubkeyFilter: PublicKey, // gameInstance, gameSpec, player, casinoProgram...
    maxNumberOfGames: number = 10,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    var lastTransactionSeen = null;
    var numberOfBets = 0;
    var finished = false;

    let metas: IGameMeta[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfBets < maxNumberOfGames && finished == false) {
      let transactionSignatures = await this.program.provider.connection.getSignaturesForAddress(
        pubkeyFilter,
        {
          before: lastTransactionSeen,
        },
        finalityLevel,
      );

      // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

      if (transactionSignatures.length <= 50) {
        finished = true;
      }

      transactionSignatures = transactionSignatures.slice(0, 50);

      const transactionLogs = await this.program.provider.connection.getParsedTransactions(
        transactionSignatures.map((txnSig) => txnSig.signature),
        {
          maxSupportedTransactionVersion: 0,
          commitment: finalityLevel
        },
      );

      transactionLogs.forEach((txnLogs) => {
        // DONT INCLUDE ERROR OR NULL TXNS
        if (txnLogs == null || txnLogs.meta?.err != null) {
          return
        }

        const gameMeta = this.toGameMeta(txnLogs);

        if (gameMeta.gameResult != null && gameMeta.bets.length > 0) {
          metas = [...metas].concat(...[gameMeta]);
          numberOfBets = metas.length;
        }
      });
      if (transactionSignatures.length != 0) {
        lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
      }

      if (numberOfBets >= maxNumberOfGames) {
        break;
      }

      iteration += 1;
      if (iteration >= maxIterations) {
        break;
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records

    metas = metas.sort(
      (a, b) => new Date(a.gameResult?.timestamp * 1000) - new Date(b.gameResult?.timestamp * 1000),
    );

    if (metas.length > maxNumberOfGames) {
      metas = metas.slice(-maxNumberOfGames);
    }

    return metas;
  }

  async loadBet(signature: string, finalityLevel: anchor.web3.Finality = "confirmed") {
    const transactionLogs = await this.program.provider.connection.getParsedTransaction(
      signature,
      {
        commitment: finalityLevel,
        maxSupportedTransactionVersion: 0
      },
    );

    if (transactionLogs == null || transactionLogs.meta?.err != null) {
      return []
    }

    const { bets, gameResult } = this.parseTxLogs(transactionLogs);
    const betsFiltered = bets.filter((meta) => {
      return meta.betResult != null;
    });

    return betsFiltered;
  }

  async loadRandomness(signature: string, finalityLevel: anchor.web3.Finality = "confirmed") {
    const transactionLogs = await this.program.provider.connection.getParsedTransaction(
      signature,
      {
        commitment: finalityLevel,
        maxSupportedTransactionVersion: 0
      },
    );

    if (transactionLogs == null || transactionLogs.meta?.err != null) {
      return
    }

    const randomness = this.parseTxLogsForRandomness(transactionLogs);

    return randomness;
  }

  parseTxLogs(txnLogs: any) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null when parsing...`)
      return {
        bets: [],
        gameResult: undefined
      }
    }

    const betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const transactionSignature = txnLogs?.transaction.signatures[0];
    const blockTime = txnLogs.blockTime

    const events = this.eventParser.parseLogs(txnLogs.meta.logMessages);

    let eventId = 0
    for (let event of events) {
      if (event.name == "BetSoloSettled") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(blockTime)

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${eventId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResulted") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(blockTime)

        gameResult = event.data;
      }

      eventId += 1
    }

    const betsWithGameResult = betMetas.map((meta) => {
      meta.gameResult = gameResult;

      return meta;
    });

    return {
      bets: betsWithGameResult,
      gameResult: gameResult
    }
  }

  toGameMeta(txnLogs: any): IGameMeta {
    const betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;
    const blockTime = txnLogs.blockTime

    const transactionSignature = txnLogs?.transaction.signatures[0];
    const events = this.eventParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of events) {
      if (event.name == "BetSoloSettled") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(blockTime)

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betIdx}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResulted") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(blockTime)

        gameResult = event.data;
      }
    }

    return {
      gameResult: gameResult,
      bets: betMetas.map((meta) => {
        meta.gameResult = gameResult;

        return meta;
      }),
    };
  }

  parseTxLogsForRandomness(txnLogs: any) {
    let randomnessResult: IRandomnessResponse | undefined = undefined;
    const randomEvents = this._randomnessParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of randomEvents) {
      // RANDOMNESS RESPONSE EVENT
      if ((event.name = "ResponseEvent")) {
        randomnessResult = event.data;
      }
    }

    return randomnessResult;
  }

  parseNewLog(txnLogs: any): { bets: IBetMeta[], gameResult: IGameHistory | undefined } {
    let betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const events = this.eventParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    const now = new Date()

    for (let event of events) {
      if (event.name == "BetSoloSettled") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(Math.round(now.getTime() / 1000))

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betIdx}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResulted") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(Math.round(now.getTime() / 1000))

        gameResult = event.data;
      }
    }

    return {
      bets: betMetas.map((bm) => {
        bm.gameResult = gameResult;
  
        return bm;
      }),
      gameResult: gameResult
    };
  }

  toNewGameMeta(txnLogs: any): IGameMeta {
    let betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const now = new Date()
    const events = this.eventParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    for (let event of events) {
      if (event.name == "BetSoloSettled") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(Math.round(now.getTime() / 1000))

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betIdx}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResulted") {
        event.data["signature"] = transactionSignature;
        event.data["timestamp"] = new anchor.BN(Math.round(now.getTime() / 1000))

        gameResult = event.data;
      }
    }

    return {
      bets: betMetas.map((bm) => {
        bm.gameResult = gameResult;

        return bm;
      }),
      gameResult: gameResult,
    };
  }

  get randomnessParser() {
    return this._randomnessParser
  }
}
