const COMMAS_AND_DOTS = [",", "."];

export enum NumberType {
  PROBABILITY = "probability",
  MULTIPLIER = "multiplier",
  TOKEN_AMOUNT = "token amount",
  TOKEN_AMOUNT_WITH_PRECISION = "token amount with precision",
  DOLLAR_AMOUNT = "dollar amount",
  XP_AMOUNT = "xp amount",
  INT = "int"
}

export const formatZeebitNumber = (
  amount: number,
  type: NumberType,
  maxChars?: number,
  decimalPlaces?: number,
) => {
  switch (type) {
    case NumberType.PROBABILITY:
      // COMES IN 0....1, return 50.00%
      return `${formatCurrency(amount * 100, 2, maxChars ? maxChars : undefined)}`;
    case NumberType.MULTIPLIER:
      return `${formatCurrency(amount, 2, maxChars ? maxChars : undefined)}`;
    case NumberType.DOLLAR_AMOUNT:
      return formatCurrency(amount, amount > 0 && amount < 0.01 ? 3: 2, maxChars ? maxChars : undefined);
    case NumberType.TOKEN_AMOUNT:
      return formatCurrency(amount, decimalPlaces ? decimalPlaces : 2, maxChars ? maxChars : 8);
    case NumberType.XP_AMOUNT:
      const xpAmount = amount / Math.pow(10, 3)

      if (xpAmount > (1 * Math.pow(10, 6))) {
        return `${formatCurrency(xpAmount / Math.pow(10, 6), 1, undefined)}m`
      } else if (xpAmount > (100 * Math.pow(10, 3))) {
        return `${formatCurrency(xpAmount / Math.pow(10, 3), 1, undefined)}k`
      }

    case NumberType.TOKEN_AMOUNT_WITH_PRECISION:
      return formatCurrency(amount, getNumberPrecision(amount), undefined);
    case NumberType.INT:
      return formatCurrency(amount, 0, undefined);
  }
};

// WIP FOR FUNCTION WHICH FORMATS A NUMBER BASED ON DECIMAL PLACES AND MAX CHARS TOTAL
export const formatCurrency = (
  amount: number,
  decimalPlaces: number,
  maxChars?: number,
): string => {
  const factor = Math.pow(10, decimalPlaces);
  const toXDecimals = Math.round(amount * factor) / factor;
  let stringVal = toXDecimals.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  if (maxChars == null) {
    return stringVal;
  }

  if (stringVal == "0" && maxChars != null) {
    stringVal = "0.0";
  } else if (stringVal.includes(".") == false) {
    stringVal += ".0";
  }

  const commasAndDots = stringVal.split("").filter((val) => {
    return COMMAS_AND_DOTS.includes(val);
  });

  const maxCharsIncludingCommasAndDots = maxChars ? maxChars + commasAndDots.length : maxChars;

  if (maxCharsIncludingCommasAndDots) {
    if (stringVal.length > maxCharsIncludingCommasAndDots) {
      // TODO - ENSURE WE NEVER CUT OFF NON DECIMAL PLACES...

      return stringVal.slice(0, maxCharsIncludingCommasAndDots);
    } else if (stringVal.length < maxCharsIncludingCommasAndDots) {
      return stringVal.padEnd(maxCharsIncludingCommasAndDots, "0");
    }
  }

  return stringVal;
};

export const getNumberPrecision = (number: number | undefined): number => {
  if (number == null) {
    return 2
  }

  if (number > 10) {
    return 2;
  }

  if (number > 1) {
    return 4;
  }

  const numberFraction = number.toString().split(".")[1] || "";

  return numberFraction.length - parseInt(numberFraction).toString().length + 5;
}
