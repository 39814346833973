import { useRef, useState } from "react";
import { ErrorType, IGameError } from "../../types/error";

export const useErrorMessageValidation = () => {
  const [errorMessages, setErrorMessages] = useState<IGameError[]>([]);
  const errorMessageByType = useRef<Map<ErrorType, IGameError>>(new Map());

  const addErrorMessage = (error: IGameError) => {
    if (errorMessageByType.current.has(error.type)) {
      return;
    }
    errorMessageByType.current.set(error.type, error);
    const newErrorMessages = [];
    for (const value of errorMessageByType.current.values()) {
      newErrorMessages.push(value);
    }

    setErrorMessages(newErrorMessages);
  };

  const removeErrorMessage = (type: ErrorType) => {
    const deleted = errorMessageByType.current.delete(type);

    if (deleted) {
      const newErrorMessages = [];
      for (const value of errorMessageByType.current.values()) {
        newErrorMessages.push(value);
      }
      setErrorMessages(newErrorMessages);
    }
  };

  return {
    errorMessages,
    addErrorMessage,
    removeErrorMessage,
    errorMessageByType: errorMessageByType.current,
  };
};
