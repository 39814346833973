import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerToken from "../playerToken";

export default class Dice extends Game {
  constructor(
    house: House,
    gameSpecPubkey: PublicKey,
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed") {
    const game = new Dice(house, gameSpecPubkey);
    await game.loadBaseState(commitmentLevel);
    await game.loadErState();
    await game.loadSupportedTokens();
    return game;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: { bets: { wager: number, wagerBasis: number, line: number }[], targetMultiplier: number },
    wager: number,
    clientSeed: number[]
  ) {
    const numberOfBets = inputs.bets.length
    const instanceRequest = {
      dice: {
        numRolls: numberOfBets,
      },
    };

    const betRequests = inputs.bets?.map(({ wagerBasis, line }) => ({
      dice: {
        line,
        wager: new anchor.BN(wagerBasis),
      },
    }));

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      null,
      clientSeed
    );
  }
  get state() {
    return this.baseState
  }
  get gameConfig() {
    return this.state ? this.state.config.dice : null;
  }
  get maxMultiplier() {
    return this
      ? Number(this.gameConfig.maxMultiplierPerMillion) / 1_000_000
      : null;
  }

  get houseEdge() {
    return this.gameConfig ? Number(this.gameConfig.edgePerMillion) / 1_000_000 : null;
  }

  get multiplierRoundingUnit() {
    return this.gameConfig ? Number(this.gameConfig.roundingDenominator) / 1_000_000 : null;
  }

  get rangeUpper() {
    return this.gameConfig ? Number(this.gameConfig.rangeUpper) : null;
  }

  get selectionMin() {
    return this.gameConfig ? Number(this.gameConfig.selectionMin) : null;
  }

  get maxNumRolls() {
    return this.gameConfig ? Number(this.gameConfig.maxNumRolls) : null;
  }

  get maxRolls() {
    const maxPerIxn = this.maxBetsPerPlaceIxn || 1
    const maxNumRolls = this.maxNumRolls || 1

    return Math.min(maxPerIxn, maxNumRolls)
  }

  get selectionMax() {
    return this.gameConfig ? Number(this.gameConfig.selectionMax) : null;
  }

  get selectionIncrement() {
    return this.gameConfig ? Number(this.gameConfig.selectionIncrement) : null;
  }

  getAcceptableLine(rawLine: number) {
    if (rawLine <= this.selectionMin) {
      return this.selectionMin;
    } else if (rawLine >= this.selectionMax) {
      return this.selectionMax;
    } else {
      return (rawLine / this.selectionIncrement) * this.selectionIncrement;
    }
  }

  getRoundedMultiplier(line: number) {
    const probability = this.getProbability({ line: line });
    const roundingUnit = this.multiplierRoundingUnit || 1;
    const houseEdge = this.houseEdge || 0.02;
    const multiplierPreRounding = (1 - houseEdge) / probability;

    return (
      Math.round(
        Math.floor(multiplierPreRounding / roundingUnit) * roundingUnit * Math.pow(10, 6),
      ) / Math.pow(10, 6)
    );
  }

  getProbability(inputs: object) {
    return 1 - inputs.line / this.rangeUpper;
  }

  getMultiplier(inputs: object): number {
    if (inputs == null || inputs.line == null) {
      return 10;
    }

    return this.getRoundedMultiplier(inputs.line);
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;

    bets.forEach((bet) => {
      const multiplier = this.getMultiplier(bet);
      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wager;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      edgePercentage: edgeDollar / totalWager,
    };
  }
}
