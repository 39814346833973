interface IInfo {
  children: any;
}

export const Info = ({ children }: IInfo) => {
  return (
    <div className="text-sm font-normal text-gray-300 flex p-3 items-start content-start gap-1.5 self-stretch flex-wrap rounded-md border-2 border-solid border-gray-600 bg-gray-800">
      {children}
    </div>
  );
};
