import { FC } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  XIcon
} from "react-share";

export const SocialsSharing: FC<{ referralLink: string, title: string }> = ({
  referralLink, title
}) => (
  <>
    <TwitterShareButton
      url={referralLink}
      title={title}
    >
      <XIcon size={32} round />
    </TwitterShareButton>
    <FacebookShareButton
      url={referralLink}
      title={title}
    >
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    <RedditShareButton
      url={referralLink}
      title={title}
      windowWidth={660}
      windowHeight={460}
    >
      <RedditIcon size={32} round />
    </RedditShareButton>
  </>
);
