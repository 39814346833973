import React, { FC, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "../../../assets/icons/loading-spinner.svg";
import Icon from "../icon/Icon";

export interface InputProps extends Omit<HTMLInputElement, "size"> {
  leftInfo?: string | React.ReactNode;
  rightInfo?: string | React.ReactNode;
  error?: string;
  success?: string;
  subTitle?: string;
  name: string;
  variant: "game" | "regular";
  classes?: {
    wrapper?: string;
    label?: string;
    input?: string;
    labelOnFocus?: string;
  };
  onFocus?: Function;
  isPreview?: boolean;
  size?: InputSize;
  isLoading?: boolean;
}
export type InputSize = "md" | "sm";

const Input: FC<InputProps> = ({
  success,
  leftInfo,
  rightInfo,
  name,
  subTitle,
  error,
  variant = "regular",
  disabled,
  isPreview,
  onBlur,
  classes,
  onFocus,
  size,
  isLoading,
  ...props
}) => {
  const [inFocus, setFocus] = useState(false);
  const gameInput = useMemo(() => variant === "game", [variant]);

  const getSizeClasses = () => {
    switch (size) {
      case "sm": {
        return "text-sm leading-3";
      }
      case "md": {
        return "text-md sm:text-sm sm:leading-6";
      }
      default: {
        return "text-md sm:text-sm sm:leading-6";
      }
    }
  };

  return (
    <div
      className={`flex flex-col ${disabled ? "cursor-not-allowed" : ""} ${classes?.wrapper}`}
    >
      <label
        htmlFor={name}
        className={twMerge(
          disabled ? "cursor-not-allowed text-gray-300" : "text-gray-50",
          "flex items-center rounded-md border-2 pl-3 pr-1 focus:border-2 ",
          inFocus
            ? gameInput
              ? "border-gray-500"
              : "border-gray-500"
            : error
              ? "border-error"
              : success
                ? "border-green-500"
                : "border-gray-600",
          isPreview ? "border-gray-700 bg-gray-700" : "bg-gray-900",
          classes?.label
        )}
      >
        {leftInfo && (
          <div
            className={twMerge(
              "pointer-events-none left-0 flex content-baseline items-center",
              inFocus ? "text-gray-300" : "text-gray-400"
            )}
          >
            {leftInfo}
          </div>
        )}
        {isLoading
          ? <div className="h-10 flex items-center"><Icon
            inheritStroke={true}
            inheritFill={false}
            size="md"
            icon={<Spinner />}

          /></div>
          : <input
            {...props}
            onFocus={() => {
              if (onFocus != null) {
                onFocus();
              }
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
              onBlur?.();
            }}
            disabled={disabled}
            className={twMerge(
              "[-webkit-appearance]:none [-moz-appearance]:textfield block w-full rounded-md border-0 px-0 py-2",
              "bg-transparent placeholder:text-gray-400 focus:border-0 focus:shadow-none focus:outline-none focus:ring-0",
              getSizeClasses(),
              disabled ? "cursor-not-allowed" : "cursor-text",
              classes?.input || "",
              inFocus ? classes?.labelOnFocus : ""
            )}
            id={name}
          />}
        {rightInfo && (
          <div className="flex content-center items-center text-current">
            {rightInfo}
          </div>
        )}
      </label>
    </div>
  );
};

export default Input;
