import { APP_NETWORK_TYPE } from "../../types/chain";
import { NetworkType, defaultNetwork } from "../chain/network";

export enum SolanaExplorer {
  SOLANA_FM = 'SOLANA.FM',
  SOL_SCAN = 'SOL SCAN',
  SOLANA_EXPLORER = 'SOLANA EXPLORER'
}

export enum ExplorerTransactionType {
  ADDRESS = "address",
  TRANSACTION = "tx",
  BLOCK = "block",
}

const getMBExplorer = (network: NetworkType) => {
  if (network == NetworkType.DEVNET) {
    return 'zeebit.magicblock.app'
  }

  return 'zeebit-mainnet.magicblock.app'
}

export const getExplorerUrl = (
  pubkeyOrBlock: string,
  chain: APP_NETWORK_TYPE,
  network: NetworkType = defaultNetwork,
  transactionType: ExplorerTransactionType = ExplorerTransactionType.ADDRESS,
  selectedExplorer: SolanaExplorer = SolanaExplorer.SOLANA_FM
) => {
  return `https://explorer.solana.com/${transactionType + "/"}${pubkeyOrBlock}${`?cluster=custom&customUrl=https%3A%2F%2F${getMBExplorer(network)}`
    }`
};
