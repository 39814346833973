import IconFont, { IconName, IconSizes, IconProps } from "./IconFont"

export {
  IconFont,
}

export type {
  IconName,
  IconSizes,
  IconProps
}