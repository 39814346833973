import { Switch } from "@headlessui/react";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

export enum CasinoSwitchSize {
  TINY = "tiny",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export interface ICasinoSwitchProps {
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  size?: CasinoSwitchSize;
  disabled?: boolean;
  label?: string;
  classNameFunction?: (checked: boolean, disabled: boolean) => string;
}

export const CasinoSwitch = ({
  onChange,
  checked,
  size = CasinoSwitchSize.MEDIUM,
  disabled = false,
  label,
  classNameFunction
}: ICasinoSwitchProps) => {
  const parentSizeClasses = useMemo(() => {
    if (size == CasinoSwitchSize.LARGE) {
      return `h-8 w-16`;
    }

    if (size == CasinoSwitchSize.MEDIUM) {
      return `h-7 w-14`;
    }

    if (size == CasinoSwitchSize.SMALL) {
      return `h-6 w-12`;
    }

    if (size == CasinoSwitchSize.TINY) {
      return `h-5 w-10`;
    }

    return `h-8 w-16`;
  }, [size]);

  const childSizeClasses = useMemo(() => {
    if (size == CasinoSwitchSize.LARGE) {
      return `h-6.5 w-6.5`;
    }

    if (size == CasinoSwitchSize.MEDIUM) {
      return `h-5.5 w-5.5`;
    }

    if (size == CasinoSwitchSize.SMALL) {
      return `h-4.5 w-4.5`;
    }

    if (size == CasinoSwitchSize.TINY) {
      return `h-3.5 w-3.5`;
    }

    return `h-6 w-6`;
  }, [size]);

  const childTranslateClasses = useMemo(() => {
    if (size == CasinoSwitchSize.LARGE) {
      return checked ? "translate-x-9" : "translate-x-1";
    }

    if (size == CasinoSwitchSize.MEDIUM) {
      return checked ? "translate-x-8" : "translate-x-1";
    }

    if (size == CasinoSwitchSize.SMALL) {
      return checked ? "translate-x-7" : "translate-x-1";
    }

    if (size == CasinoSwitchSize.TINY) {
      return checked ? "translate-x-6" : "translate-x-1";
    }

    return checked ? "translate-x-9" : "translate-x-1";
  }, [size, checked]);

  const parentBgColor = useMemo(() => {
    if (disabled == false) {
      return checked ? "bg-brand-mixed-gradient " : "bg-black-700";
    } else {
      return checked ? "opacity-50 bg-brand-mixed-gradient" : "opacity-50 bg-black-700";
    }
  }, [checked, disabled]);

  const childBgColor = useMemo(() => {
    if (disabled == true) {
      if (checked == false) {
        return "opacity-50 bg-gray-400";
      } else {
        return "opacity-50 bg-gray-50";
      }
    }

    return "bg-gray-50";
  }, [checked, disabled]);

  return (
    <Switch
      disabled={disabled}
      checked={checked}
      onClick={(event) => event.stopPropagation()}
      onChange={() => onChange(!checked)}
      className={twMerge(
        parentBgColor,
        "relative inline-flex shrink-0",
        parentSizeClasses,
        "items-center rounded-full transition-colors",
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        classNameFunction?.(checked, disabled) || ""
      )}
    >
      <span className="sr-only">{label}</span>
      <span
        className={`${childTranslateClasses} inline-block ${childSizeClasses} transform rounded-full ${childBgColor} transition-transform`}
      />
    </Switch>
  );
};
