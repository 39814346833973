import { useState } from "react";

export default function useToggle(initialValue = false): {
  value: boolean;
  toggleValue: (value?: boolean) => void;
} {
  const [value, setValue] = useState(initialValue);
  const toggleValue: (newValue?: boolean) => void = (newValue) => {
    setValue((prev) => (newValue ? newValue : !prev));
  };

  return {
    value,
    toggleValue,
  };
}
