import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { BASE_TOAST_CONFIG } from './BaseToast';
import { ToasterContext } from '../../contexts';
import { Id, ToastOptions } from 'react-toastify';
import { DepositToast, IDepositToastType } from './deposit/DepositToast';

export interface IToastOptions {
    content: IToastContent;
    config?: ToastOptions;
}
export interface IToastContent {
    title?: string;
    message?: string;
    type: IDepositToastType;
}
export type useToastAction = ({ content, config }: IToastOptions) => void;
export interface IUseToast {
    updateToast: useToastAction;
    createToast: useToastAction;
}

const useToast = (): IUseToast => {
    const toastId = useRef<Id | null>(null);
    const toast = useContext(ToasterContext);

    const renderToaster = ({ title, message, type }: IToastContent) => (
        <DepositToast
            title={title}
            message={message}
            type={type}
        />
    )

    const createToast = ({ content, config }: IToastOptions) => {
        const options = config ? { ...BASE_TOAST_CONFIG, autoClose: false, ...config } : { ...BASE_TOAST_CONFIG, autoClose: false };
        toastId.current = toast(
            renderToaster(content),
            { ...options, onClose: () => { 
                toast.dismiss(toastId.current) 
                toastId.current = null
            } },
        );
    }
    const updateToast = ({ content, config }: IToastOptions) => {
        if (!toastId.current) {
            createToast({ content, config })
            return;
        };
        toast.update(toastId.current, {
            render: renderToaster(content),
            ...(config ?? {})
        })
    }
    useEffect(() => {
        return (() => {
            if (toastId.current) {
                toast.dismiss(toastId.current);
                toastId.current = null
            }
        })
    }, [])

    return useMemo(() => {
        return {
            updateToast: updateToast,
            createToast: createToast
        };
    }, [updateToast, createToast]);
};

export default useToast;