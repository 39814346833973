import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useModal } from "react-modal-hook";
import { twMerge } from "tailwind-merge";
import { Keypair } from "@solana/web3.js";

import { ModalProps } from "./types";
import Icon from "../common/icon/Icon";
import {
  Accordion,
  BaseModal,
  Button,
  Dropdown,
  IconFont,
  Tooltip,
} from "../common";
import Input from "../common/input/Input";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import FormItem from "../common/form-item/FormItem";
import IconUrl from "../common/icon/Icon";
import InputButton from "../common/input/InputButton";
import { CheckBox, CheckboxSize } from "../common/check-box/check-box";
import { truncatePubkey } from "../../utils/string/string";
import { AwaitingModal } from "./AwaitingModal";
import {
  NumberType,
  formatZeebitNumber,
} from "../../utils/currency/formatting";
import { confirmTransaction } from "../../utils/solana/utils";
import {
  HouseContext,
  NetworkContext,
  PlayerTokenContext,
  ToasterContext,
  AggregatedBalancesContext,
  WrappedWalletContext,
  BalanceContext,
} from "../../contexts";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import Tabs from "../common/tabs/Tabs";
import { BASE_TOAST_CONFIG } from "../toast/BaseToast";
import { AutoSigningNeedsMoreSolModal } from "../../modals/auto-sign/AutoSigningNeedsMoreSolModal";
import { APP_NETWORK_TYPE } from "../../types/chain";
import { HouseTokenStatus } from "../../sdk/enums";
import PlayerToken, {
  DepositStage,
  WithdrawalStage,
} from "../../sdk/playerToken";
import Spinner from "../common/spinner/Spinner";
import { SigningModal } from "./SigningModal";

export enum IFundOrWithdrawModalTabs {
  DEPOSIT = "Deposit",
  WITHDRAW = "Withdraw",
}

interface IAddFundsAndPlayModalProps extends ModalProps {
  walletModalSelectedTab?: IFundOrWithdrawModalTabs;
  showModal: Function;
}

export const AddFundsAndPlayModal: FC<IAddFundsAndPlayModalProps> = ({
  visible,
  showModal,
  hideModal,
  walletModalSelectedTab = IFundOrWithdrawModalTabs.DEPOSIT,
}) => {
  const { isWeb3AuthWallet, walletPubkey } = useContext(WrappedWalletContext);
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { setSelectedTokenMeta, selectedTokenMeta } =
    useContext(BalanceContext);
  const selectedMerged = useMemo(() => {
    return mergedTokens?.find((token) => {
      return token.context?.pubkey == selectedTokenMeta.mint;
    });
  }, [mergedTokens, selectedTokenMeta]);
  const playBalance = useMemo(() => {
    if (selectedMerged?.playerToken == null) {
      return 0;
    }

    return (
      selectedMerged.playerToken?.playBalance /
      Math.pow(10, selectedMerged.context?.decimals || 6)
    );
  }, [selectedMerged]);

  const walletBalance = useMemo(() => {
    if (selectedMerged?.wallet == null) {
      return 0;
    }
    return selectedMerged?.wallet.uiAmount;
  }, [selectedMerged]);
  const [currentTab, setCurrentTab] = useState<IFundOrWithdrawModalTabs>(
    walletModalSelectedTab
  );

  useEffect(() => {
    setCurrentTab(walletModalSelectedTab);
  }, [walletModalSelectedTab]);

  const toast = useContext(ToasterContext);

  const tokensDropdownItems =
    mergedTokens?.map((token) => ({
      value: token?.context?.symbol,
      data: token,
      key: token?.context?.symbol,
      name: `${token?.context?.name} (${token?.context?.symbol})`,
      isUpdatingBalance: token.isUpdatingBalance,
    })) || [];

  const [tokensAmountValue, setTokensAmountValue] = useState(0);
  const { signerKp, allowsAutoSigning, setAllowsAutoSigning, lamportBalance } =
    useContext(SessionAuthorityContext);
  const {
    initAndDeposit,
    loadPlayerToken,
    initAndDelegate,
    processWithdrawal,
    withdrawFundsAndEndSession,
    processWithdrawalAll,
    updateSlipUndelegate,
    updateToast,
    createToast,
    setLastTransactionError,
    lastTransactionError,
  } = useContext(PlayerTokenContext);
  const [actionLoading, setActionLoading] = useState(false);
  const { client, recentBlockhash, chain } = useContext(NetworkContext);
  const { houseToken } = useContext(HouseContext);
  const [statusMessageEr, setStatusMessageEr] = useState<string>();
  const [action, setAction] = useState<"deposit" | "withdrawal">();

  const [finishedInitialChecks, setFinishedInitialChecks] = useState(false); // before allowing deposit/withdraw, we need to check for update slips

  const lastPlayerTokenChecked = useRef<string>();

  const undelegateUpdateSlip = useCallback(async () => {
    try {
      setActionLoading(true);
      setAction("withdrawal");

      const sessionKp =
        allowsAutoSigning == true && signerKp != null
          ? Keypair.fromSecretKey(bs58.decode(signerKp))
          : undefined;

      const sig = await updateSlipUndelegate(sessionKp, true);

      // toast(
      //   <BaseToast
      //     message={`Successfully prepared for a withdrawal or deposit.`}
      //     type={"success"}
      //   />,
      //   BASE_TOAST_CONFIG,
      // );
      updateToast({
        content: {
          title: "Successfully prepared for a withdrawal or deposit.",
          type: "loading",
        },
      });
    } catch (err) {
      console.error({ err });
      setLastTransactionError(err.message || err);
      // toast(
      //   <BaseToast
      //     message={`There was an issue preparing for a withdrawl or deposit.`}
      //     type={"error"}
      //   />,
      //   BASE_TOAST_CONFIG,
      // );
      updateToast({
        content: {
          message: "There was an issue preparing for a withdrawl or deposit.",
          type: "error",
        },
        config: BASE_TOAST_CONFIG,
      });
    } finally {
      setActionLoading(false);
      setAction(undefined);
    }
  }, [signerKp, allowsAutoSigning, updateSlipUndelegate]);

  const { addBalancesBeingUpdated, removeBalancesBeingUpdated } = useContext(
    AggregatedBalancesContext
  );

  const continuePartialWithdrawal = useCallback(
    async (withdrawalStage: WithdrawalStage) => {
      try {
        setActionLoading(true);
        setAction("withdrawal");

        if (selectedMerged?.context?.pubkey != null) {
          addBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
        }

        const sig = await processWithdrawal(
          0,
          setStatusMessageEr,
          withdrawalStage
        );

        // toast(
        //   <BaseToast
        //     message={`Successfully completed ${selectedMerged?.context?.name} withdrawal.`}
        //     type={"success"}
        //   />,
        //   BASE_TOAST_CONFIG,
        // );
        updateToast({
          content: {
            title: `Successfully completed ${selectedMerged?.context?.name} withdrawal.`,
            type: "success",
          },
          config: BASE_TOAST_CONFIG,
        });
      } catch (err) {
        console.error({ err });
        if (!lastTransactionError) {
          setLastTransactionError(err.message || err);
        }
        // toast(
        //   <BaseToast
        //     message={`There was an issue completing the withdrawal.`}
        //     type={"error"}
        //   />,
        //   BASE_TOAST_CONFIG,
        // );
        updateToast({
          content: {
            message: "There was an issue completing the withdrawal.",
            type: "error",
          },
          config: BASE_TOAST_CONFIG,
        });
      } finally {
        setActionLoading(false);
        setAction(undefined);

        if (selectedMerged?.context?.pubkey != null) {
          removeBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
        }
      }
    },
    [
      selectedMerged,
      processWithdrawal,
      client,
      recentBlockhash,
      tokensAmountValue,
      selectedMerged,
      addBalancesBeingUpdated,
      removeBalancesBeingUpdated,
    ]
  );

  const continuePartialDeposit = useCallback(
    async (depositStage: DepositStage) => {
      try {
        setAction("deposit");
        setActionLoading(true);
        if (selectedMerged?.context?.pubkey != null) {
          addBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
        }

        const sessionKp =
          allowsAutoSigning == true && signerKp != null
            ? Keypair.fromSecretKey(bs58.decode(signerKp))
            : undefined;
        const SESSION_AUTH_LAMPORTS_MB = 0;

        const sig = await initAndDelegate(
          86_400,
          1,
          updateToast,
          sessionKp,
          SESSION_AUTH_LAMPORTS_MB,
          0,
          depositStage
        );

        // CONFIRM THE TX
        await confirmTransaction(sig, client, recentBlockhash, "confirmed");

        updateToast({
          content: {
            title: `Successfully finished previously started deposit ${selectedMerged?.context?.name}.`,
            type: "success",
          },
          config: BASE_TOAST_CONFIG,
        });

        // LOAD THE PLAYER TOKENS
        await loadPlayerToken();
      } catch (err) {
        console.error({ err });
        setLastTransactionError(err.message || err);

        updateToast({
          content: {
            message: "There was an issue completing the previous deposit.",
            type: "error",
          },
          config: BASE_TOAST_CONFIG,
        });
      } finally {
        setActionLoading(false);
        setAction(undefined);

        if (selectedMerged?.context?.pubkey != null) {
          removeBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
        }
      }
    },
    [
      tokensAmountValue,
      selectedMerged,
      client,
      recentBlockhash,
      loadPlayerToken,
      signerKp,
      initAndDelegate,
      allowsAutoSigning,
      updateToast,
      addBalancesBeingUpdated,
      removeBalancesBeingUpdated,
    ]
  );

  useEffect(() => {
    async function runInitialChecks(pToken: PlayerToken) {
      try {
        if (finishedInitialChecks == true) {
          setFinishedInitialChecks(false);
        }

        lastPlayerTokenChecked.current = pToken.publicKey.toString();

        if (pToken.houseToken.isDelegated == false || pToken.state == null) {
          return;
        }

        // LOAD THE UPDATE SLIP FROM EITHER CHAIN
        const updateSlipPubkey = PlayerToken.deriveUpdateSlipPubkey(
          pToken.publicKey,
          pToken.programId
        );

        const baseUpdateSlipAccount =
          await pToken.baseProgram.account.updateSlip.fetchNullable(
            updateSlipPubkey,
            "processed"
          );
        const erUpdateSlipAccount =
          await pToken.erProgram.account.updateSlip.fetchNullable(
            updateSlipPubkey,
            "processed"
          );

        if (baseUpdateSlipAccount == null && erUpdateSlipAccount == null) {
          return;
        }

        // update slip exists on ER and waiting apply
        // -> we need to show the deposit as pending,
        // apply on the ER, await its undelegation and close it
        if (erUpdateSlipAccount != null) {
          // CHECK DELEGATION STATUS IS DELEGATED
          const delegationStatus = Object.keys(erUpdateSlipAccount.status)[0];

          if (delegationStatus == "delegated") {
            const status = Object.keys(erUpdateSlipAccount.status)[0];

            // IF STATUS IS APPLIED OR COMPLETED, DO NOTHING AS CLOSING PICKED UP IN FLOW
            if (["completed", "applied"].includes(status)) {
              return;
            }

            const update = Object.keys(erUpdateSlipAccount.update)[0];

            if (update != null && update == "playerTokenDeposit") {
              // SET DEPOSIT TAB, TRY CONTINUE FLOW
              setCurrentTab(IFundOrWithdrawModalTabs.DEPOSIT);
              await continuePartialDeposit(DepositStage.DEPOSIT_APPLY);
              return;
            }
          }
        }
        if (baseUpdateSlipAccount != null) {
          // CHECK IF CAN BE CLOSED
          const status = Object.keys(baseUpdateSlipAccount.status)[0];
          const update = Object.keys(baseUpdateSlipAccount.update)[0];

          if (["applied", "completed"].includes(status)) {
            // CLOSE ALREADY PICKED UP AS PART OF THE CYCLE
            return;
          }

          // APPLY WITHDRAWAL AND CONTINUE FLOW
          if (update == "playerTokenWithdraw") {
            setCurrentTab(IFundOrWithdrawModalTabs.WITHDRAW);
            await continuePartialWithdrawal(WithdrawalStage.WITHDRAW_APPLY);
            return;
          }
        }

        if (baseUpdateSlipAccount != null) {
          // CHECK DELEGATED
          const delegationStatus = Object.keys(
            baseUpdateSlipAccount.delegationStatus
          )[0];

          if (delegationStatus == "delegated") {
            const update = Object.keys(baseUpdateSlipAccount.update)[0];

            if (update != null && update == "playerTokenDeposit") {
              // SET DEPOSIT TAB, TRY CONTINUE FLOW
              setCurrentTab(IFundOrWithdrawModalTabs.DEPOSIT);
              await continuePartialDeposit(DepositStage.DEPOSIT_APPLY);
              return;
            }
          }
        }

        // IF PRE-DELEGATED BUT NOT CAUGHT BY PREVIOUS STEPS - NEED TO UNDELEGATE AND CLOSE
        if (baseUpdateSlipAccount != null) {
          const status = Object.keys(baseUpdateSlipAccount.status)[0];
          if (status == "predelegated") {
            await undelegateUpdateSlip();
          }
        }
      } catch (err) {
        console.warn(
          `There was an issue running the initial checks. ${JSON.stringify(err)}`
        );
      } finally {
        setFinishedInitialChecks(true);
      }
    }

    // ONLY WANT TO RUN THESE CHECKS ON MB
    if (chain != APP_NETWORK_TYPE.MB_AND_SOLANA) {
      if (finishedInitialChecks == false) {
        setFinishedInitialChecks(true);
      }

      return;
    }

    // need a pt to validate
    if (selectedMerged?.playerToken == null) {
      if (finishedInitialChecks == true) {
        setFinishedInitialChecks(false);
      }
      return;
    }

    // already run the checks
    if (
      lastPlayerTokenChecked.current ==
      selectedMerged?.playerToken.publicKey.toString()
    ) {
      return;
    }

    runInitialChecks(selectedMerged.playerToken);
  }, [
    selectedMerged?.playerToken,
    continuePartialDeposit,
    continuePartialWithdrawal,
    undelegateUpdateSlip,
    chain,
  ]);

  const [showAwaitingModal, hideAwaitingModal] = useModal(
    ({ in: open }) => (
      <AwaitingModal
        visible={open}
        hideModal={hideAwaitingModal}
        mainText="Waiting for signature"
        secondaryText="Please sign permission"
      />
    ),
    [allowsAutoSigning]
  );

  // ENSURE ONLY SHOWN WHEN NOT WEB3 AUTH WALLET
  const [showAutoSigningAgreementModal, hideAutoSigningAgreementModal] =
    useModal(
      ({ in: open }) => (
        <AutoSigningAgreementModal
          visible={open}
          hideModal={hideAutoSigningAgreementModal}
          onAutoSigningApprove={() => setAllowsAutoSigning(true)}
          onModalsClose={hideModal}
        />
      ),
      [setAllowsAutoSigning]
    );

  const [showAutoSigningNeedsMoreSolModal, hideAutoSigningNeedsMoreSolModal] =
    useModal(
      ({ in: open }) => (
        <AutoSigningNeedsMoreSolModal
          visible={open}
          hideModal={() => {
            hideAutoSigningNeedsMoreSolModal();
          }}
        />
      ),
      []
    );

  const actionHeading = useMemo(() => {
    if (action == null || statusMessageEr == null) {
      return;
    }

    return action == "deposit" ? "Processing deposit" : "Processing withdrawal";
  }, [action, statusMessageEr]);

  // const [showStatusLoadingModal, hideStatusLoadingModal] = useModal(
  //   ({ in: open }) => (
  //     <AwaitingModal
  //       visible={open}
  //       hideModal={hideStatusLoadingModal}
  //       mainText={actionHeading}
  //       secondaryText={statusMessageEr}
  //     />
  //   ),
  //   [statusMessageEr, actionHeading],
  // );

  // SIGNING MODAL
  const [showSigningModal, hideSigningModal] = useModal(
    ({ in: open }) => (
      <SigningModal
        visible={open}
        hideModal={hideSigningModal}
        mainText={"Confirm transaction in your wallet"}
        secondaryText={statusMessageEr}
      />
    ),
    [actionHeading, statusMessageEr]
  );

  // useEffect(() => {
  //   if (action == null) {
  //     hideStatusLoadingModal?.();
  //   } else {
  //     showStatusLoadingModal?.()
  //   }
  // }, [action, showStatusLoadingModal, hideStatusLoadingModal])

  // IF THERE IS A STATUS MESSAGE ON THE ER, WANT TO SHOW THE LOADING MODAL
  const houseTokenDisabled = useMemo(() => {
    const status = selectedMerged?.playerToken?.houseToken.status;

    const withdrawalDisabled =
      status == null ||
      ![HouseTokenStatus.Active, HouseTokenStatus.InFlowsSuspended].includes(
        status
      );
    const depositDisabled =
      status == null ||
      ![HouseTokenStatus.Active, HouseTokenStatus.OutFlowsSuspended].includes(
        status
      );

    return currentTab == IFundOrWithdrawModalTabs.DEPOSIT
      ? depositDisabled
      : withdrawalDisabled;
  }, [selectedMerged?.playerToken?.houseToken, currentTab]);

  const handleDepositClick = useCallback(async () => {
    try {
      if (selectedMerged?.context?.pubkey != null) {
        addBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
      }

      hideModal();

      // ONLY SHOW SIGNING MODAL FOR NON WEB 3 AUTH
      if (isWeb3AuthWallet == false) {
        showSigningModal();
      }

      // IF ITS NOT DELEGATED, STILL WANT TO KICK OFF THE TOAST FLOW
      if (houseToken?.isDelegated == false) {
        updateToast({
          content: {
            title: "Initialized the deposit…",
            type: "loading",
          },
        });
      }

      console.log(`SHOW SIGNING MODAL`);
      setLastTransactionError(null);
      if (selectedMerged?.playerToken?.houseToken.isDelegated == false) {
        setAction(undefined);
        setStatusMessageEr(undefined);
      } else {
        setAction("deposit");
      }

      console.log(`SET ACTION LOADING`);
      setActionLoading(true);

      // CHECK THE HOUSE TOKEN IS OK
      if (houseTokenDisabled) {
        updateToast({
          content: {
            message:
              "There was an issue depositing tokens. The house token is not active.",
            type: "error",
          },
          config: BASE_TOAST_CONFIG,
        });
        return;
      }

      const basis =
        tokensAmountValue *
        Math.pow(10, selectedMerged?.context?.decimals || 6);
      const sessionKp =
        allowsAutoSigning == true && signerKp != null
          ? Keypair.fromSecretKey(bs58.decode(signerKp))
          : undefined;
      const SESSION_AUTH_LAMPORTS_MB = 0;

      console.log(`INTO THE INIT AND DELEGATE`);
      const sig = houseToken?.isDelegated
        ? await initAndDelegate(
            86_400,
            1,
            updateToast,
            sessionKp,
            SESSION_AUTH_LAMPORTS_MB,
            basis,
            undefined,
            hideSigningModal
          )
        : await initAndDeposit(sessionKp, 10_000_000, basis, hideSigningModal);

      console.log(`FINISHED THE INIT AND DELEGATE`);

      // CONFIRM THE TX
      await confirmTransaction(sig, client, recentBlockhash, "confirmed");

      updateToast({
        content: {
          title: `Successfully deposited ${tokensAmountValue} ${selectedMerged?.context?.name}.`,
          type: "success",
        },
        config: BASE_TOAST_CONFIG,
      });

      // LOAD THE PLAYER TOKENS
      await loadPlayerToken();
    } catch (err) {
      console.error({ err });
      setLastTransactionError(err.message || err);
      hideSigningModal();
      showModal();
      updateToast({
        content: {
          message: "There was an issue depositing tokens.",
          type: "error",
        },
        config: BASE_TOAST_CONFIG,
      });
    } finally {
      setActionLoading(false);
      setAction(undefined);

      if (selectedMerged?.context?.pubkey != null) {
        removeBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
      }
    }
  }, [
    initAndDeposit,
    tokensAmountValue,
    selectedMerged,
    client,
    recentBlockhash,
    loadPlayerToken,
    signerKp,
    initAndDelegate,
    allowsAutoSigning,
    houseTokenDisabled,
    updateToast,
    hideSigningModal,
    addBalancesBeingUpdated,
    removeBalancesBeingUpdated,
    isWeb3AuthWallet,
  ]);

  const handleWithdraw = useCallback(
    async (isWithdrawAll = false) => {
      if (selectedMerged?.context?.pubkey != null) {
        addBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
      }

      hideModal();

      // IF ITS NOT DELEGATED, STILL WANT TO KICK OFF THE TOAST FLOW
      if (houseToken?.isDelegated == false) {
        updateToast({
          content: {
            title: "Initialized the withdrawal...",
            type: "loading",
          },
        });
      }

      if (isWeb3AuthWallet == false) {
        showSigningModal();
      }

      setLastTransactionError(null);
      try {
        setActionLoading(true);
        if (selectedMerged?.playerToken?.houseToken.isDelegated == false) {
          setAction(undefined);
          setStatusMessageEr(undefined);
        } else {
          setAction("withdrawal");
        }

        if (houseTokenDisabled) {
          updateToast({
            content: {
              message:
                "There was an issue withdrawing tokens. The house token is not active.",
              type: "error",
            },
            config: { ...BASE_TOAST_CONFIG },
          });
          return;
        }

        // CALC AMOUNT TO WITHDRAW
        const decimals = selectedMerged?.context?.decimals || 6;
        const withdrawAmountBasis = isWithdrawAll
          ? selectedMerged?.playerToken?.playBalance || 0
          : tokensAmountValue * Math.pow(10, decimals);

        const sig = await processWithdrawal(
          withdrawAmountBasis,
          updateToast,
          undefined,
          hideSigningModal
        );

        updateToast({
          content: {
            title: `Successfully withdrew ${withdrawAmountBasis / Math.pow(10, decimals)} ${selectedMerged?.context?.name}.`,
            type: "success",
          },
          config: { ...BASE_TOAST_CONFIG },
        });
      } catch (err) {
        console.error({ err });
        setLastTransactionError(err.message || err);

        hideSigningModal();

        showModal();

        updateToast({
          content: {
            message: "There was an issue withdrawing tokens.",
            type: "error",
          },
          config: { ...BASE_TOAST_CONFIG },
        });
      } finally {
        setActionLoading(false);
        setAction(undefined);

        if (selectedMerged?.context?.pubkey != null) {
          removeBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
        }
      }
    },
    [
      selectedMerged,
      processWithdrawal,
      client,
      recentBlockhash,
      tokensAmountValue,
      selectedMerged,
      houseTokenDisabled,
      addBalancesBeingUpdated,
      removeBalancesBeingUpdated,
      updateToast,
      hideSigningModal,
      isWeb3AuthWallet,
    ]
  );

  const withdrawAndEndSession = useCallback(async () => {
    if (selectedMerged?.context?.pubkey != null) {
      addBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
    }

    hideModal();

    if (isWeb3AuthWallet == false) {
      showSigningModal();
    }

    setLastTransactionError(null);
    try {
      setAction("withdrawal");
      setActionLoading(true);
      createToast({
        content: {
          title: "Awaiting confirmation...",
          type: "loading",
        },
      });
      const sig = await withdrawFundsAndEndSession(
        updateToast,
        hideSigningModal
      );

      updateToast({
        content: {
          title: `Successfully withdrew all ${selectedMerged?.context?.name}, and ended the session.`,
          type: "success",
        },
        config: { ...BASE_TOAST_CONFIG },
      });
    } catch (err) {
      console.error({ err });
      setLastTransactionError(err.message || err);
      showModal();
      hideSigningModal();
      updateToast({
        content: {
          message:
            "There was an issue withdrawing tokens, and ending the session.",
          type: "error",
        },
        config: { ...BASE_TOAST_CONFIG },
      });
    } finally {
      setActionLoading(false);
      setAction(undefined);

      if (selectedMerged?.context?.pubkey != null) {
        removeBalancesBeingUpdated([selectedMerged?.context?.pubkey]);
      }
    }
  }, [
    withdrawFundsAndEndSession,
    selectedMerged,
    addBalancesBeingUpdated,
    removeBalancesBeingUpdated,
    updateToast,
    hideSigningModal,
    isWeb3AuthWallet,
  ]);

  const withdrawAllTokens = useCallback(async () => {
    try {
      hideModal();

      if (isWeb3AuthWallet) {
        showSigningModal();
      }

      setLastTransactionError(null);

      setAction("withdrawal");
      setActionLoading(true);
      const sig = await processWithdrawalAll(
        mergedTokens,
        updateToast,
        hideSigningModal
      );

      updateToast({
        content: {
          title: "Successfully withdrew all tokens.",
          type: "success",
        },
        config: { ...BASE_TOAST_CONFIG },
      });
    } catch (err) {
      console.error({ err });
      setLastTransactionError(err.message || err);
      showModal();
      hideSigningModal();

      updateToast({
        content: {
          message: "There was an issue withdrawing all tokens tokens.",
          type: "error",
        },
        config: { ...BASE_TOAST_CONFIG },
      });
    } finally {
      setActionLoading(false);
      setAction(undefined);
    }
  }, [processWithdrawalAll, mergedTokens, isWeb3AuthWallet]);

  useEffect(() => {
    setTokensAmountValue(0);
    setCurrentTab(walletModalSelectedTab);
  }, [visible]);

  const amountError =
    Number(
      currentTab === IFundOrWithdrawModalTabs.DEPOSIT
        ? walletBalance
        : playBalance
    ) < tokensAmountValue
      ? "Insufficient funds"
      : "";
  const solGasFee = "0.00005";

  useEffect(() => {
    setTokensAmountValue(0);
  }, [selectedTokenMeta?.mint]);

  return (
    <BaseModal
      open={visible}
      title="Funding"
      onClose={() => {
        hideModal();
        hideAutoSigningAgreementModal();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`,
      }}
    >
      <div
        data-id="add-funds-and-play"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center font-normal gap-y-1">
            <Tabs
              classes={{ wrapper: "self-stretch w-full" }}
              activeTab={currentTab}
              onTabSelect={setCurrentTab}
              tabs={[
                {
                  name: IFundOrWithdrawModalTabs.DEPOSIT,
                  label: IFundOrWithdrawModalTabs.DEPOSIT,
                },
                {
                  name: IFundOrWithdrawModalTabs.WITHDRAW,
                  label: IFundOrWithdrawModalTabs.WITHDRAW,
                },
              ]}
            />
          </div>
          <div className="flex w-full flex-col gap-y-4">
            <FormItem
              className="self-stretch [&>label]:font-normal"
              label="Token"
            >
              <Dropdown
                items={tokensDropdownItems}
                classes={{
                  input: `h-12 bg-gray-900 hover:bg-gray-500 [&[data-headlessui-state="open"]]:bg-gray-900`,
                  menu: "top-1 bg-gray-500 px-2 w-full",
                }}
                containerStyles="w-full sm:w-full"
                triggerButton={({ open }) => (
                  <div className="flex items-center justify-between relative w-full px-2">
                    <div className="flex items-center cursor-pointer w-full">
                      {selectedTokenMeta != null &&
                      tokensDropdownItems.find((token) => {
                        return (
                          token.data.context?.pubkey == selectedTokenMeta.mint
                        );
                      }) != null ? (
                        <>
                          <IconUrl
                            iconUrl={selectedMerged?.context?.imageDarkPng}
                            className="w-5 h-5 mr-2 mb-0.5"
                          />
                          <div className="flex flex-col gap-y-0.5">
                            <div className="text-base font-semibold">
                              {`${selectedMerged?.context?.name} (${truncatePubkey(selectedTokenMeta?.mint || "", 3)})`}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <IconFont
                      className={`transition-all ${open ? "rotate-180" : ""} text-[24px]`}
                      name="arrow_down"
                    />
                  </div>
                )}
                itemComponent={({ item }) => {
                  return (
                    <button
                      className="flex w-full bg-gray-500 rounded-lg hover:bg-gray-400 items-center p-2 justify-between"
                      disabled={item.isUpdatingBalance}
                      onClick={() => {
                        setSelectedTokenMeta({
                          mint: item.data.context.pubkey,
                          decimals: item.data.context.decimals,
                        });
                      }}
                    >
                      <div className="flex items-center">
                        <IconUrl
                          iconUrl={item?.data?.context.imageDarkPng}
                          className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5"
                        />
                        <div className="flex flex-col gap-y-0.5 relative top-0.5 text-left">
                          <div className="flex justify-start text-base font-semibold capitalize">
                            {item.name}
                          </div>
                        </div>
                      </div>
                      {item.isUpdatingBalance ? <Spinner size="xs" /> : null}
                    </button>
                  );
                }}
              />
            </FormItem>

            <FormItem
              className="self-stretch [&>label]:font-normal"
              label="Amount"
              rightLabel={
                <div className="flex font-semibold">
                  {currentTab === IFundOrWithdrawModalTabs.DEPOSIT ? (
                    <>
                      In Wallet:{" "}
                      {formatZeebitNumber(
                        walletBalance,
                        NumberType.TOKEN_AMOUNT,
                        10,
                        selectedMerged?.context?.decimals || 6
                      )}
                    </>
                  ) : (
                    <>
                      Play Balance:{" "}
                      {formatZeebitNumber(
                        playBalance,
                        NumberType.TOKEN_AMOUNT,
                        10,
                        selectedMerged?.context?.decimals || 6
                      )}
                    </>
                  )}
                  &nbsp;{selectedMerged?.context?.symbol}
                </div>
              }
              error={amountError}
            >
              <Input
                classes={{
                  label: amountError ? "" : "border-none",
                  input: "py-3",
                }}
                name="token-amount"
                variant="game"
                type="number"
                // isPreview={isPreview}
                // disabled={disabled || isPreview}
                step={0.1}
                min={"0"}
                error={amountError}
                value={String(tokensAmountValue)}
                onChange={(e) => {
                  setTokensAmountValue(Number(e.target.value));
                }}
                leftInfo={
                  <Icon
                    iconUrl={selectedMerged?.context?.imageDarkPng}
                    className="mb-[2px] mr-[5px] [&>img]:rounded-full"
                  />
                }
                rightInfo={
                  <div className="ml-[5px] flex gap-1 [&>button]:w-11">
                    <InputButton
                      onClick={() => {
                        setTokensAmountValue(
                          Number(
                            (tokensAmountValue ||
                              (currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                                ? walletBalance
                                : playBalance)) / 2
                          )
                        );
                      }}
                    >
                      Half
                    </InputButton>
                    <InputButton
                      onClick={() => {
                        setTokensAmountValue(
                          Number(
                            currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                              ? walletBalance
                              : playBalance
                          )
                        );
                      }}
                    >
                      Max
                    </InputButton>
                  </div>
                }
              />
            </FormItem>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div className="flex-col text-gray-200 text-sm gap-1 bg-gray-600 rounded-md p-2 font-normal">
              <Accordion
                items={[
                  {
                    title: (
                      <div className="flex w-full justify-between pr-1 text-gray-50 text-base font-normal">
                        <div>
                          {currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                            ? "Play Balance"
                            : "Connected Wallet"}
                          :
                        </div>
                        <div className="flex">
                          {tokensAmountValue > 0 ? "+" : ""}
                          &nbsp;
                          {formatZeebitNumber(
                            tokensAmountValue,
                            NumberType.TOKEN_AMOUNT,
                            10,
                            selectedMerged?.context?.decimals || 6
                          )}
                          &nbsp;
                          <Icon
                            iconUrl={selectedMerged?.context?.imageDarkPng}
                            className="relative top-0.5"
                            size="md"
                          />
                        </div>
                      </div>
                    ),
                    content: (
                      <div className="text-gray-200 text-base font-normal">
                        <div className="flex justify-between">
                          <div>Current Balance:</div>
                          <div>
                            {formatZeebitNumber(
                              currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                                ? playBalance
                                : walletBalance,
                              NumberType.TOKEN_AMOUNT,
                              10,
                              selectedMerged?.context?.decimals || 6
                            )}
                            &nbsp;{selectedMerged?.context?.symbol}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>
                            After{" "}
                            {currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                              ? "Deposit"
                              : "Withdrawal"}
                            :
                          </div>
                          <div>
                            {formatZeebitNumber(
                              currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                                ? playBalance + tokensAmountValue
                                : playBalance - tokensAmountValue,
                              NumberType.TOKEN_AMOUNT,
                              10,
                              selectedMerged?.context?.decimals || 6
                            )}
                            &nbsp;{selectedMerged?.context?.symbol}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>Gas fee:</div>
                          <div>{solGasFee} SOL</div>
                        </div>
                      </div>
                    ),
                    key: "inPlayerWallet",
                  },
                ]}
              />
            </div>
            {/* ONLY ALLOW AUTO SIGNING IF NOT WEB3AUTH WALLET */}
            {/* HIDE IF NOT WEB3 AUTH AND ON MB (MUST BE TRUE FOR FLOWS TO WORK) */}
            {walletPubkey != null &&
            isWeb3AuthWallet == false &&
            currentTab === IFundOrWithdrawModalTabs.DEPOSIT &&
            chain != APP_NETWORK_TYPE.MB_AND_SOLANA ? (
              <div className="flex items-start self-stretch text-gray-200">
                <label className="flex gap-x-2">
                  <div className="self-center">
                    <CheckBox
                      size={CheckboxSize.sm}
                      checked={allowsAutoSigning}
                      setChecked={(isChecked) => {
                        if (
                          isChecked &&
                          (!lamportBalance || lamportBalance < 5000)
                        ) {
                          showAutoSigningNeedsMoreSolModal();
                        } else {
                          setAllowsAutoSigning(isChecked);
                        }
                      }}
                    />
                  </div>

                  <div className="flex font-normal text-sm items-center">
                    <span>Approve ‘Auto-signing’ experience</span>&nbsp;
                    <Tooltip content={"Tooltip content"}>
                      <IconFont name="information" size="md" />
                    </Tooltip>
                  </div>
                </label>
              </div>
            ) : (
              ""
            )}
            <Button
              variant="divvy"
              className={twMerge(
                "w-full disabled:border-0 disabled:bg-gray-600",
                amountError || tokensAmountValue <= 0 ? "bg-none" : ""
              )}
              size="sm"
              isLoading={actionLoading}
              disabled={
                amountError ||
                tokensAmountValue <= 0 ||
                houseTokenDisabled == true ||
                finishedInitialChecks == false
              }
              onClick={
                currentTab === IFundOrWithdrawModalTabs.DEPOSIT
                  ? handleDepositClick
                  : () => handleWithdraw()
              }
            >
              {currentTab}
            </Button>
            {currentTab === IFundOrWithdrawModalTabs.WITHDRAW &&
            selectedMerged?.playerToken != null &&
            selectedMerged.playerToken.houseToken.isDelegated == true &&
            selectedMerged.playerToken.playBalance > 0 ? (
              <Button
                variant="divvy"
                className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800 disabled:border-0 disabled:bg-gray-600"
                size="sm"
                disabled={houseTokenDisabled || finishedInitialChecks == false}
                onClick={withdrawAndEndSession}
                isLoading={actionLoading}
              >
                Withdraw And End Session
              </Button>
            ) : (
              ""
            )}
            {currentTab === IFundOrWithdrawModalTabs.WITHDRAW && (
              <Button
                variant="divvy"
                className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800 disabled:border-0 disabled:bg-gray-600"
                size="sm"
                disabled={
                  selectedMerged?.playerToken?.playBalance === 0 ||
                  finishedInitialChecks == false
                }
                onClick={() => handleWithdraw(true)}
              >
                Withdraw All
              </Button>
            )}
          </div>
          {lastTransactionError ? (
            <div className="bg-amber-900 rounded py-2 px-3 text-sm text-amber-300 font-normal line-clamp-4">
              <b>Note:</b> Previous transaction has failed due to "
              {lastTransactionError}"
            </div>
          ) : null}
        </div>
      </div>
    </BaseModal>
  );
};

interface IAutoSigningAgreementModalProps extends ModalProps {
  onAutoSigningApprove: Function;
  onModalsClose: Function;
}

export const AutoSigningAgreementModal: FC<IAutoSigningAgreementModalProps> = ({
  visible,
  hideModal,
  onAutoSigningApprove,
  onModalsClose,
}) => {
  const [shouldHide, setShouldHide] = useState(false);

  return (
    <BaseModal
      open={visible}
      onClose={() => {
        hideModal();
        onModalsClose();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`,
      }}
      withHeading={false}
    >
      <div
        data-id="auto-signing-agreement"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center font-normal gap-y-1">
            <div className="flex font-semibold text-xl">Auto-signing</div>
            <div className="flex text-center text-gray-200">
              Without ‘Auto-signing’ you will be required to manually sign
              transactions each time you bet. Are you sure you don’t need it?
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div
              className={twMerge("flex items-start self-stretch text-gray-200")}
            >
              <label className="flex gap-x-2">
                <div className="self-center">
                  <CheckBox
                    size={CheckboxSize.sm}
                    checked={shouldHide}
                    setChecked={setShouldHide}
                  />
                </div>

                <div className="flex font-normal text-sm items-center">
                  Don't show again
                </div>
              </label>
            </div>
            <div className="flex gap-x-3">
              <Button
                variant="gray"
                className="w-full h-10"
                size="sm"
                onClick={() => {
                  onAutoSigningApprove();
                  onModalsClose();
                  hideModal();
                }}
              >
                Use Auto-signing
              </Button>
              <Button
                variant="divvy"
                className="w-full h-10"
                size="sm"
                onClick={() => {}}
              >
                Continue Without
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
