import { createContext, useMemo, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { ChainType, defaultChainType, defaultNetworkType, NetworkType } from "../types/chain";
import { SolanaExplorer } from "../utils/explorer/explorer";

export enum PriorityFees {
  LOW='Low',
  MEDIUM='Medium',
  HIGH='High'
}

export const microLamprtsForFeeLevel = (fee: PriorityFees) => {
  switch (fee) {
    case PriorityFees.HIGH:
      return 20000
    case PriorityFees.MEDIUM:
      return 10000
    case PriorityFees.LOW:
      return 10000
  }
}

interface IUserPreference {
  chainType: ChainType;
  chainNetworkType: NetworkType;
  playHeavyAnimations: boolean;
  setPlayHeavyAnimations: (play: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  includeGlobalBetstream: boolean;
  setIncludeGlobalBetstream: (include: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  showSmartContractInfo: boolean;
  setShowSmartContractInfo: (show: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  volumeLevel: number;
  setVolumeLevel: (volume: number) => React.Dispatch<React.SetStateAction<number>>;
  enableHotkeys: boolean;
  setEnableHotkeys: (enable: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  enableMaxBet: boolean;
  setEnableMaxBet: (enable: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  isTokenValuesInUSD: boolean;
  setIsTokenValuesInUSD: React.Dispatch<React.SetStateAction<boolean>>;
  isSolGasValuesVisible: boolean;
  setIsSolGasValuesVisible: (enable: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  isResultAnimationSkipped: boolean;
  setIsResultAnimationSkipped: (enable: boolean) => React.Dispatch<React.SetStateAction<boolean>>;
  solanaExplorer: SolanaExplorer,
  setSolanaExplorer: (explorer: SolanaExplorer) => React.Dispatch<React.SetStateAction<SolanaExplorer>>,
  priorityFees: PriorityFees,
  setPriorityFees: (fees: PriorityFees) => React.Dispatch<React.SetStateAction<PriorityFees>>,
}

export const UserPreferenceContext = createContext<IUserPreference>({} as IUserPreference);

interface Props {
  children: any;
}

const defaultUserPreferences = {
  enableHotkeys: true,
  isTokenValuesInUSD: false,
  isSolGasValuesVisible: true,
  volumeLevel: 50,
  enableMaxBet: true,
  isResultAnimationSkipped: false,
  solanaExplorer: SolanaExplorer.SOLANA_EXPLORER,
  priorityFees: PriorityFees.HIGH,
};

export const USER_PREFERENCES_KEY = "zeebit-user-preferences"

export const UserPreferenceProvider = (props: Props) => {
  const [chainType, setChainType] = useLocalStorage("aver-selected-chain", defaultChainType);
  const [chainNetworkType, setChainNetworkType] = useLocalStorage(
    "aver-selected-network-type",
    defaultNetworkType,
  );
  const [ userPreferences, setUserPreferences ] = useLocalStorage(USER_PREFERENCES_KEY, defaultUserPreferences);
  const handleUserPreferencesSave = (preferenceKey: string) => (value: boolean|number) => {
    setUserPreferences((preferences: object) => ({
      ...preferences,
      [preferenceKey]: value
    }));
  }

  const [playHeavyAnimations, setPlayHeavyAnimations] = useState(true);
  const [includeGlobalBetstream, setIncludeGlobalBetstream] = useState(true);
  const [showSmartContractInfo, setShowSmartContractInfo] = useState(true);

  return (
    <UserPreferenceContext.Provider
      value={useMemo(
        () => ({
          chainType: chainType,
          setChainType: setChainType,
          chainNetworkType: chainNetworkType,
          setChainNetworkType: setChainNetworkType,
          playHeavyAnimations: playHeavyAnimations,
          setPlayHeavyAnimations: setPlayHeavyAnimations,
          includeGlobalBetstream: includeGlobalBetstream,
          setIncludeGlobalBetstream: setIncludeGlobalBetstream,
          showSmartContractInfo: showSmartContractInfo,
          setShowSmartContractInfo: setShowSmartContractInfo,
          volumeLevel: userPreferences.volumeLevel,
          setVolumeLevel: handleUserPreferencesSave("volumeLevel"),
          enableHotkeys: userPreferences.enableHotkeys,
          setEnableHotkeys: handleUserPreferencesSave("enableHotkeys"),
          enableMaxBet: userPreferences.enableMaxBet,
          setEnableMaxBet: handleUserPreferencesSave("enableMaxBet"),
          isTokenValuesInUSD: userPreferences.isTokenValuesInUSD,
          setIsTokenValuesInUSD: handleUserPreferencesSave("isTokenValuesInUSD"),
          isSolGasValuesVisible: userPreferences.isSolGasValuesVisible,
          setIsSolGasValuesVisible: handleUserPreferencesSave("isSolGasValuesVisible"),
          isResultAnimationSkipped: userPreferences.isResultAnimationSkipped,
          setIsResultAnimationSkipped: handleUserPreferencesSave("isResultAnimationSkipped"),
          solanaExplorer: userPreferences.solanaExplorer,
          setSolanaExplorer: handleUserPreferencesSave("solanaExplorer"),
          priorityFees: userPreferences.priorityFees,
          setPriorityFees: handleUserPreferencesSave("priorityFees"),
        }),
        [
          chainType,
          chainNetworkType,
          playHeavyAnimations,
          includeGlobalBetstream,
          showSmartContractInfo,
          userPreferences
        ],
      )}
    >
      {props.children}
    </UserPreferenceContext.Provider>
  );
};
