import { useMemo } from "react";
import IconFont, { IconName } from "../common/iconFont/IconFont";
import IconButton from "../common/button/IconButton";
import { ToastOptions, CloseButtonProps, ToastContentProps } from "react-toastify";
import Button from "../common/button/Button";
import { twMerge } from "tailwind-merge";
import Icon, { IconSizes } from "../common/icon/Icon";
import Spinner from "../../assets/icons/loading-spinner-gradient.svg";

export type ToasterType = "success" | "error" | "warning" | "regular" | "loading";

export const BASE_TOAST_CONFIG: ToastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  pauseOnHover: false,
  draggable: false,
  bodyClassName: "p-0",
  className: "p-0 bg-inherit",
  style: {},
  closeButton: false,
};

export interface IBaseToast extends ToastContentProps {
  title?: any;
  message: any;
  type: ToasterType;
  icon?: React.ReactNode;
  iconsSize?: IconSizes
  iconName?: IconName;
  closeLabel?: string;
  className?: string;
  classes?: {
    wrapper?: string;
    iconWrapper?: string;
    icon?: string;
    title?: string;
    message?: string;
    button?: string;
  }
}

export const BaseToast = ({
  type,
  icon,
  iconName,
  iconsSize,
  title,
  message,
  closeLabel,
  closeToast,
  className,
  classes
}: IBaseToast) => {
  const toastIcon = useMemo((): React.ReactNode | undefined => {
    if (!!icon) {
      return icon;
    }
    if (!!iconName) {
      return <IconFont name={iconName} size={iconsSize || "xl"} />;
    }
    if (type == "success") {
      return <IconFont size={iconsSize || "xl"} name="correct" className={classes?.icon} />;
    } else if (type == "warning") {
      return <IconFont size={iconsSize || "xl"} name="alert" className={classes?.icon} />;
    } else if (type == "error") {
      return <IconFont size={iconsSize || "xl"} name="error" className={classes?.icon} />;
    } else if (type == "loading") {
      return <Icon size={iconsSize || "xl"} icon={<Spinner />} className={classes?.icon} />;
    }
  }, [type, icon, iconsSize, classes]);

  return (
    <div
      className={twMerge(
        "flex w-[340px] p-3 justify-center items-center gap-x-3 bg-gray-700 rounded-lg shadow-brand-regular text-white",
        className, classes?.wrapper
      )}
    >
      {toastIcon ? <ToastIcon type={type} icon={toastIcon} className={classes?.iconWrapper} /> : ""}

      <div className="flex pr-2 flex-col justify-center items-start flex-1">
        {title && (
          <div className={twMerge("font-bold text-gray-50", classes?.title)}>
            {title}
          </div>
        )}
        {message && <div className={twMerge("text-sm font-normal text-gray-200 self-stretch", classes?.message)}>{message}</div>}
      </div>
      {type !== "loading" ?
        closeLabel ? (
          <Button size="sm" variant="gray" onClick={closeToast} className={classes?.button}>
            {closeLabel}
          </Button>
        ) : (
          <IconButton iconName="close_md" className={twMerge("material-icons", classes?.button)} onClick={closeToast} />
        ) : null}
    </div>
  );
};

export interface IToastIcon {
  type: ToasterType;
  icon: any;
  className?: string;
}

export const ToastIcon = ({ type, icon, className }: IToastIcon) => {
  const colorClasses = useMemo(() => {
    if (type == "success") {
      return "bg-response-success text-gray-50";
    } else if (type == "error") {
      return "bg-rose-500 text-gray-50";
    } else if (type == "warning") {
      return "bg-response-warning text-gray-50";
    } else if (type == "loading") {
      return "bg-transparent text-gray-50";
    } else {
      return "bg-global-bg text-gray-200";
    }
  }, [type]);

  return (
    <div className={twMerge("flex p-2 items-start gap-x-[10px] rounded-md ${colorClasses}", colorClasses, className)}>{icon}</div>
  );
};
