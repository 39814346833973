import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { ModalProps } from "./types";
import Icon from "../common/icon/Icon";
import { BaseModal, Button } from "../common";
import Input from "../common/input/Input";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import FormItem from "../common/form-item/FormItem";
import InputButton from "../common/input/InputButton";
import {
  NumberType,
  formatZeebitNumber,
} from "../../utils/currency/formatting";
import {
  NetworkContext,
  PlayerTokenContext,
  ToasterContext,
  BalanceContext,
  AggregatedBalancesContext,
} from "../../contexts";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../toast/BaseToast";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

interface IAddFundsAndPlayModalProps extends ModalProps {
  onFundSuccess?: Function;
}

export const TransferToAutoSignBalanceModal: FC<IAddFundsAndPlayModalProps> = ({
  visible,
  hideModal,
  onFundSuccess,
}) => {
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { selectedTokenMeta } = useContext(BalanceContext);
  const selectedMerged = useMemo(() => {
    return mergedTokens?.find((token) => {
      return token.context?.symbol === "SOL";
    });
  }, [mergedTokens, selectedTokenMeta]);

  const walletBalance = useMemo(() => {
    if (selectedMerged?.wallet == null) {
      return 0;
    }
    return selectedMerged?.wallet.uiAmount;
  }, [selectedMerged]);

  const toast = useContext(ToasterContext);

  const [tokensAmountValue, setTokensAmountValue] = useState("0");
  const { signerKp, allowsAutoSigning, topUpSol, lamportBalance } = useContext(
    SessionAuthorityContext
  );
  const { loadPlayerToken } = useContext(PlayerTokenContext);

  const lamportBalanceWithPrecision =
    Number(lamportBalance || 0) / LAMPORTS_PER_SOL;

  const [actionLoading, setActionLoading] = useState(false);
  const { client, recentBlockhash } = useContext(NetworkContext);

  const handleTopUpSolClick = useCallback(async () => {
    try {
      // IF INITIALISING THE ACCOUNT, MUST BE > 0.008 SOL
      if ((lamportBalance || 0) == 0) {
        if (tokensAmountValue <= 0.008) {
          toast(
            <BaseToast
              message={`You must deposit more than 0.008 Sol.`}
              type={"error"}
            />,
            BASE_TOAST_CONFIG
          );

          return;
        }
      }

      // CONFIRM THE TX
      await topUpSol(Number(tokensAmountValue));

      toast(
        <BaseToast
          message={`Successfully deposited ${tokensAmountValue} ${selectedMerged?.context?.name}.`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG
      );

      onFundSuccess?.();
      // HIDE MODAL
      hideModal();
    } catch (err) {
      console.error({ err });
      toast(
        <BaseToast
          message={`There was an issue depositing tokens.`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG
      );
    } finally {
      setActionLoading(false);
    }
  }, [
    tokensAmountValue,
    selectedMerged,
    client,
    recentBlockhash,
    loadPlayerToken,
    signerKp,
    allowsAutoSigning,
  ]);

  useEffect(() => {
    setTokensAmountValue(0);
  }, [visible]);

  const amountError =
    Number(walletBalance) < Number(tokensAmountValue)
      ? "Insufficient funds"
      : "";
  const solGasFee = "0.00005";

  return (
    <BaseModal
      open={visible}
      withHeading={false}
      onClose={hideModal}
      classes={{
        dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`,
      }}
    >
      <div
        data-id="transfer-to-auto-balance"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col gap-y-4 items-center">
            <div className="flex w-full mt-3 justify-center">
              <img
                src={getS3StaticFolderUrl("/static/top-up-auto-sign.png")}
                className="flex w-1/2"
              />
            </div>

            <div className="flex flex-col items-center gap-y-2.5 self-stretch text-center">
              <div className="text-xl font-semibold">Top Up Auto-Signing</div>
              <div className="subtitle text-base font-normal text-gray-200">
                Add more SOL to use as Auto-Signing gas, you can claim any
                remaining SOL from this balance at any time.
              </div>
            </div>

            <FormItem
              className="self-stretch [&>label]:font-normal"
              label="Amount"
              rightLabel={
                <div className="flex font-semibold">
                  In Wallet: &nbsp;
                  {formatZeebitNumber(walletBalance, NumberType.TOKEN_AMOUNT)}
                  &nbsp;{selectedMerged?.context?.symbol}
                </div>
              }
              error={amountError}
            >
              <Input
                classes={{
                  label: amountError ? "" : "border-none",
                  input: "py-3",
                }}
                name="token-amount"
                variant="game"
                type="number"
                step={0.1}
                error={amountError}
                value={tokensAmountValue}
                onChange={(e) => {
                  const value = e.target.value;
                  const [firstChar, ...restChars] = value.split("");
                  if (
                    e.target.value.length > 1 &&
                    firstChar === "0" &&
                    restChars[0] !== "."
                  ) {
                    const valueWithDot = [firstChar, ".", ...restChars].join(
                      ""
                    );
                    setTokensAmountValue(valueWithDot);
                  } else {
                    setTokensAmountValue(value);
                  }
                }}
                leftInfo={
                  <Icon
                    iconUrl={selectedMerged?.context?.imageDarkPng}
                    className="mb-[2px] mr-[5px] [&>img]:rounded-full"
                  />
                }
                rightInfo={
                  <div className="ml-[5px] flex gap-1 [&>button]:w-11">
                    <InputButton
                      onClick={() => {
                        setTokensAmountValue(
                          String(Number(tokensAmountValue) / 2)
                        );
                      }}
                    >
                      Half
                    </InputButton>
                    <InputButton
                      onClick={() =>
                        setTokensAmountValue(String(walletBalance))
                      }
                    >
                      Max
                    </InputButton>
                  </div>
                }
              />
            </FormItem>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <div className="flex-col text-gray-200 text-sm gap-1 bg-gray-600 rounded-md p-2 font-normal">
              <div className="flex justify-between">
                <div>In Signing Wallet:</div>
                <div>
                  {lamportBalanceWithPrecision}{" "}
                  {selectedMerged?.context?.symbol}
                </div>
              </div>
              <div className="flex justify-between">
                <div>After Deposit:</div>
                <div>
                  {lamportBalanceWithPrecision + Number(tokensAmountValue)}
                  &nbsp;{selectedMerged?.context?.symbol}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Gas fee:</div>
                <div>{solGasFee} SOL</div>
              </div>
            </div>

            <Button
              variant="divvy"
              className={twMerge(
                "w-full",
                amountError || Number(tokensAmountValue) <= 0 ? "bg-none" : ""
              )}
              size="sm"
              isLoading={actionLoading}
              disabled={amountError || Number(tokensAmountValue) <= 0}
              onClick={handleTopUpSolClick}
            >
              Add SOL
            </Button>
            <Button
              variant="divvy"
              className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800"
              size="sm"
              onClick={hideModal}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
