export const ZEEBIT_DOCS_URL = `https://zeebit.gitbook.io/zeebit.io/`;
export const SWITCHBOARD_HOMEPAGE = 'https://switchboard.xyz/';
export const SEC_3_HOMEPAGE = 'https://www.sec3.dev/';
export const ZEEBIT_WEEKLY_RACE_PROMO = 'https://medium.com/@Zeebit/22b6a1b4ebbb';
export const ZEEBIT_TWITTER_URL = 'https://twitter.com/Zeebitio';
export const ZEEBIT_DEVNET = 'https://dev.zeebit.io/';
export const ZEEBIT_MAINNET = 'https://zeebit.io/';
export const DIVVY_LEADERBOARD_POINTS_JSON_URL =
  "https://divvybet-prod.s3.amazonaws.com/stats/divvy-points.json";
export const DIVVY_BETTING_URL = "https://app.divvy.bet";
