import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerToken from "../playerToken";

export default class CrashMulti extends Game {
  constructor(
    house: House,
    gameSpecPubkey: PublicKey,
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
    const game = new CrashMulti(house, gameSpecPubkey);
    console.log({ game });

    await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)

    return game
  }

  async multiBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: {
      targetMultiplier: number;
      wagerBasis: number;
    },
    wager: number,
    clientSeed: number[]
  ) {
    const instanceRequest = {
      crashMulti: {},
    };

    const betRequests = [{
      crashMulti: {
        targetMultiplierPerMillion: new anchor.BN(inputs.targetMultiplier),
        wager: new anchor.BN(inputs.wagerBasis),
      },
    }];

    return await this.multiPlayIx(
      ownerOrAuth,
      playerToken,
      instanceRequest,
      betRequests,
      clientSeed
    );
  }

  get state() {
    return this.baseState
  }

  get gameConfig() {
    return this.state ? this.state.config.crashMulti : null;
  }

  get maxCoins() {
    return this.gameConfig?.maxCoins;
  }

  getMultiplier(input: object) {
    return Number(this.gameConfig?.maxMultiplierPerMillion) / 1_000_000;
  }

  getProbability(input: object) {
    const multiplier = input.targetMultiplier / 1_000_000;
    return 1 / multiplier;
  }

  get edgePercentage() {
    return (this.gameConfig?.edgePerMillion?.toNumber() || (0.02 * 1_000_000)) / 1_000_000
  }

  // EACH BET HAS WAGER, NUM COINS, NUM CORRECT
  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;
    let totalWagerBasis = 0;

    bets.forEach((bet) => {
      const multiplier = bet.multiplier;
      const payoutOnBet = multiplier * bet.wager;
      const probability = (1 - this.edgePercentage) * (1 / multiplier);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wagerBasis;
      totalWagerBasis += bet.wagerBasis;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      totalWagerBasis: totalWagerBasis,
      edgePercentage: edgeDollar / totalWagerBasis, // USED IN CALCULATING MAX BET VIA KELLY
    };
  }
}
