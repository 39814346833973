import React, { FC, useState } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./style.css";
import { twMerge } from "tailwind-merge";

export interface TooltipProps extends TippyProps {
  content: React.ReactNode;
  showOnClick?: boolean;
  className?: string;
  tippyClassName?: string;
  bgColor?: "gray-500" | "purple-500";
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  className,
  showOnClick = false,
  tippyClassName,
  bgColor = "gray-500",
  ...props
}) => {
  const trigger = showOnClick ? "click" : "mouseenter focus";
  const bgClasses = {
    "gray-500": "[&>.tippy-content]:bg-[var(--gray-500)] [&>.tippy-arrow]:text-[var(--gray-500)]",
    "purple-500":
      "[&>.tippy-content]:bg-[var(--purple-500)] [&>.tippy-arrow]:text-[var(--purple-500)]",
  };
  if (!content) return children;
  return (
    <Tippy
      className={twMerge("ToolTip cursor-pointer", bgClasses[bgColor], tippyClassName)}
      content={content}
      trigger={trigger}
      interactive={true}
      appendTo={document.body}
      {...props}
    >
      <div className={`${className}`}>{children}</div>
    </Tippy>
  );
};

export default Tooltip;
