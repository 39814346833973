import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

export interface InputButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dark?: boolean;
  children: string | React.ReactNode;
}

const InputButton: FC<InputButtonProps> = ({ dark, children, className, ...props }) => {
  return (
    <button
      className={twMerge(
        `w-[32px]  h-[28px] text-sm font-normal flex justify-center items-center bg-gray-700 hover:bg-gray-600 active:bg-gray-800 rounded-sm text-gray-50
        ${dark && "bg-transparent hover:bg-gray-800 active:bg-transparent text-gray-400"}`,
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default InputButton;
