import { createContext, useCallback, useMemo, useState } from "react";
import { RightNavType } from "../types/nav";
import useToggle from "../hooks/useToggle";
import { isDesktop } from "react-device-detect";
import { useLocalStorage } from "../hooks/useLocalStorage";

export enum ProfileTab {
  DASHBOARD = "Profile",
  REWARDS_CALENDAR = "Rewards",
  SETTINGS = "Settings",
  TRANSACTIONS = "Transactions",
  REFERRALS = "Referrals",
  Logout = "Logout",
}

export interface INavContext {
  leftPanelOpen: boolean;
  toggleLeftPanel: (value?: boolean | undefined) => void;
  toggleLeftPanelRightClosed: (value?: boolean | undefined) => void;
  rightPanelOpen: boolean;
  toggleRightPanel: (value?: boolean | undefined) => void;
  toggleRightPanelLeftClosed: (value?: boolean | undefined) => void;
  rightPanelType: RightNavType;
  setRightPanelType: React.Dispatch<React.SetStateAction<RightNavType>>;
  closeAllBars: () => void;
  userProfilePanelOpen: boolean;
  toggleUserProfilePanel: (value?: boolean | undefined) => void;
  profileTab: ProfileTab;
  setProfileTab: React.Dispatch<React.SetStateAction<ProfileTab>>;
  searchOpen: boolean;
  searchToggle: (value?: boolean | undefined) => void;
}

export const NavContext = createContext<INavContext>({} as INavContext);

interface Props {
  children: any;
}

export const NavProvider = ({ children }: Props) => {
  const [profileTab, setProfileTab] = useLocalStorage(
    "zeebit-selected-profile-tab",
    ProfileTab.DASHBOARD
  );
  const { value: leftBarIsOpen, toggleValue: leftBarToggle } = useToggle(false);
  const { value: rightBarIsOpen, toggleValue: rightBarToggle } = useToggle();
  const [rightPanelType, setRightPanelType] = useState<RightNavType>(
    RightNavType.CHAT
  );
  const { value: userProfileIsOpen, toggleValue: userProfileToggle } =
    useToggle(false);
  const { value: searchOpen, toggleValue: searchToggle } = useToggle(false);

  // ONLY ONE CAN BE OPEN FOR MOBILE
  const toggleLeftPanelRightClosed = useCallback(() => {
    if (rightBarIsOpen) {
      rightBarToggle();
    }

    return leftBarToggle();
  }, [rightBarIsOpen, rightBarToggle, leftBarToggle]);

  const toggleRightPanelLeftClosed = useCallback(() => {
    if (leftBarIsOpen) {
      leftBarToggle();
    }

    return rightBarToggle();
  }, [leftBarIsOpen, leftBarToggle, rightBarToggle]);

  const closeAllBars = useCallback(() => {
    if (leftBarIsOpen) {
      leftBarToggle(false);
    }

    if (rightBarIsOpen) {
      rightBarToggle(false);
    }

    if (userProfileIsOpen) {
      userProfileToggle(false);
    }
  }, [leftBarIsOpen, rightBarIsOpen, userProfileIsOpen]);

  return (
    <NavContext.Provider
      value={useMemo(
        () => ({
          rightPanelOpen: rightBarIsOpen,
          toggleRightPanel: rightBarToggle,
          toggleRightPanelLeftClosed: toggleRightPanelLeftClosed,
          leftPanelOpen: leftBarIsOpen,
          toggleLeftPanel: leftBarToggle,
          toggleLeftPanelRightClosed: toggleLeftPanelRightClosed,
          rightPanelType: rightPanelType,
          setRightPanelType: setRightPanelType,
          closeAllBars: closeAllBars,
          userProfilePanelOpen: userProfileIsOpen,
          toggleUserProfilePanel: userProfileToggle,
          profileTab: profileTab,
          setProfileTab: setProfileTab,
          searchOpen: searchOpen,
          searchToggle: searchToggle,
        }),
        [
          rightBarIsOpen,
          leftBarIsOpen,
          rightPanelType,
          toggleLeftPanelRightClosed,
          toggleRightPanelLeftClosed,
          closeAllBars,
          userProfileIsOpen,
          profileTab,
          searchOpen,
        ]
      )}
    >
      {children}
    </NavContext.Provider>
  );
};
