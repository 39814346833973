import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import House from "../house";
import GameSpec from "../gameSpec";
import { ZeebitV2 } from "../../target/types/zeebit_v2";
import PlayerToken from "../playerToken";
import GameInstanceSolo from "../gameInstance";
import { APP_NETWORK_TYPE } from "../../types/chain";

export const GRID_SIZE = 25;

export default class Mines extends GameSpec {

  constructor(
    house: House,
    // hseTkn: HouseToken,
    gameSpecPubkey: PublicKey,
  ) {
    super(
      house,
      // hseTkn,
      gameSpecPubkey,
    )
    this.gameSpecPubkey = gameSpecPubkey;
  }
  
  static async load(
    house: House,
    gameSpecPubkey: PublicKey,
  ) {
    const game = new Mines(
      house,
      gameSpecPubkey,
    )
    await game.loadAllState();
    return game
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: {
      gridSize: number,
      numMines: number,
      cellIdx: number,
      wagerBasis: number,
      action?: {
        chosenCellIdx: number
      },
      insurance: boolean,
      identifier: PublicKey,
    },
    wager: number,
    clientSeed: number[],
  ) {
    const instanceRequest = {
      mines: { gridSize: inputs.gridSize, numMines: inputs.numMines },
    };

    const betRequests = [
      {
        mines: {
          cellIdx: inputs.cellIdx,
          wager: new anchor.BN(inputs.wagerBasis)
        }
      }
    ];

    const actionRequest = inputs.action ? {
      mines: {
        chosenCellIdx: inputs.action.chosenCellIdx
      }
    } : null;
    console.log({ actionRequest, betRequests, instanceRequest });

    const numberOfBets = 1;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      actionRequest,
      clientSeed,
      inputs.identifier,
    );
  }

  get state() {
    // FALL BACK TO BASE STATE
    return this.isDelegated ? (this.erState || this.baseState) : this.baseState;
  }

  get gameConfig() {
    return this.state ? this.state.config.blackjack : null;
  }

  get houseEdge(): number {
    if (this.state.config.mines) {
      return Number(this.state.config.mines.edgePerMillion) / 1_000_000
    } else {
      return undefined
    }
  }

  get maxMultiplier(): number {
    return 10 // TODO MAX MULTIPLIER
  }
  // let probability_per_million = ((remaining_cells - remaining_mines) as u64 * 1_000_000) / remaining_cells as u64;
  // let multiplier_per_million = ((1_000_000 * (1_000_000 - edge_per_million as u128)) / probability_per_million as u128) as u64;
  // let multiplier_per_million_rounded = (multiplier_per_million / rounding_denominator) * rounding_denominator;
  getMultiplier(input: { remainingCells: number, remainingMines: number }): number {
    const probability = this.getProbability(input);
    return (1_000_000 - this.state.config.mines.edgePerMillion) / probability / 1_000_000;
  }
  getProbability(input: { remainingCells: number, remainingMines: number }): number {
    return (input.remainingCells - input.remainingMines) / input.remainingCells;
  }
  potentialPayout(
    wager: number
  ): number {
    return wager * this.maxMultiplier
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;
    let totalWagerBasis = 0;

    bets.forEach((bet) => {
      const multiplier = bet.multiplier;
      const payoutOnBet = multiplier * bet.wager;
      const probability = bet.probability;

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wagerBasis;
      totalWagerBasis += bet.wagerBasis;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      totalWagerBasis: totalWagerBasis,
      edgePercentage: edgeDollar / totalWagerBasis, // USED IN CALCULATING MAX BET VIA KELLY
    };
  }
}

export class MinesInstance extends GameInstanceSolo {

  constructor(
    gameSpec: GameSpec,
    instancePubkey: PublicKey,
    playerToken: PlayerToken,
    chain: APP_NETWORK_TYPE,
    baseState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"],
    erState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"]
  ) {
    super(instancePubkey, gameSpec, playerToken, chain, baseState, erState)
  }

  get isPlayersTurn(): boolean {
    return this.state && (Object.keys(this.state.status)[0] == "awaitingPlayerResponse") ? true : false
  }
}





