import React, { FC, useContext } from "react";
import { useModal } from "react-modal-hook";

import { BaseModal } from "../BaseModal";
import { ModalProps } from "../../components/modals/types";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import Button from "../../components/common/button/Button";
import {
  CheckBox,
  CheckboxSize,
} from "../../components/common/check-box/check-box";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import { IconFont, Tooltip } from "../../components/common";
import { AutoSigningNeedsMoreSolModal } from "./AutoSigningNeedsMoreSolModal";

interface IAutoSigningModalProps extends ModalProps {
  shouldHideAutoSigningModal: boolean;
  setShouldHideAutoSigningModal: Function;
  showFundsAndPlayModal: Function;
}

export const AutoSigningModal: FC<IAutoSigningModalProps> = ({
  visible,
  hideModal,
  shouldHideAutoSigningModal,
  setShouldHideAutoSigningModal,
  showFundsAndPlayModal,
}) => {
  const { setAllowsAutoSigning, lamportBalance } = useContext(
    SessionAuthorityContext
  );

  const [showAutoSigningNeedsMoreSolModal, hideAutoSigningNeedsMoreSolModal] =
    useModal(
      ({ in: open }) => (
        <AutoSigningNeedsMoreSolModal
          visible={open}
          hideModal={() => {
            hideAutoSigningNeedsMoreSolModal();
          }}
        />
      ),
      []
    );

  return (
    <BaseModal open={visible} hideModal={hideModal}>
      <div className="flex w-[85vw] md:w-[460px] flex-col items-start gap-y-3 bg-gray-800">
        <div className="flex flex-col w-full items-center gap-y-3">
          <div className="flex w-1/3 mt-3">
            <img
              src={getS3StaticFolderUrl("/static/auto-signing-icon.png")}
              className="flex w-full"
            />
          </div>

          <div className="flex pt-3 flex-col items-center gap-y-2.5 self-stretch text-center">
            <div className="text-xl font-semibold">Auto-Signing</div>
            <div className="subtitle text-base font-normal text-gray-200">
              To play without signing each bet transaction you need delicate a
              little SOL (claimable back) and transfer some funds to ‘Play
              Balance’. Only funds in Play Balance can be used with Auto-Signing
              feature. Think of this as buying casino chips, transfer back
              anytime.
            </div>
          </div>

          <div className="flex items-start self-stretch text-gray-200">
            <label className="flex gap-x-2">
              <div className="self-center">
                <CheckBox
                  size={CheckboxSize.sm}
                  checked={shouldHideAutoSigningModal}
                  setChecked={setShouldHideAutoSigningModal}
                />
              </div>

              <div className="flex font-normal text-sm items-center">
                <span>Don’t show this message again</span>&nbsp;
                <Tooltip content={"_"}>
                  <IconFont name="information" size="md" />
                </Tooltip>
              </div>
            </label>
          </div>

          <div className="flex flex-col items-start gap-y-3 self-stretch mt-3">
            <Button
              disabled={false}
              className="w-full"
              variant="divvy"
              size="md"
              onClick={() => {
                if (!lamportBalance || lamportBalance < 5000) {
                  showAutoSigningNeedsMoreSolModal();
                } else {
                  setAllowsAutoSigning(true);
                  showFundsAndPlayModal();
                }

                hideModal();
              }}
            >
              Transfer & Auto-Sign
            </Button>
            <Button
              variant="divvy"
              className="w-full bg-none border-2 border-gray-50 hover:after:border-gray-800"
              size="md"
              onClick={() => {
                hideModal();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
