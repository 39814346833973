import * as anchor from "@coral-xyz/anchor";
import { Commitment, PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerToken from "../playerToken";

export enum CoinFlipSide {
  HEADS = 0,
  TAILS = 1,
}

export default class CoinFlip extends Game {
  constructor(
    house: House,
    // hseTkn: HouseToken,
    gameSpecPubkey: PublicKey,
  ) {
    super(house, gameSpecPubkey);
  }

  static async load(house: House, gameSpecPubkey: PublicKey, commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateUpdates: boolean = false) {
    const game = new CoinFlip(house, gameSpecPubkey);
    await game.loadAllState(commitmentLevel, loadChildState, trackStateUpdates);
    return game
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: object,
    wager: number,
    clientSeed: number[]
  ) {
    const instanceRequest = {
      coinFlip: {
        numCoins: inputs.numberOfCoins,
      },
    };

    const betRequests = [{
      coinFlip: {
        side: inputs.side,
        numCorrect: inputs.numberCorrect,
        wager: new anchor.BN(inputs.wagerBasis),
      },
    }];

    const numberOfBets = 1;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      null,
      clientSeed
    );
  }

  get state() {
    return this.baseState
  }

  get gameConfig() {
    return this.state ? this.state.config.coinFlip : null;
  }

  get maxCoins() {
    return this.gameConfig?.maxCoins;
  }

  getMultiplier(input: object) {
    const multiplierRecord = this.gameConfig?.multipliers.find(
      (m) => m.numCorrect == input.numberCorrect && m.numTossed == input.numberOfCoins,
    );
    return Number(multiplierRecord?.multiplierPerMillion) / 1_000_000;
  }

  getProbability(input: object) {
    const choose = (n: number, k: number) => {
      if (k === 0) return 1;
      return (n * choose(n - 1, k - 1)) / k;
    };

    const numberOfCoins = input.numberOfCoins;
    const numberCorrect = input.numberCorrect;

    const pCorrect = 0.5;
    const pIncorrect = 1 - pCorrect;
    var prob = 0;
    for (let k = Number(numberOfCoins); k >= Number(numberCorrect); k--) {
      const probKCorrect = pCorrect ** k * pIncorrect ** (numberOfCoins - k);
      const combinationsOfKfromN = choose(numberOfCoins, k);
      prob += probKCorrect * combinationsOfKfromN;
    }
    return prob;
  }

  // EACH BET HAS WAGER, NUM COINS, NUM CORRECT
  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;
    let totalWagerBasis = 0;

    bets.forEach((bet) => {
      const multiplier = bet.multiplier;
      const payoutOnBet = multiplier * bet.wager;
      const probability = bet.probability;

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wagerBasis;
      totalWagerBasis += bet.wagerBasis;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      totalWagerBasis: totalWagerBasis,
      edgePercentage: edgeDollar / totalWagerBasis, // USED IN CALCULATING MAX BET VIA KELLY
    };
  }
}
