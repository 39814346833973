import * as anchor from "@coral-xyz/anchor";
import { ZeebitV2 } from "./program-types/solana_zeebit_v2";
import { PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getAssociatedTokenAddressSync } from '@solana/spl-token';
import House from "./house";
import { toHouseTokenStatus } from "./utils";
import { HouseTokenStatus } from "./enums";

export default class HouseToken {

    private _house: House;
    private _tokenMintPubkey: PublicKey;
    private _houseTokenPubkey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["houseToken"];
    private _baseState: anchor.IdlAccounts<ZeebitV2>["houseToken"];

    constructor( 
        house: House,
        tokenMintPubkey: PublicKey,
    ) {
        this._house = house;
        this._tokenMintPubkey = tokenMintPubkey;
        this._houseTokenPubkey = HouseToken.deriveHouseTokenPubkey(
            house.publicKey,
            tokenMintPubkey,
            house.baseProgram.programId
        );;   
        
    };

    static async load(
        house: House,
        tokenMintPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const houseToken = new HouseToken(
            house,
            tokenMintPubkey,
        )
        await houseToken.loadBaseState(commitmentLevel);
        if (houseToken.isDelegated) {
            houseToken.loadErState(commitmentLevel);
        }
        return houseToken
    };

   

    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.houseToken.fetchNullable(
            this._houseTokenPubkey,
            commitmentLevel
        );
        if (state) {
            this._baseState = state;
        }
        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.erProgram.account.houseToken.fetchNullable(
            this._houseTokenPubkey,
            commitmentLevel
        );
        if (state) {
            this._erState = state;
        } 
        return
    }

    static deriveHouseTokenPubkey(
        housePubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_token"),
                housePubkey.toBuffer(),
                tokenMintPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    static deriveHouseTokenBankPubkey(
        housePubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_token_bank"),
                housePubkey.toBuffer(),
                tokenMintPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    static deriveHouseTokenVaultPubkey(
        houseTokenPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        return getAssociatedTokenAddressSync(
            tokenMintPubkey,
            houseTokenPubkey,
            true
        );
    };

    deriveHouseTokenUpdatePubkey(
        updateNonce: anchor.BN
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_token_update"),
                this.publicKey.toBuffer(),
                updateNonce.toBuffer('le', 8)
            ],
            this.baseProgram.programId
        );
        return pk
    }

    get house() {
        return this._house
    }

    get baseProgram() {
        return this.house.baseProgram
    }

    get erProgram() {
        return this.house.erProgram
    }

    get programId() {
        return this.house.baseProgram.programId
    }

    get publicKey() {
        return this._houseTokenPubkey
    }

    get tokenMintPubkey() {
        return this._tokenMintPubkey
    }

    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get delegationStatus(): string | null {
        return this.baseState?.delegationStatus ? Object.keys(this.baseState.delegationStatus)[0] : null;
    }

    get isDelegated() {
        return this.delegationStatus ? (this.delegationStatus == "delegated" ? true : false) : false;
    }

    get bankPublicKey() {
        return HouseToken.deriveHouseTokenBankPubkey(this.house.publicKey, this.tokenMintPubkey, this.programId);
    }

    get incrementUnit() {
        return this.baseState?.incrementUnit != null ? Number(this.baseState?.incrementUnit): undefined
    }

    get availableBalance() {
        return this.baseState != null ? Number(this.baseState.availableBalance): undefined
    }

    get lockedBalance() {
        return this.baseState != null ? Number(this.baseState.lockedBalance): undefined
    }

    get status() {
        return this._baseState != null ? toHouseTokenStatus(this._baseState.status): undefined
    }

    get isActive() {
        return this.status == HouseTokenStatus.Active
    }

    get vaultPublicKey() {
        return HouseToken.deriveHouseTokenVaultPubkey(
            this.bankPublicKey,
            this.tokenMintPubkey
        )
    }

    get state() {
        return this._baseState || this._erState
    }

    get proportionTotalBankrollBps() {
        return this.state?.proportionTotalBankrollBps
    }

    static async airdropTokensIx (house: House, amount: number, owner: PublicKey, tokenMint: PublicKey): Promise<TransactionInstruction> {
        const tokenAccountPubkey = getAssociatedTokenAddressSync(tokenMint, owner, false);
    
        return await house.baseProgram.methods.airdrop({
          amount: new anchor.BN(amount)
        }).accounts({
          owner: owner,
          house: house.publicKey,
          tokenMint: tokenMint,
          tokenAccount: tokenAccountPubkey,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId
        }).instruction()
    }
}