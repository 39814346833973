import { useMemo } from "react";
import { BaseToast } from "../BaseToast";

export type IDepositToastType = "success" | "error" | "loading";

export interface IDepositToast {
  type: IDepositToastType;
  title?: string;
  message?: string;
}

export const DepositToast = ({ type, title, message }: IDepositToast) => {
  const buttonClasses = "bg-transparent opacity-80 hover:bg-transparent hover:opacity-100 active:bg-transparent";
  const messageClasses = "text-gray-50";
  const iconWrapperClasses = "bg-transparent p-0";

  const toast = useMemo(() => {
    if (type == "error") {
      return <BaseToast
        message={message}
        type={type}
        iconName="error"
        iconsSize="xxl"
        classes={{
          wrapper: "border border-rose-300 bg-[linear-gradient(90deg,_#CF364C_0%,#E14F62_100%)]",
          message: messageClasses,
          iconWrapper: iconWrapperClasses,
          button: buttonClasses,
        }}

      />;
    } else if (type == 'success') {
      return <BaseToast
        title={!message ? '' : title}
        message={!message ? title : message}
        type={type}
        iconName="transactions"
        iconsSize="xxl"
        classes={{
          wrapper: "border border-teal-300 bg-[linear-gradient(90deg,_#439288_0%,#56B5A6_100%)]",
          message: messageClasses,
          iconWrapper: iconWrapperClasses,
          button: buttonClasses,
        }}
      />
    } else {
      return <BaseToast
        title={!message ? '' : title}
        message={!message ? title : message}
        type={type}
        iconsSize="xxl"
        classes={{
          wrapper: "border border-gray-400 bg-gray-600",
          message: messageClasses,
          iconWrapper: iconWrapperClasses,
          button: buttonClasses,
        }}
      />
    }
  }, [type, title, message]);
  return toast;
};
