// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Scrollbar .os-scrollbar,
.Scrollbar-vertical .os-scrollbar {
  --os-padding-perpendicular-size: 6;
  --os-handle-bg: var(--custom-handle-color);
  opacity: 0.4;
}
.Scrollbar:hover .os-scrollbar {
  opacity: 1;
}
.Scrollbar {
  width: calc(100% + 12px);
  margin-right: -12px;
  padding-right: 12px;
}
.Scrollbar-vertical {
  height: calc(100% + 12px);
  margin-bottom: -12px;
  padding-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/scrollbar/styles.css"],"names":[],"mappings":"AAAA;;EAEE,kCAAkC;EAClC,0CAA0C;EAC1C,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,oBAAoB;EACpB,oBAAoB;AACtB","sourcesContent":[".Scrollbar .os-scrollbar,\n.Scrollbar-vertical .os-scrollbar {\n  --os-padding-perpendicular-size: 6;\n  --os-handle-bg: var(--custom-handle-color);\n  opacity: 0.4;\n}\n.Scrollbar:hover .os-scrollbar {\n  opacity: 1;\n}\n.Scrollbar {\n  width: calc(100% + 12px);\n  margin-right: -12px;\n  padding-right: 12px;\n}\n.Scrollbar-vertical {\n  height: calc(100% + 12px);\n  margin-bottom: -12px;\n  padding-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
