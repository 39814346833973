import { PriorityFees, USER_PREFERENCES_KEY } from "../../contexts/UserPreferenceContext"

export const toMaxPriorityFee = (priorityFee: PriorityFees) => {
    switch(priorityFee) {
      case PriorityFees.HIGH:
        return 500_000_000
      case PriorityFees.MEDIUM:
        return 1_000_000
      case PriorityFees.LOW:
        return 10_000
      default:
        console.warn(`Unknown Priority fee level ${priorityFee}`)
        return 10_000
    }
  }

  export const getMaxPriorityFee = () => {
    // const userPrefs = JSON.parse(localStorage.getItem(USER_PREFERENCES_KEY) || '{}')
    // const priority = userPrefs?.priorityFees

    // if (priority == null) {
    //   return 500_000
    // }

    // return toMaxPriorityFee(priority)

    return 500_000
  }