import { APP_NETWORK_TYPE } from "../types/chain"
import { Wallets } from "./wallets";

// WALLETS ALLOWED ON EACH CHAIN
export const ENABLED_SOLANA_WALLETS = [Wallets.PHANTOM, Wallets.BACKPACK, Wallets.SOLFLARE, Wallets.METAMASK]
export const ENABLED_SONIC_WALLETS = [Wallets.OKX_WALLET, Wallets.BACKPACK]
export const ENABLED_MB_AND_SOLANA = [Wallets.PHANTOM, Wallets.BACKPACK, Wallets.SOLFLARE, Wallets.METAMASK] // VERIFY THIS WHEN TESTING MAGIC BLOCK

export const getEnabledWalletsForChain = (chain: APP_NETWORK_TYPE) => {
    switch (chain) {
        case APP_NETWORK_TYPE.SOLANA:
            return ENABLED_SOLANA_WALLETS;
        case APP_NETWORK_TYPE.MB_AND_SOLANA:
            return ENABLED_MB_AND_SOLANA;
    }
}