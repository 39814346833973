import React, { FC, ReactNode, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";

export type ITableItem = {
  id: string;
  [key: string]:
    | {
        value?: string | number;
        suffix?: ReactNode;
        prefix?: ReactNode;
        subValue?: string;
        flag?: string;
        cellStyles?: string;
        colSpan?: number;
      }
    | string;
};

export interface HomePageTableProps {
  items: ITableItem[];
  header: (string | { value: string | React.ReactNode; cellStyles?: string })[];
  emptyMessage?: string;
  padding?: string;
  classes?: {
    tableBodyStyles?: string;
    rowStyles?: string;
  };
  isLoading?: boolean;
  loadingRowsNumber?: number;
  onClickRow?: Function;
  onLoadMore?: Function;
  variant?: "bicolor" | "withGaps" | null;
}

const Table: FC<HomePageTableProps> = ({
  header,
  items,
  emptyMessage = "No Game History Available.",
  classes,
  padding = "p-1.5",
  isLoading,
  loadingRowsNumber = 5,
  onClickRow,
  onLoadMore,
  variant = "bicolor",
}) => {
  const tableRows = isLoading
    ? [
        ...items,
        ...Array.from(Array(loadingRowsNumber).keys()).map(() =>
          header.map(() => ({ isLoadingCell: true })),
        ),
      ]
    : items;

  const intersectionObserverElementRef = useIntersectionObserver(() => onLoadMore?.(items));

  return (
    <div
      className={twMerge(
        `${padding} min-w-full`,
        classes?.tableBodyStyles || "rounded-md bg-gray-800",
      )}
    >
      <table className="relative min-w-full">
        <thead className="relative z-10">
          <tr>
            {header
              .filter((h) => h !== "id")
              .map((title, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`
                      px-1.5 py-1.5 text-center text-xs font-normal uppercase text-gray-400 first:text-left last:text-right 
                      max-sm:first:table-cell max-sm:last:table-cell sm:first:table-cell 
                      sm:last:table-cell lg:table-cell sm:[&:nth-child(2)]:table-cell md:[&:nth-child(3)]:table-cell  
                      max-sm:[&:nth-child(5)]:table-cell sm:[&:nth-child(5)]:table-cell
                      ${title?.cellStyles || "hidden"}
                    `}
                >
                  <div>{typeof title === "string" ? title : title?.value}</div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="relative z-0">
          {tableRows.map(({ id, rowStyles = "", ...item }, index) => (
            <tr
              key={id ?? index}
              className={twMerge(
                `
                ${onClickRow ? "cursor-pointer" : ""}
                relative
                `,
                `${variant === "bicolor" ? "odd:bg-gray-700" : ""}`,
                `${variant === "withGaps" ? "[&:hover>td>div]:bg-gray-600" : ""}`,
                classes?.rowStyles,
                rowStyles,
              )}
              onClick={() => (onClickRow as Function)?.(index)}
            >
              {Object.values(item).map((value, index) => {
                const showSuccessText = ["won", "push"].includes(value?.flag || "");
                if (!value) return;
                return (
                  <td
                    key={index}
                    colSpan={value?.colSpan || 1}
                    className={twMerge(
                      `
                    relative z-10  whitespace-nowrap px-2 text-center text-sm
                    font-normal text-gray-50 first:rounded-l-md first:pl-3 first:text-left last:rounded-r-md 
                    last:pr-3 last:text-right
                    max-sm:first:table-cell max-sm:last:table-cell sm:first:table-cell 
                    sm:last:table-cell lg:table-cell sm:[&:nth-child(2)]:table-cell md:[&:nth-child(3)]:table-cell  
                    max-sm:[&:nth-child(5)]:table-cell
                    sm:[&:nth-child(5)]:table-cell
                    [&>div>div]:first:justify-start [&>div>div]:last:justify-end`,
                      `${value?.isLoadingCell ? "animate-pulse" : ""}`,
                      `${variant === "withGaps" && !isLoading ? "h-auto" : "h-[44px]"}`,

                      `${value?.cellStyles || "hidden"}`,
                    )}
                  >
                    <div
                      className={twMerge(
                        `row-rounded-border absolute  `,
                        `${variant === "withGaps" ? "bottom-1 left-0 top-1 w-full bg-gray-700" : "left-0 top-0 h-full w-full"}`,
                      )}
                    ></div>
                    {value?.isLoadingCell ? (
                      <div className="relative h-3 w-full rounded bg-gray-600"></div>
                    ) : (
                      <>
                        {typeof value === "string" ? (
                          value
                        ) : (
                          <div className="flex flex-col">
                            <div
                              data-layout="flex-container"
                              className={`
                                  relative z-10 flex items-center justify-center gap-1
                                  ${
                                    index === 0
                                      ? "justify-start"
                                      : index === Object.values(item).length - 1
                                        ? "justify-end"
                                        : "justify-center"
                                  }
                                  ${showSuccessText ? "text-emerald-500" : ""} 
                                `}
                            >
                              {value?.prefix}
                              {(value?.value || value?.suffix || value?.subValue) && (
                                <div className="flex flex-col">
                                  <div className="flex items-center justify-start gap-1">
                                    {value?.value}
                                    {value?.suffix}
                                  </div>
                                  <span className="text-xs text-gray-300">{value?.subValue}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {!tableRows?.length && (
        <div className="flex h-[54px] w-full items-center justify-center text-gray-400">
          {emptyMessage}
        </div>
      )}
      {<div ref={intersectionObserverElementRef}></div>}
    </div>
  );
};

export default Table;

interface IHomePageTableLoading {
  numberRows: number;
}

export function HomePageTableLoading({ numberRows }: IHomePageTableLoading) {
  const header = ["Game", "Player", "Time", "Bet Amount", "Multiplier", "Payout"];
  const rows = Array.from(Array(numberRows).keys());

  return (
    <div className=" min-w-full rounded-md bg-gray-800 p-1.5">
      <table className="min-w-full">
        <thead>
          <tr>
            {header
              .filter((h) => h !== "id")
              .map((title) => (
                <th
                  key={title}
                  scope="col"
                  className={`hidden px-1.5 py-1.5 text-center text-xs font-normal uppercase text-gray-400 first:text-left 
                    last:text-right 
                    max-sm:first:table-cell max-sm:last:table-cell sm:first:table-cell 
                    sm:last:table-cell lg:table-cell sm:[&:nth-child(2)]:table-cell md:[&:nth-child(3)]:table-cell  
                    max-sm:[&:nth-child(5)]:table-cell sm:[&:nth-child(5)]:table-cell`}
                >
                  {title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="">
          {rows.map((index) => (
            <tr key={index} className="cursor-pointer odd:bg-gray-700 ">
              {/* even:hover:bg-gray-500 hover:bg-gray-700  */}
              {header.map((value) => (
                <td
                  key={value}
                  className={`
                    hidden whitespace-nowrap px-1.5 py-3 text-center text-sm font-normal 
                    text-gray-50 first:rounded-l-md first:pl-3 first:text-left last:rounded-r-md last:pr-3 
                    last:text-right max-sm:first:table-cell
                    max-sm:last:table-cell 
                    sm:first:table-cell sm:last:table-cell lg:table-cell 
                    sm:[&:nth-child(2)]:table-cell md:[&:nth-child(3)]:table-cell max-sm:[&:nth-child(5)]:table-cell sm:[&:nth-child(5)]:table-cell  
                    [&>div]:first:justify-start
                    [&>div]:last:justify-end
                  `}
                >
                  {
                    <div className={`flex animate-pulse items-center justify-center gap-1`}>
                      {
                        {
                          Game: (
                            <>
                              <div className="mr-1 h-5 w-5 rounded-full bg-gray-600"></div>
                              <div className="h-4 w-24 rounded bg-gray-600"></div>
                            </>
                          ),
                          Player: (
                            <>
                              <div className="mr-1 h-5 w-5 rounded-sm bg-gray-600"></div>
                              <div className="h-3 w-20 rounded bg-gray-600"></div>
                            </>
                          ),
                          Time: (
                            <>
                              <div className="h-3 w-20 rounded bg-gray-600"></div>
                            </>
                          ),
                          "Bet Amount": (
                            <>
                              <div className="h-3 w-20 rounded bg-gray-600"></div>
                              <div className="ml-1 h-5 w-5 rounded-full bg-gray-600"></div>
                            </>
                          ),
                          Multiplier: (
                            <>
                              <div className="h-3 w-10 rounded bg-gray-600"></div>
                            </>
                          ),
                          Payout: (
                            <>
                              <div className="h-3 w-20 rounded bg-gray-600"></div>
                              <div className="ml-1 h-5 w-5 rounded-full bg-gray-600"></div>
                            </>
                          ),
                        }[value]
                      }
                    </div>
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
