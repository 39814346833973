import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export interface ImageProps {
  url: string;
  classes?: {
    wrapper?: string;
    image?: string;
  };
}

export const ImageStatic: FC<PropsWithChildren<ImageProps>> = ({ url, classes, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleLoad = () => {
    // console.log({ event });
    setIsLoading(false);
  };

  useEffect(() => {
    if (imgRef && imgRef.current && imgRef.current.getAttribute("listener") !== "true") {
      imgRef.current.addEventListener("load", handleLoad, { once: true });
    }
    return () => {
      if (imgRef && imgRef.current) {
        handleLoad();
        imgRef.current.removeEventListener("load", handleLoad);
      }
    };
  }, [imgRef.current]);

  return (
    <div
      className={twMerge(
        "bg-animated-gradient-gray-700-gray-800 aspect-square shrink-0 grow overflow-hidden rounded-lg",
        classes?.wrapper,
      )}
    >
      <img src={url} ref={imgRef} className="hidden" />
      {!isLoading ? (
        <div
          style={{
            "--image-url": `url(${url})`,
          }}
          className={twMerge("h-full w-full bg-[image:var(--image-url)] bg-cover", classes?.image)}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
