import * as anchor from "@coral-xyz/anchor";
import { ZeebitV2 } from "./program-types/zeebit_v2_solana";
import { PublicKey, MemcmpFilter, TransactionInstruction, Connection, TransactionError, Commitment, AccountMeta } from "@solana/web3.js";
import { listenForTransaction, toGameStatus } from "./utils";
import House from "./house";
import HouseToken from "./houseToken";
import PlayerToken from "./playerToken";
import { sha256 } from 'js-sha256';
import { sleep } from "../utils/time/sleep";
import { NATIVE_MINT, getAssociatedTokenAddressSync } from "@solana/spl-token";

export type GameSpecInterval =
    | { onDemand: {} }
    | { timeCycle: { offset: anchor.BN, interval: number, min_remaining: number } }
    | { slotCycle: { offset: anchor.BN, interval: number, min_remaining: number } }

export default class GameSpec {

    private _house: House;
    // private _houseToken: HouseToken;
    private _gameSpecPubkey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["gameSpec"];
    private _baseState: anchor.IdlAccounts<ZeebitV2>["gameSpec"];
    private _gameSpecTokens: Map<string, anchor.IdlAccounts<ZeebitV2>["gameSpecToken"]>;


    // EVENT PARSERS
    private _baseInstanceParser: anchor.EventParser
    private _erInstanceParser: anchor.EventParser


    constructor(
        house: House,
        // houseToken: HouseToken,
        gameSpecPubkey: PublicKey,
    ) {
        this._house = house;
        this._gameSpecPubkey = gameSpecPubkey;
        // this._houseToken = houseToken;

        // SET THE PARSERS
        this._baseInstanceParser = new anchor.EventParser(
            house.baseProgram.programId,
            new anchor.BorshCoder(house.baseProgram.idl),
        );

        this._erInstanceParser = new anchor.EventParser(
            house.erProgram.programId,
            new anchor.BorshCoder(house.erProgram.idl),
        );
    };

    static async load(
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: Commitment = "processed",
        loadChildState: boolean = false,
        trackStateUpdates: boolean = false
    ) {
        const gameSpec = new GameSpec(
            house,
            gameSpecPubkey
        )

        await gameSpec.loadAllState(commitmentLevel, loadChildState, trackStateUpdates)

        console.log({
            gameSpec
        })

        return gameSpec
    };

    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        console.log({
            gameSpecPubkey: this._gameSpecPubkey.toString(),
            commitmentLevel
        });

        const state = await this.baseProgram.account.gameSpec.fetchNullable(
            this._gameSpecPubkey,
            commitmentLevel
        );
        console.log({ loadBaseState: state });

        if (state) {
            this._baseState = state;
        } else {
            console.warn(`loadBaseState A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }

        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        console.log('loadErState', { _gameSpecPubkey: this._gameSpecPubkey });

        const state = await this.erProgram.account.gameSpec.fetchNullable(
            this._gameSpecPubkey,
            commitmentLevel
        );
        console.log('loadErState', state);
        if (state) {
            this._erState = state;
        }
        // else {
        //     throw new Error(`loadErState A valid account was not found at the pubkey provided: ${this.publicKey}`)
        // }
        return
    }

    async loadAllState(commitmentLevel: Commitment = "processed", loadChildState: boolean = false, trackStateChanges: boolean = false) {
        // LOADING BASE STATE/ER STATE
        await this.loadBaseState(commitmentLevel)
        await this.loadErState(commitmentLevel)

        // LOAD SUPPORTED TOKENS
        await this.loadSupportedTokens()
    }

    // METHOD TO LOAD INSTANCE SOLO OR INSTANCE MULTI and INSTANCE MULTI TOKENS, 
    async loadChildState(commitmentLevel: Commitment = "processed") {
        // LOAD SUPPORTED TOKENS
        await this.loadSupportedTokens()
    }

    // METHOD WHICH OPENS AN ON-LOGS LISTENER AND UPDATES STATE ACCORDINGLY
    async trackStateChanges() { }

    async loadErInstanceState(
        instancePubkey: PublicKey
    ) {
        let state;
        try {
            state = await this.erProgram.account.instanceSolo.fetchNullable(instancePubkey, "processed");
        } catch (error) {
            console.log('loadInstance fetchNullable', error);
        }
        return state;
    }

    async loadInstanceState(
        instancePubkey: PublicKey
    ) {
        let state;
        try {
            state = await this.baseProgram.account.instanceSolo.fetchNullable(instancePubkey, "processed");
        } catch (error) {
            console.warn('loadInstance fetchNullable', error);
        }
        return state;
    }

    async loadInstanceMultiState(
        instanceMultiPubkey: PublicKey,
        commitment: Commitment = "processed"
    ) {
        let state;
        try {
            state = await this.baseProgram.account.instanceMulti.fetchNullable(instanceMultiPubkey, commitment);
        } catch (error) {
            console.log('loadInstance fetchNullable', error);
        }
        return state;
    }

    async loadErInstanceMultiState(
        instanceMultiPubkey: PublicKey,
        commitment: Commitment = "processed"
    ) {
        let state;
        try {
            state = await this.erProgram.account.instanceMulti.fetchNullable(instanceMultiPubkey, commitment);
        } catch (error) {
            console.log('loadInstance fetchNullable', error);
        }
        return state;
    }

    async loadInstanceTokenMultiState(
        instanceTokenMultiPubkey: PublicKey
    ) {
        let state;
        try {
            state = await this.baseProgram.account.instanceTokenMulti.fetchNullable(instanceTokenMultiPubkey);
        } catch (error) {
            console.log('loadInstance fetchNullable', error);
        }
        return state;
    }

    async loadErInstanceTokenMultiState(
        instanceTokenMultiPubkey: PublicKey
    ) {
        let state;
        try {
            state = await this.erProgram.account.instanceTokenMulti.fetchNullable(instanceTokenMultiPubkey);
        } catch (error) {
            console.log('loadInstance fetchNullable', error);
        }
        return state;
    }

    async loadSupportedTokens() {
        const baseGameSpecTokens = await this.baseProgram.account.gameSpecToken.all(
            [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as MemcmpFilter],     // For this GameSpec
        );

        const erGameSpecTokens = await this.erProgram.account.gameSpecToken.all(
            [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as MemcmpFilter],     // For this GameSpec
        );
        let gstMap = new Map<string, anchor.IdlAccounts<ZeebitV2>["gameSpecToken"]>();
        
        [...baseGameSpecTokens, ...erGameSpecTokens].map((gst) => {
            gstMap.set(
                gst.account.tokenMint.toString(),
                gst.account
            )
        })

        this._gameSpecTokens = gstMap;
        console.log(`end GameSpec.loadSupportedTokens() ${gstMap}`)

        return gstMap
    }

    static deriveGameSpecTokenPubkey(
        gameSpecPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        programId: PublicKey,
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("game_spec_token"),
                gameSpecPubkey.toBuffer(),
                tokenMintPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    deriveGameSpecTokenPubkey(
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        return GameSpec.deriveGameSpecTokenPubkey(
            this.publicKey,
            tokenMintPubkey,
            this.baseProgram.programId,
        )
    };


    get oracleListPubkey() {
        return House.deriveHouseOracleListPubkey(this.publicKey, this.programId)
    }

    get baseProgram() {
        return this.house.baseProgram
    }

    get erProgram() {
        return this.house.erProgram
    }

    get programId() {
        return this.baseProgram.programId
    }

    get house() {
        return this._house
    }


    get publicKey() {
        return this._gameSpecPubkey
    }


    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get supportedTokens() {
        return this._gameSpecTokens
    }

    get supportedTokenByMint() {
        return this._gameSpecTokens
    }

    get isDelegated() {
        return this.baseState ? 
        ["delegated", "delegationActive"].includes(Object.keys(this.baseState.delegationStatus)[0]) 
        : this.erState ? ["delegated", "delegationActive"].includes(Object.keys(this.erState.delegationStatus)[0]): false
    }

    get status() {
        return this.state != null ? toGameStatus(this.state.status) : null;
    }

    get statusString() {
        return this.state != null ? Object.keys(this.state.status)[0] : null;
    }

    get gameType() {
        return this.state != null ? Object.keys(this.state.game)[0] : null;
    }

    get state() {
        // FALL BACK TO BASE STATE
        return this.isDelegated ? (this.erState || this.baseState): this.baseState
    }

    get specType(): string | undefined {
        return this.state?.specType ? Object.keys(this.state?.specType)[0] : undefined
    }

    get isSolo() {
        return this.specType?.includes('solo')
    }

    get isInteractive() {
        return this.specType?.toLowerCase()?.includes('interactive')
    }

    get isMultiplayer() {
        return this.specType?.includes('multiplayer')
    }

    get minWagerInHouseTokenUnits() {
        return this.state != null ? Number(this.state.minWagerInHouseTokenUnits) : undefined
    }

    get maxBetsPerPlaceIxn() {
        return this.state != null ? Number(this.state.maxBetsPerPlaceIxn) : undefined
    }

    deriveMultiInstanceIdx() {
        const now = Math.floor(Date.now() / 1000) + 1;
        var offset: number;
        var interval: number;
        var minRemaining: number;
        if (this.state.interval.timeCycle) {
            const offset = Number(this.state.interval.timeCycle.offset);
            const interval = Number(this.state.interval.timeCycle.interval);
            const minRemaining = Number(this.state.interval.timeCycle.minRemaining);
            const intervals_ever = (((now - offset) / interval) + 1);
            const t = offset + (intervals_ever * interval);

            if ((t - now) < minRemaining) {
                return ((intervals_ever + 1) % 2);
            } else {
                return (intervals_ever % 2);
            };
        }

        // TODO: Other interval types


    }


    static getInstructionDiscriminatorFromSnakeCase(
        ixnNameSnakeCase: string
    ): number[] {
        const preimage = `${"global"}:${ixnNameSnakeCase}`;
        const discriminatorBytes = Buffer.from(sha256.digest(preimage)).slice(0, 8);
        var discriminatorU8s: number[] = [];
        discriminatorBytes.forEach((b) => { discriminatorU8s.push(Number(b)) });
        return discriminatorU8s
    }

    static deriveDisciminatorForResponse(
        specType: "soloSimple" | "soloInteractive" | "multiplayerMultiToken" | "multiplayerOneToken",
    ) {
        switch (specType) {
            case "soloSimple": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("solo_respond")
            };
            case "soloInteractive": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("solo_respond")
            };
            case "multiplayerMultiToken": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("multi_respond")
            };
            case "multiplayerOneToken": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("multi_respond")
            };
        }
    }

    static deriveDisciminatorForExpire(
        specType: "soloSimple" | "soloInteractive" | "multiplayerMultiToken" | "multiplayerOneToken",
    ) {
        switch (specType) {
            case "soloSimple": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("solo_expire")
            };
            case "soloInteractive": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("solo_expire")
            };
        }
    }

    static deriveDisciminatorForSettle(
        specType: "soloSimple" | "soloInteractive" | "multiplayerMultiToken" | "multiplayerOneToken",
    ) {
        switch (specType) {
            case "soloSimple": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("solo_respond")
            };
            case "soloInteractive": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("solo_respond")
            };
            case "multiplayerMultiToken": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("multi_settle")
            };
            case "multiplayerOneToken": {
                return GameSpec.getInstructionDiscriminatorFromSnakeCase("multi_settle")
            };
        }
    }

    async soloBetIx(
        ownerOrAuth: PublicKey,
        playerToken: PlayerToken,
        inputs: object,
        wager: number,
        clientSeed: number[],
        identifierPubkey?: PublicKey): Promise<TransactionInstruction> {
        throw new Error("SHOULD BE IMPLEMENTED ON THE GAME CLASS...")
    }

    async multiBetIx(
        ownerOrAuth: PublicKey,
        playerToken: PlayerToken,
        inputs: object,
        wager: number,
        clientSeed: number[],
        identifierPubkey?: PublicKey): Promise<TransactionInstruction> {
        throw new Error("SHOULD BE IMPLEMENTED ON THE GAME CLASS...")
    }

    async soloPlayIx(
        ownerOrAuth: PublicKey,
        playerToken: PlayerToken,
        countBets: number,
        instanceRequest: any,
        betRequests: any[],
        actionRequest: any | null,
        clientSeed: number[],
        identifierPubkey?: PublicKey,
        withdrawOnSettle: boolean = false
    ): Promise<TransactionInstruction> {
        const program = playerToken.houseToken.isDelegated ? this.erProgram : this.baseProgram;
        const payer = playerToken.houseToken.isDelegated ? ownerOrAuth : this.house.housePayerPubkey();
        const gameSpecTokenPubkey = this.deriveGameSpecTokenPubkey(
            playerToken.houseToken.tokenMintPubkey
        );

        const instanceIdx = 0 // TODO - PROPERLY CHECK FOR ID HERE

        const instancePubkey = PlayerToken.deriveInstancePubkey(
            playerToken.publicKey,
            instanceIdx,
            playerToken.baseProgram.programId
        );

        console.log({
            instanceRequest,
            betRequests,
            actionRequest,
            clientSeed: clientSeed,
            payer: ownerOrAuth.toString(),
            authority: ownerOrAuth.toString(),
            house: this.house.publicKey.toString(),
            houseToken: playerToken.houseToken.publicKey.toString(),
            oracleList: this.house.oracleListPubKey.toString(),
            gameSpec: this.publicKey.toString(),
            gameSpecToken: gameSpecTokenPubkey.toString(),
            playerToken: playerToken.publicKey.toString(),
            identifier: identifierPubkey?.toString(),
            instance: instancePubkey.toString(),
            systemProgram: anchor.web3.SystemProgram.programId.toString()
        });

        if (actionRequest) {
            try {
                return await program.methods.soloAction({
                    actionRequest: actionRequest as any,
                    clientSeed: clientSeed,
                    withdrawOnSettle: (playerToken.isDelegated ? false : withdrawOnSettle)
                }).accounts({
                    payer: payer,
                    authority: ownerOrAuth,
                    house: this.house.publicKey,
                    houseToken: playerToken.houseToken.publicKey,
                    gameSpec: this.publicKey,
                    gameSpecToken: gameSpecTokenPubkey,
                    playerToken: playerToken.publicKey,
                    instance: instancePubkey,
                    identifier: identifierPubkey,
                    systemProgram: anchor.web3.SystemProgram.programId
                }).instruction()
            } catch (error) {
                console.error('soloAction', error);
            }

        } else {
            var remainingAccounts: AccountMeta[] = [];

            if (withdrawOnSettle == true && playerToken.isDelegated == false) {
                const tokenAccountOrWalletPubkey = playerToken.houseToken.tokenMintPubkey == NATIVE_MINT ? this.baseProgram.provider.publicKey : getAssociatedTokenAddressSync(playerToken.houseToken.tokenMintPubkey, ownerOrAuth, false);
                const vaultPubkey = HouseToken.deriveHouseTokenVaultPubkey(playerToken.houseToken.bankPublicKey, playerToken.houseToken.tokenMintPubkey);
                remainingAccounts.push({ pubkey: tokenAccountOrWalletPubkey, isWritable: false, isSigner: false, } as AccountMeta);
                remainingAccounts.push({ pubkey: playerToken.houseToken.bankPublicKey, isWritable: false, isSigner: false, } as AccountMeta);
                remainingAccounts.push({ pubkey: vaultPubkey, isWritable: false, isSigner: false, } as AccountMeta);
            };

            const args = {
                instanceIdx: instanceIdx,
                instanceRequest: instanceRequest as any,
                betRequests: betRequests as any[],
                clientSeed: clientSeed,
                withdrawOnSettle: (playerToken.isDelegated ? false : withdrawOnSettle)
            };

            const accounts = {
                authority: ownerOrAuth,
                owner: playerToken.ownerPubkey,
                payer: payer,
                house: this.house.publicKey,
                houseToken: playerToken.houseToken.publicKey,
                gameSpec: this.publicKey,
                gameSpecToken: gameSpecTokenPubkey,
                playerToken: playerToken.publicKey,
                instance: instancePubkey,
                systemProgram: anchor.web3.SystemProgram.programId
            }

            console.log({
                args,
                accounts
            })


            return await program.methods.soloPlay(
                args
            ).accounts(
                accounts
            ).remainingAccounts(
                remainingAccounts
            ).instruction()
        }
    };

    async multiPlayIx(
        ownerOrAuth: PublicKey,
        playerToken: PlayerToken,
        instanceRequest: any,
        betRequests: any[],
        clientSeed: number[]
    ): Promise<TransactionInstruction> {
        const program = playerToken.houseToken.isDelegated ? this.erProgram : this.baseProgram;
        const payer = playerToken.houseToken.isDelegated ? ownerOrAuth : this.house.housePayerPubkey();

        const gameSpecTokenPubkey = this.deriveGameSpecTokenPubkey(
            playerToken.houseToken.tokenMintPubkey
        );

        const instanceIdx = this.deriveMultiInstanceIdx() || 0;

        const instancePubkey = PlayerToken.deriveInstanceMultiPubkey(
            this.publicKey,
            instanceIdx,
            playerToken.baseProgram.programId
        );
        const instanceTokenPubkey = PlayerToken.deriveInstanceTokenMultiPubkey(
            instancePubkey,
            gameSpecTokenPubkey,
            playerToken.baseProgram.programId
        );

        const accounts = {
            payer: payer,
            authority: ownerOrAuth,
            owner: playerToken.ownerPubkey,
            house: this.house.publicKey,
            houseToken: playerToken.houseToken.publicKey,
            gameSpec: this.publicKey,
            gameSpecToken: gameSpecTokenPubkey,
            playerToken: playerToken.publicKey,
            housePayer: this.house.housePayerPubkey(),
            instance: instancePubkey,
            instanceToken: instanceTokenPubkey,
            systemProgram: anchor.web3.SystemProgram.programId
        };

        return await program.methods.multiPlay({
            instanceIdx: instanceIdx,
            instanceRequest: instanceRequest as any,
            betRequests: betRequests as any[],
            clientSeed: clientSeed
        }).accounts(
            accounts
        ).instruction();
    };

    async soloVoid(
        ownerPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        instancePubkey: PublicKey,
        identifierPubkey: PublicKey,
        confirmationLevel: anchor.web3.Commitment = "processed",
        onSuccessfulSendCallback?: Function,
        onSuccessfulConfirmCallback?: Function,
        onErrorCallback?: Function,
    ) {
        try {
            const program = this.baseProgram; // TODO: Update when we're working on delegation
            const houseTokenPubkey = HouseToken.deriveHouseTokenPubkey(
                this.house.publicKey,
                tokenMintPubkey,
                program.programId
            );
            const playerTokenPubkey = PlayerToken.derivePlayerTokenPubkey(
                houseTokenPubkey,
                ownerPubkey,
                program.programId
            )
            const gameSpecTokenPubkey = this.deriveGameSpecTokenPubkey(
                tokenMintPubkey
            );
            const tx = await program.methods.soloVoid(
                {}
            ).accounts({
                authority: program.provider.publicKey,
                houseToken: houseTokenPubkey,
                gameSpec: this.publicKey,
                gameSpecToken: gameSpecTokenPubkey,
                playerToken: playerTokenPubkey,
                instance: instancePubkey,
                identifier: identifierPubkey,
                systemProgram: anchor.web3.SystemProgram.programId
            }).signers(
                []
            ).rpc(
                { skipPreflight: true }
            );

            console.log(tx);

            if (onSuccessfulSendCallback) {
                onSuccessfulSendCallback(tx);
            };

            listenForTransaction(
                program.provider.connection,
                tx,
                confirmationLevel,
                onSuccessfulConfirmCallback,
                onErrorCallback
            )

        } catch (err) {
            if (onErrorCallback) {
                onErrorCallback(err);
            } else {
                console.error(err);
            }
        }
    };

    getMultiplier(inputs: object): number {
        return 0;
    }

    getProbability(inputs: object): number {
        return 0;
    }

    // GIVEN AN ARRAY OF BETS WE RETURN META DATA LIKE PAYOUT, PROFIT AND WAGERED
    getBetMetas(bets: object[]): {
        bets: object[];
        wager: number;
        profit: number;
        payout: number;
        edgePercentage: number;
    } {
        throw new Error("getBetMetas Function not defined in specific game class");
    }

    get baseInstanceParser() {
        return this._baseInstanceParser
    }

    subscribeToGameSpecEvents(
        client: Connection,
        onGameInstanceOrRoundCreatedEvent: Function,
        onBetSoloCreatedEvent: (args: { [key: string]: any, signature: string }) => Promise<void> | void,
        onBetSoloUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onBetSoloResultedEvent: Function,
        onGameInstanceResultEvent: Function,
        onGameInstanceUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onGameInstanceClosedEvent: Function,
        onError?: Function,
        playerToken?: PlayerToken,
    ) {

        // TODO - ADD FILTER FOR THE GAME HERE
        const handleLogs = async (logs: {
            err: TransactionError | null;
            logs: string[];
            signature: string;
        }, context: {
            slot: number;
        }) => {
            if (logs.err != null) {
                const logsString = logs.logs.join(', ')

                // EXCLUDE CRANKER RELATED ISSUES HERE
                if (logsString.includes('MultiSettle') || logsString.includes('MultiRespond')) {
                    return
                }

                console.error('Error in Game WS Listener', { logs })
                onError?.(logs.err)
                return
            }

            const events = this.baseInstanceParser.parseLogs(logs.logs);

            const signature = logs.signature;

            for (let event of events) {
                console.warn('GAME SPEC - subscribeToGameEvents', event.name, signature);

                switch (event.name) {
                    case "GameInstanceCreated":
                        onGameInstanceOrRoundCreatedEvent?.({ ...event.data, signature });
                        break;
                    case "BetSoloCreated":
                        onBetSoloCreatedEvent?.({ ...event.data, signature });
                        break;
                    case "GameInstanceResulted":
                        onGameInstanceResultEvent({ ...event.data, signature });
                        break;
                    case "BetSoloSettled":
                        onBetSoloResultedEvent?.({ ...event.data, signature });
                        break;
                    case "BetSoloUpdate":
                        onBetSoloUpdateEvent?.({ ...event.data, signature });
                        break;
                    case "GameInstanceUpdate":
                        onGameInstanceUpdateEvent?.({ ...event.data, signature });
                        break;
                    case "GameInstanceClosed":
                        onGameInstanceClosedEvent?.({ ...event.data, signature });
                        break;
                    default:
                        console.warn(`INSTANCE MULTI PARSER -  Not a known event --> `, event)
                }
            }
        };

        const gameSpecOrInstance = this.isMultiplayer || playerToken == null ? this.publicKey: PlayerToken.deriveInstanceSoloPubkey(playerToken.publicKey, 0, this.programId)

        return client.onLogs(gameSpecOrInstance, handleLogs, "processed");
    }

    async subscribeToInstanceMultiEventPolling(
        client: Connection,
        playerToken: PlayerToken,
        gameInstancePubkey: PublicKey,
        onGameInstanceOrRoundCreatedEvent: Function,
        onBetSoloCreatedEvent: (args: { [key: string]: any, signature: string }) => Promise<void>,
        onBetSoloUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onBetSoloResultedEvent: Function,
        onGameInstanceResultEvent: Function,
        onGameInstanceUpdateEvent: (args: { [key: string]: any, signature: string }) => void,
        onGameInstanceClosedEvent: Function,
        onError: Function,
        lastSignature: string,
        timeoutS: number = 20
    ) {
        // TODO - NEED TO ADD FILTER ON GAME HERE
        const handleLogs = async (txMeta: anchor.web3.ParsedTransactionWithMeta | null, signature: string) => {
            if (txMeta == null || txMeta.meta == null || txMeta.meta.logMessages == null) {
                return
            }

            const events = this.baseInstanceParser.parseLogs(txMeta.meta.logMessages);

            for (let event of events) {
                console.log('INSTANCE PARSER - subscribeToGameEventsPolling', event);
                if (event.name == "GameInstanceCreated") {
                    if (onGameInstanceOrRoundCreatedEvent) {
                        onGameInstanceOrRoundCreatedEvent({ ...event.data, signature });
                    }
                    // } else if (event.name == "BetSoloCreated") {
                    //     if (onBetSoloCreatedEvent) {
                    //         await onBetSoloCreatedEvent({ ...event.data, signature });
                    //     }
                    // } else if (event.name == "GameInstanceResulted") {
                    //     if (onGameInstanceResultEvent) {
                    //         onGameInstanceResultEvent({ ...event.data, signature });
                    //     }
                    // } else if (event.name == "BetSoloSettled") {
                    //     if (onBetSoloResultedEvent) {
                    //         onBetSoloResultedEvent({ ...event.data, signature });
                    //     }
                    // } else if (event.name == "BetSoloUpdate") {
                    //     if (onBetSoloUpdateEvent) {
                    //         onBetSoloUpdateEvent({ ...event.data, signature })
                    //     }
                    // } else if (event.name == "GameInstanceUpdate") {
                    //     if (onGameInstanceUpdateEvent) {
                    //         onGameInstanceUpdateEvent({ ...event.data, signature })
                    //     }
                    // } else if (event.name == "GameInstanceClosed") {
                    //     if (onGameInstanceClosedEvent) {
                    //         onGameInstanceClosedEvent({ ...event.data, signature })
                    //     }
                } else {
                    console.warn(`INSTANCE PARSER - Not a known event --> `, event)
                }
            }
        };

        // const instanceTokenPubkey = this.publicKey
        const instanceIdx = this.deriveMultiInstanceIdx();

        const instanceMultiPubkey = PlayerToken.deriveInstanceMultiPubkey(
            this.publicKey,
            instanceIdx || 0,
            playerToken.baseProgram.programId
        );

        console.log(`STARTING POLLING INSTANCE`, {
            client,
            instanceMultiPubkey: instanceMultiPubkey.toString(),
            pubkeyFilter: gameInstancePubkey.toString()
        })

        // VARS USED IN WHILE LOOP
        let isFinished = false // HARD STOP
        let cycles = 0 // CHECK ON MAX CYCLES
        let stageOfCycle = 0 // WHERE IN CYCLE ARE WE - 0 = Looking for game instance, 1 = get logs for instance created, 2 = get logs for bet results 

        while (isFinished == false) {
            cycles += 1

            if (cycles > timeoutS) {
                onError("Too Many Tries To Get Game Result")

                return
            }

            if (stageOfCycle == 0) {
                // GET LOGS FOR INITIAL TX SIG
                const logs = await client.getParsedTransaction(lastSignature, { commitment: 'confirmed', maxSupportedTransactionVersion: 0 })

                if (logs == null) {
                    sleep(1_000)

                    continue
                } else {
                    const signatures = await client.getSignaturesForAddress(instanceMultiPubkey, {
                        until: lastSignature,
                    }, 'confirmed')

                    if (signatures == null || signatures.length == 0) {
                        handleLogs(logs, lastSignature)
                        stageOfCycle = 1

                        await sleep(1000)

                        continue
                    } else {
                        const parsedTxsWithMeta = await client.getParsedTransactions(signatures.map((signature) => {
                            return signature.signature
                        }), {
                            commitment: 'confirmed',
                            maxSupportedTransactionVersion: 0
                        })

                        parsedTxsWithMeta.forEach((parsedTx, index) => {
                            if (parsedTx == null && parsedTx.meta?.err != null) {
                                return
                            }

                            const signature = signatures[index].signature

                            handleLogs(parsedTx, signature)

                        })

                        isFinished = true
                    }
                }
            }

            if (stageOfCycle == 1) {
                // GOT THE ACCOUNT DATA
                // TIME TO GET SIGNATURES FOR ACCOUNT - WILL RESULT WILL BE IN THE NEXT SIG AFTER ONE PASSED...
                const signatures = await client.getSignaturesForAddress(gameInstancePubkey, {
                    until: lastSignature,
                }, 'confirmed')
                if (signatures.length == 0) {
                    await sleep(1000)

                    continue
                }

                const parsedTxsWithMeta = await client.getParsedTransactions(signatures.map((signature) => {
                    return signature.signature
                }), {
                    commitment: 'confirmed',
                    maxSupportedTransactionVersion: 0
                })

                parsedTxsWithMeta.forEach((parsedTx, index) => {
                    if (parsedTx == null || parsedTx.meta?.err != null) {
                        return
                    }

                    const signature = signatures[index].signature

                    handleLogs(parsedTx, signature)
                })

                isFinished = true
            }
        }
    }

    async closeOnLogsWsConnection(client: Connection, wsId: number) {
        try {
            await client.removeOnLogsListener(wsId);
        } catch (err) {
            console.warn("Issue closing GameSpec socket", err);
        }
    }
    static deriveInstanceTokenMultiAccountDiscriminator() {
        return Buffer.from(sha256.digest("account:InstanceTokenMulti")).subarray(0, 8);
    }

    deriveInstanceMultiPubkey(instanceIdx?: number): PublicKey {
        const instanceId = instanceIdx == null ? (this.deriveMultiInstanceIdx() || 0) : instanceIdx

        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("instance_multi"),
                this.publicKey.toBuffer(),
                new anchor.BN(instanceId).toArrayLike(Buffer, 'le', 1)

            ],
            this.programId
        );
        return pk
    };
}




