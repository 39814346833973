import { useMemo, useState } from "react";
import { WalletTab } from "../../modals/WalletModal";
import { useModal } from "react-modal-hook";
import WalletModal from "../../modals/WalletModal";

export const useWalletModal = () => {
  const [walletTab, setWalletTab] = useState<WalletTab>(WalletTab.FUND);
  const [showWalletModal, hideWalletModal] = useModal(
    ({ in: open }) => (
      <WalletModal
        open={open}
        closeModal={hideWalletModal}
        selectedTab={walletTab}
        setSelectedTab={setWalletTab}
      />
    ),
    [walletTab],
  );

  return useMemo(() => {
    return {
      walletTab: walletTab,
      setWalletTab: setWalletTab,
      showWalletModal: showWalletModal,
      hideWalletModal: hideWalletModal,
    };
  }, [walletTab]);
};
