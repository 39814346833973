import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { ZeebitV2 } from "./program-types/solana_zeebit_v2"; 
import { PublicKey, AccountMeta, Keypair, GetVersionedBlockConfig, MemcmpFilter, sendAndConfirmTransaction, ComputeBudgetProgram, TransactionInstruction } from "@solana/web3.js";
import { listenForTransaction } from "./utils";
import { APPROXIMATE_MS_PER_SLOT } from "./constants";
import nacl from "tweetnacl";
import * as base58 from 'bs58'
import GameSpec from "./gameSpec";
import { toHouseStatus } from "./utils";
import { DELEGATION_PROGRAM_ID, DelegateAccounts, MAGIC_PROGRAM_ID } from "@magicblock-labs/delegation-program";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { MAGIC_CONTEXT_ID } from "@magicblock-labs/ephemeral-rollups-sdk";

export default class House {

    private _erProgram: Program<ZeebitV2>;
    private _baseProgram: Program<ZeebitV2>;
    private _housePubkey: PublicKey;
    private _oracleListPubkey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["house"];
    private _baseState: anchor.IdlAccounts<ZeebitV2>["house"];
    private _oracleList: anchor.IdlAccounts<ZeebitV2>["houseOracleList"] | null;
    private _eventParser: anchor.EventParser;
    private _listenOnRollup: boolean;
    private _listenWebsocketId: number;
    private _keypair: Keypair;

    constructor(
        baseProgram: anchor.Program<ZeebitV2>,
        erProgram: anchor.Program<ZeebitV2>,
        housePubkey: PublicKey,
        keypair?: Keypair
    ) {
        this._baseProgram = baseProgram;
        this._erProgram = erProgram;
        this._housePubkey = housePubkey;
        this._oracleListPubkey = House.deriveHouseOracleListPubkey(this._housePubkey, this.baseProgram.programId);
        this._eventParser = new anchor.EventParser(
            this._baseProgram.programId,
            new anchor.BorshCoder(this._baseProgram.idl)
        );
        this._keypair = keypair;
    };

    static async load(
        baseProgram: anchor.Program<ZeebitV2>,
        erProgram: anchor.Program<ZeebitV2>,
        housePubkey: PublicKey,
        keypair?: Keypair,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const houseToken = new House(
            baseProgram,
            erProgram,
            housePubkey,
            keypair
        )
        await houseToken.loadBaseState(commitmentLevel);
        // await houseToken.loadOracleList(commitmentLevel);
        // if (houseToken.isDelegated) {
        //     houseToken.loadErState(commitmentLevel);
        // }
        return houseToken
    };

    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.house.fetchNullable(
            this._housePubkey,
            commitmentLevel
        );
        if (state) {
            this._baseState = state;
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.erProgram.account.house.fetchNullable(
            this._housePubkey,
            commitmentLevel
        );
        if (state) {
            this._erState = state;
        } else {
            // throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        return
    }

    async loadOracleList(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.houseOracleList.fetchNullable(
            this.oracleListPubKey,
            commitmentLevel
        );
        if (state) {
            this._oracleList = state;
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.oracleListPubKey}`)
        }
        return
    }

    static deriveHousePubkey(
        houseId: number,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house"),
                new anchor.BN(houseId).toArrayLike(Buffer, 'le', 8)
            ],
            programId
        );
        return pk
    };

    static deriveHouseOracleListPubkey(
        housePubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("oracle_list"),
                housePubkey.toBuffer(),
            ],
            programId
        );
        return pk
    };

    static deriverPermissionPubkey(
        housePubkey: PublicKey,
        authorityPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("permission"),
                housePubkey.toBuffer(),
                authorityPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    housePayerPubkey(): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_payer"),
                this.publicKey.toBuffer()
            ],
            this.programId
        );
        return pk
    };

    async predelegateUpdateSlipIx(
        payer: PublicKey,
        updateSlipPubkey: PublicKey,
        relatedAccountPubkey: PublicKey
    ): Promise<TransactionInstruction> {
            const {
                delegationPda,
                delegationMetadata,
                bufferPda,
                commitStateRecordPda,
                commitStatePda,
            } = DelegateAccounts(
                updateSlipPubkey, 
                this._baseProgram.programId
            );

            return await this.baseProgram.methods.updateSlipPredelegate(
                {}
            ).accounts({
                payer: payer,
                relatedAccount: relatedAccountPubkey,
                updateSlip: updateSlipPubkey,
                buffer: bufferPda,
                delegationRecord: delegationPda,
                delegationMetadata: delegationMetadata,
                ownerProgram: this.baseProgram.programId,
                delegationProgram: DELEGATION_PROGRAM_ID,
                systemProgram: anchor.web3.SystemProgram.programId
            }).instruction()
    };

    async applyPlayerTokenDepositIxn(
        payerPubkey: PublicKey,
        updateSlipPubkey: PublicKey,
        playerTokenPubkey: PublicKey,
        houseToken: PublicKey,
    ): Promise<TransactionInstruction> {  
        return await this.erProgram.methods.playerTokenDepositApply(
            {}
        ).accounts({
            payer: payerPubkey,
            updateSlip: updateSlipPubkey,
            playerToken: playerTokenPubkey,
            houseToken: houseToken,
            magicProgram: MAGIC_PROGRAM_ID,
            magicContext: MAGIC_CONTEXT_ID
        }).instruction();
    };

    async applyPlayerTokenWithdrawIxn(
        updateSlipPubkey: PublicKey,
        rentRecipientPubkey: PublicKey,
        ownerPubkey: PublicKey,
        playerTokenPubkey: PublicKey,
        houseTokenPubkey: PublicKey,
        houseTokenBankPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        vaultPubkey: PublicKey,
        tokenAccountPubkey: PublicKey
    ): Promise<TransactionInstruction> {  
        return await this.erProgram.methods.playerTokenWithdrawApply(
            {}
        ).accounts({
            updateSlip: updateSlipPubkey,
            rentRecipient: rentRecipientPubkey,
            owner: ownerPubkey,
            playerToken: playerTokenPubkey,
            houseToken: houseTokenPubkey,
            houseTokenBank: houseTokenBankPubkey,
            tokenMint: tokenMintPubkey,
            vault: vaultPubkey,
            tokenAccount: tokenAccountPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();
    };

    async closeUpdateSlipIxn(
        updateSlipPubkey: PublicKey,
        rentRecipientPubkey: PublicKey,
    ): Promise<TransactionInstruction> {  
        return await this.baseProgram.methods.updateSlipClose(
            {}
        ).accounts({
            updateSlip: updateSlipPubkey,
            rentRecipient: rentRecipientPubkey
        }).instruction();
    };

    async undelegateUpdateSlipIxn(
        updateSlipPubkey: PublicKey,
        payerPubkey: PublicKey,
    ): Promise<TransactionInstruction> {  
        return await this.erProgram.methods.updateSlipUndelegate(
            {}
        ).accounts({
            updateSlip: updateSlipPubkey,
            payer: payerPubkey,
            magicProgram: MAGIC_PROGRAM_ID,
            magicContext: MAGIC_CONTEXT_ID
        }).instruction();
    };

    get oracleListPubkey() {
        return House.deriveHouseOracleListPubkey(this.publicKey, this.programId)
    }

    get baseProgram() {
        return this._baseProgram
    }

    get erProgram() {
        return this._erProgram
    }

    get programId() {
        return this._baseProgram.programId
    }

    get publicKey() {
        return this._housePubkey
    }

    get oracleListPubKey() {
        return this._oracleListPubkey
    }

    get eventParser() {
        return this._eventParser
    }

    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get oracelList() {
        return this._oracleList
    }

    get status() {
        return this.baseState ? toHouseStatus(this.baseState.status) : null;
    }

    get isDelegated() {
        // TODO - GET DELEGATED STATUS
        return this.baseState != null && Object.keys(this.baseState.status)[0] != "active"
    }
    async tryGetBlockhash(
        program: anchor.Program,
        slotNumber: number
    ): Promise<Buffer | null> {
        // TODO: Retry logic
        try {
            const blockInfo = await program.provider.connection.getBlock(
                slotNumber,
                {
                    commitment: "confirmed",
                    transactionDetails: "none",
                    maxSupportedTransactionVersion: 0,
                    rewards: false
                } as GetVersionedBlockConfig
            );
            const blockhashString = blockInfo.blockhash;
            const blockhashBytes = blockhashString ? base58.decode(blockhashString) : null;
            return Buffer.from(blockhashBytes)
        } catch {
            return null
        }
    };

    async waitForBlockhash(
        program: anchor.Program,
        slotNumber: number
    ): Promise<[number, Buffer]> {
        var blockash: Buffer = null;
        var currentSlot: number;
        var slotUsed = slotNumber;
        do {
            currentSlot = await program.provider.connection.getSlot("confirmed");
            console.log(`[${(new Date()).toISOString().slice(11, 19)}] Waiting for blockhash at slot ${slotNumber} (Current slot: ${currentSlot})`)
            const slotAway = slotNumber - currentSlot;
            const waitTimeMs = APPROXIMATE_MS_PER_SLOT * slotAway * 0.9; // Be a little aggressive in rechecking
            if (waitTimeMs > 0) {
                await new Promise(f => setTimeout(f, waitTimeMs));
            }

            blockash = await this.tryGetBlockhash(program, slotNumber)

            if (currentSlot > slotNumber && blockash == null) {
                // OTHERWISE
                if (blockash == null) {
                    // TODO: Fix this -- temporary solution only
                    return [slotNumber, Buffer.from("nHJBQHj6KRQ8Jcm98iMxBX5Sb2yE25xKhEX4SRBNynY")]
                    // return Promise.reject(`Blockhash not found for slot currentSlot: ${currentSlot}, slot we want: ${slotNumber}`)
                }
            }
        } while (blockash == null);
        console.log(`[${(new Date()).toISOString().slice(11, 19)}] Got blockhash for ${slotUsed} `)
        return [slotUsed, blockash];
    };

    async getOpenSoloInstances(
        commitmentLevel: anchor.web3.Commitment = "processed",
    ): Promise<{ key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] }[]> {
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        let f = await program.account.instanceSolo.all(
            // [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as MemcmpFilter ],     // For this GameSpec
        );
        var instanceList: { key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] }[] = [];
        console.log(f);
        f.forEach((pb) => {
            instanceList.push(
                { key: pb.publicKey, account: pb.account }
            )
        })
        return instanceList
    }

    async getOpenMultiInstances(
        commitmentLevel: anchor.web3.Commitment = "processed",
    ): Promise<{ key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceMulti"] }[]> {
        console.log('getOpenMultiInstances');
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        let f = await program.account.instanceMulti.all(
            // [{ memcmp: { offset: 8, bytes: this.publicKey.toBase58() } } as MemcmpFilter ],     // For this GameSpec
        );
        var instanceList: { key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceMulti"] }[] = [];
        f.forEach((pb) => {
            instanceList.push(
                { key: pb.publicKey, account: pb.account }
            )
        })
        return instanceList
    }

    async getOpenMultiInstanceTokens(
        instanceMultiPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed",
    ): Promise<{ key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceTokenMulti"] }[]> {
        const program = this._listenOnRollup ? this.erProgram : this.baseProgram;
        let f = await program.account.instanceTokenMulti.all(
            [{ memcmp: { offset: 8, bytes: instanceMultiPubkey.toBase58() } } as MemcmpFilter],     // For this InstanceMulti
        );
        var instanceTokenList: { key: PublicKey, account: anchor.IdlAccounts<ZeebitV2>["instanceTokenMulti"] }[] = [];
        f.forEach((pb) => {
            instanceTokenList.push(
                { key: pb.publicKey, account: pb.account }
            )
        })
        return instanceTokenList
    }

    static async getDiscriminator(accountName) {
        const encoder = new TextEncoder();
        const data = encoder.encode(`account:${accountName}`);
        
        // Generate the SHA-256 hash
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        
        // Convert the ArrayBuffer to a Uint8Array and extract the first 8 bytes
        const hashArray = new Uint8Array(hashBuffer).subarray(0, 8);
        
        return hashArray;
      }
}



