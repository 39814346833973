import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import SolanaRpc from "../utils/solana/rpc";
import { Connection, PublicKey } from "@solana/web3.js";
import { NATIVE_MINT, getAssociatedTokenAddress, unpackAccount } from "@solana/spl-token";
import { SOL_DECIMALS, USDC_DECIMALS, USDC_MINT } from "../constants/sol";
import { ICasinoToken } from "../types/token";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { WrappedWalletContext } from "./WrappedWalletContext";
import PlayerToken from "../sdk/playerToken";
import HouseToken from "../sdk/houseToken";
import { NetworkContext } from "./NetworkContext";
import { getTokenMint } from "../sdk/constants";

// -- TODO --
// Decide how to manage refreshing different balances
// Native token, primary token, secondary tokens

export interface IPrimaryMeta {
  mint: string;
  decimals: number;
}

export interface IBalanceContext {
  // SOLANA
  solBalances: IChainBalances | undefined;
  selectedTokenMeta: IPrimaryMeta;
  setSelectedTokenMeta: React.Dispatch<React.SetStateAction<IPrimaryMeta>>;
  loadedBalances: boolean;
  acceptingUpdates: boolean
  setAcceptingUpdates: React.Dispatch<React.SetStateAction<boolean>>
  balancesByMint: Map<string, IChainBalance> | undefined
}

export interface ITokenCheckMeta {
  playerToken: PlayerToken | undefined;
  houseToken: HouseToken | undefined;
  context: ICasinoToken | undefined;
}

export interface IChainBalance {
  decimals?: number | undefined;
  basis: number;
  uiAmount: number;
  identifier: string;
  meta?: ITokenCheckMeta; // USED FOR DETAILS ON CONTEXT, PLATFORM AND HOUSE TOKEN INFO
  uiAmountBase?: number;
}

export interface IChainBalances {
  native: IChainBalance | undefined;
  primary: IChainBalance | undefined;
  secondaries: IChainBalance[] | undefined;
}

export const BalanceContext = createContext<IBalanceContext>({} as IBalanceContext);

interface Props {
  children: any;
}

export const BalanceProvider = ({ children }: Props) => {
  const [acceptingUpdates, setAcceptingUpdates] = useState(true)
  const { solanaRpc, walletPubkey } = useContext(WrappedWalletContext);
  const [loadedBalances, setLoadedBalances] = useState(false);
  const { chain, platformTokens, client } = useContext(NetworkContext);

  const [selectedTokenMeta, setSelectedTokenMeta] = useLocalStorage("zeebit-selected-token", '');

  useEffect(() => {
    // ENSURE MINT IS IN CONFIG FILE
    if (selectedTokenMeta != null && selectedTokenMeta != '' && selectedTokenMeta.mint != null) {
      const tokenConfig = platformTokens.find((token) => {
        return token.pubkey == selectedTokenMeta.mint;
      });

      if (tokenConfig == null) {
        const tokenMint = getTokenMint(chain)

        setSelectedTokenMeta({
          mint: tokenMint?.toString(),
          decimals: USDC_DECIMALS,
        });
      }
    } else {
      const tokenMint = getTokenMint(chain)

      setSelectedTokenMeta({
        mint: tokenMint?.toString(),
        decimals: USDC_DECIMALS,
      });
    }
  }, [selectedTokenMeta, platformTokens, chain]);

  // SOLANA
  const [nativeSolBalance, setNativeSolBalance] = useState<IChainBalance>();
  const [primarySolBalance, setPrimarySolBalance] = useState<IChainBalance>();

  const [tokenBalances, setTokenBalances] = useState<IChainBalance[]>();
  const [solBalances, setSolBalances] = useState<IChainBalances | undefined>();

  useEffect(() => {
    // IF ACCEPTING UPDATES SET MOST RECENT VALUE, OTHERWISE USE PREVIOUS
    
    if (acceptingUpdates == true) {
      setSolBalances({
        primary: selectedTokenMeta.mint == NATIVE_MINT.toString() ? nativeSolBalance: primarySolBalance,
        native: nativeSolBalance,
        secondaries: tokenBalances,
      })
    } else {
      // WAIT UNTIL ACCEPTING UPDATES AGAIN...
    }
  }, [nativeSolBalance, primarySolBalance, tokenBalances, acceptingUpdates, selectedTokenMeta])

  const nativeSolSub = useRef<number>();
  const primarySolSub = useRef<number>();

  useEffect(() => {
    async function openPrimaryWsConnection(wallet: PublicKey, primary: IPrimaryMeta, connection: Connection) {
      if (primarySolSub.current != null) {
        await closePrimaryWsConnections(connection);
      }

      try {
        const ata = await getAssociatedTokenAddress(new PublicKey(primary.mint), wallet);

        const primarySolSubscription = connection.onAccountChange(
          ata,
          async (updatedAccountInfo) => {
            const account = unpackAccount(ata, updatedAccountInfo);
            const tokenBasis = Number(account.amount);

            setPrimarySolBalance({
              identifier: primary.mint.toString(),
              basis: tokenBasis,
              uiAmount: tokenBasis / Math.pow(10, primary.decimals),
              decimals: primary.decimals,
            });
          },
          "confirmed",
        );
        primarySolSub.current = primarySolSubscription;
      } catch (err) {
        setPrimarySolBalance({
          identifier: primary.mint.toString(),
          basis: 0,
          uiAmount: 0,
          decimals: primary.decimals,
        });
      }
    }

    async function openSolWsConnection(wallet: PublicKey, connection: Connection) {
      if (nativeSolSub.current != null) {
        await closeSolWsConnections(connection);
      }

      const lamportSubscription = connection.onAccountChange(
        wallet,
        (updatedAccountInfo) => {
          setNativeSolBalance({
            identifier: NATIVE_MINT.toString(),
            basis: updatedAccountInfo.lamports,
            uiAmount: updatedAccountInfo.lamports / Math.pow(10, SOL_DECIMALS),
            decimals: SOL_DECIMALS,
          });
        },
        "confirmed",
      );
      nativeSolSub.current = lamportSubscription;
    }

    async function closeSolWsConnections(connection: Connection) {
      try {
        if (nativeSolSub.current != null) {
          await connection?.removeAccountChangeListener(nativeSolSub.current);
          nativeSolSub.current = undefined;
        }
      } catch (err) {
        console.warn("Issue closing lamport listener", err);
      }
    }

    async function closePrimaryWsConnections(connection: Connection) {

      try {
        if (primarySolSub.current != null) {
          await connection?.removeAccountChangeListener(primarySolSub.current);
          primarySolSub.current = undefined;
        }
      } catch (err) {
        console.warn("Issue closing primary listener", err);
      }
    }

    async function loadInitialBalances(rpc: SolanaRpc, primaryMeta: IPrimaryMeta) {
      try {
        let native = await rpc.getLamportBalance();
        setNativeSolBalance(native);
      } catch (err) {
        console.warn("Issue loading initial sol balance. ", err);
        setNativeSolBalance({
          decimals: 9,
          uiAmount: 0,
          identifier: NATIVE_MINT.toString(),
          basis: 0,
        });
      }

      try {
        let primary = await rpc.getTokenBalance(
          new PublicKey(primaryMeta.mint),
          primaryMeta.decimals,
        );

        setPrimarySolBalance(primary);
      } catch (err) {
        console.warn("Issue loading initial primary balance.");

        setPrimarySolBalance({
          identifier: primaryMeta.mint?.toString(),
          decimals: primaryMeta.decimals,
          basis: 0,
          uiAmount: 0,
          uiAmountBase: 0,
        });
      }

      try {
        const balances = await rpc.getAssociatedTokenBalances();
        setTokenBalances(balances);
      } catch (e) {
        console.warn("Issue loading the token balances", e);
        setTokenBalances([]);
      }

      setLoadedBalances(true);
    }

    async function openWsConnections(wallet: PublicKey, primaryMeta: IPrimaryMeta, connection: Connection) {
      try {
        await openSolWsConnection(wallet, connection);
      } catch (err) {
        console.warn("Issue opening ws connection to watch sol balance.", err);
      }

      try {
        await openPrimaryWsConnection(wallet, primaryMeta, connection);
      } catch (err) {
        console.warn("Issue opening ws connection to watch usdc balance.", err);
      }
    }

    if (solanaRpc != null && walletPubkey != null && client != null && selectedTokenMeta != null) {
      loadInitialBalances(solanaRpc, selectedTokenMeta);

      openWsConnections(walletPubkey, selectedTokenMeta, client);
    } else {
      setNativeSolBalance(undefined);
      setPrimarySolBalance(undefined);

      closeSolWsConnections(client);
      closePrimaryWsConnections(client);
    }

    return () => {
      closeSolWsConnections(client);
      closePrimaryWsConnections(client);
    };
  }, [solanaRpc, walletPubkey, client, selectedTokenMeta]);


  const { walletValidation } = useContext(ErrorHandlingContext);

  // MINIMUM LAMPORTS CHECK - Alert above button - insufficient gas for transaction
  useEffect(() => {
    if (loadedBalances == true && nativeSolBalance != null && nativeSolBalance.basis < 5000) {
      walletValidation.addErrorMessage({
        type: ErrorType.INSUFFICIENT_SOL_FOR_TX,
        title: "Insuficcient Sol For Tx",
        message: "You do not have enough sol in your wallet to send a transaction.",
      });
    } else {
      walletValidation.removeErrorMessage(ErrorType.INSUFFICIENT_SOL_FOR_TX);
    }
  }, [nativeSolBalance, loadedBalances]);

  const balancesByMint = useMemo(() => {
    const balanceByMint = tokenBalances?.reduce((result, item) => {
      result.set(item.identifier, item)
      
      return result
    }, new Map<string, IChainBalance>)

    // SET PRIMARY AND NATIVE HERE
    if (solBalances?.primary != null) {
      balanceByMint?.set(solBalances?.primary?.identifier, solBalances?.primary)
    }

    if (solBalances?.native != null) {
      balanceByMint?.set(solBalances?.native?.identifier, solBalances?.native)
    }

    return balanceByMint
  }, [tokenBalances, solBalances])


  return (
    <BalanceContext.Provider
      value={useMemo(
        () => ({
          solBalances: solBalances,
          selectedTokenMeta: selectedTokenMeta,
          setSelectedTokenMeta: setSelectedTokenMeta,
          loadedBalances: loadedBalances,
          acceptingUpdates: acceptingUpdates,
          setAcceptingUpdates: setAcceptingUpdates,
          balancesByMint: balancesByMint
        }),
        [solBalances, selectedTokenMeta, loadedBalances, acceptingUpdates, setAcceptingUpdates, balancesByMint],
      )}
    >
      {children}
    </BalanceContext.Provider>
  );
};
