import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import Icon from "../icon/Icon";
import IconFont from "../iconFont/IconFont";
import ArrowDown from "../../../assets/icons/arrow-down.svg";

interface IDropdownItem {
  name: string;
  value: string;
  icon?: string;
  selectedIcon?: string;
  iconFont?: string;
  isDisabled?: boolean;
  className?: string;
}
export type DropdownSize = "md" | "sm";

interface IDropdown {
  items: IDropdownItem[];
  itemComponent?: Function;
  triggerButton?: Function;
  containerStyles?: string;
  disabled?: boolean;
  onSelect?: (item: IDropdownItem) => void;
  size?: DropdownSize;
  classes?: {
    input?: string;
    menu?: string;
  };
  initialSelectedItem?: IDropdownItem
}
const Dropdown: React.FC<IDropdown> = ({
  items,
  itemComponent,
  triggerButton,
  onSelect,
  containerStyles,
  size,
  classes,
  initialSelectedItem,
  disabled
}) => {
  const [selectedItem, setSelectedItem] = useState<IDropdownItem>();

  useEffect(() => {
    if (selectedItem == null) {
      setSelectedItem(initialSelectedItem ? initialSelectedItem : items[0]);
    }
  }, [items, initialSelectedItem]);

  const getSizeClasses = () => {
    switch (size) {
      case "sm": {
        return "h-[34px]";
      }
      case "md": {
        return "h-[40px]";
      }
      default: {
        return "h-[40px]";
      }
    }
  };
  return (
    <div
      data-id="dropdown"
      className={twMerge(
        "sm:w-[180px]",
        containerStyles || ""
      )}
    >
      <Menu
        as="div"
        className="relative flex items-start sm:-space-x-1 ring-0 w-full cursor-pointer"
      >
        {({ open }) => (
          <>
            <Menu.Button
              as="div"
              className={twMerge(
                "flex relative w-full py-0 px-1 sm:px-3 items-center justify-between rounded-md",
                "sm:rounded-s-md border-solid bg-gray-500 z-10",
                getSizeClasses(),
                classes?.input,
              )}
              disabled={disabled}
            >
              {triggerButton?.({ open, selectedItem }) || (
                <>
                  <div className="flex items-center space-x-1.5">
                    <div
                      hidden={!selectedItem?.icon && !selectedItem?.iconFont}
                      className="trigger-button-icon hidden sm:block"
                    >
                      {[
                        selectedItem?.iconFont && (
                          <IconFont name={selectedItem?.iconFont} iconSize={16} />
                        ),
                        (selectedItem?.icon || selectedItem?.selectedIcon)
                        && (
                          <img
                            className="w-[16px] h-[16px] text-white"
                            src={selectedItem?.selectedIcon || selectedItem.icon}
                          />
                        ),
                      ]}
                    </div>
                    <div className="text-sm text-slate-50">{selectedItem?.name}</div>
                  </div>
                  <div className="flex items-center">
                    <Icon
                      size="xl"
                      icon={
                        <ArrowDown
                          className={`transition-transform ${open ? "rotate-180 mt-1 me-n2" : ""}`}
                        />
                      }
                      className={open ? "rotate-180" : ""}
                    />
                  </div>
                </>
              )}
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                as="div"
                className={`
                  flex min-w-[140px] py-1.5 px-0 flex-col justify-center items-center z-20
                  bg-gray-500 absolute right-0 mt-[48px] origin-top-right rounded-md
                  ${classes?.menu}
                `}
              >
                <div className="w-full flex flex-col p-0 items-center rounded-lg">
                  {items.map((item, itemIndex) => (
                    <Menu.Item
                      key={itemIndex}
                      as="div"
                      onClick={() => {
                        if (item?.isDisabled !== true) {
                          setSelectedItem(item);
                          onSelect?.(item);
                        }
                      }}
                      className={twMerge(
                        "flex py-1 items-start self-stretch cursor-pointer",
                        item?.className || ""
                      )}
                    >
                      {({ active }) => (
                        <>
                          {(itemComponent as Function)?.({
                            active,
                            item,
                            selected: selectedItem?.value,
                          }) || (
                              <button
                                disabled={item?.isDisabled || selectedItem?.value === item.value}
                                className={twMerge(
                                  "w-full py-1 mx-1 rounded text-white text-gray-900 ",
                                  item?.isDisabled ? "" : "hover:bg-gray-400",
                                  selectedItem?.value === item.value ? "bg-gray-400" : "text-gray-900"
                                )}
                              >
                                {/*<div className={`${disabled ? "text-gray-400" : ""}` >*/}
                                <div className="flex items-center ps-4 gap-x-2 pt-1 text-slate-50">
                                  {(item?.icon || item?.iconFont) && (
                                    <div>
                                      {[
                                        item?.iconFont && <IconFont name={item.iconFont} size="md" />,
                                        item?.icon && (
                                          <img src={item.icon} className="w-[16px] h-[16px]" />
                                        ),
                                      ]}
                                    </div>
                                  )}
                                  <div className="text-sm text-slate-50">
                                    {item.name}
                                  </div>
                                </div>
                              </button>
                            )}
                        </>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default Dropdown;
