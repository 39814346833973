import { APP_NETWORK_TYPE } from "../../types/chain";

// ALL ENV VARIABLE IMPORTED HERE

export const ENV_NAME: string = process.env.ENV_NAME?.toUpperCase() || "DEVNET"; // DEVNET, MAINNET
export const RPC_READ_ENDPOINT = process.env.RPC_READ_ENDPOINT // RPC TO USE FOR LOADING STATE + WSS
export const RPC_WRITE_ENDPOINTS = process.env.RPC_WRITE_ENDPOINTS // RPCs TO USE FOR SENDING TXNS: , seperated list
export const WEB3_AUTH_REDIRECT = process.env.WEB3_AUTH_REDIRECT || "http://localhost:8081" // IF USING WEB3 Auth Redirect URL
export const APP_NETWORK: APP_NETWORK_TYPE = process.env.APP_NETWORK || APP_NETWORK_TYPE.MB_AND_SOLANA // CHAIN MB_AND_SOLANA, OR SONIC

// IF ALLOWING MULTIPLE CHAINS
export const getRpcReadEndpoint = (chain: APP_NETWORK_TYPE) => {
    return RPC_READ_ENDPOINT
}

export const getRpcWriteEndpoints = (chain: APP_NETWORK_TYPE) => {
    return RPC_WRITE_ENDPOINTS.split(',')
}

export const PRIORITY_FEES_ENDPOINT: string = process.env.PRIORITY_FEES_ENDPOINT // ON MAINNET - ENDPOINT USED FOR PRIORITY FEES (SAMPLE OF SENDING TXN USES HELIUS)
export const BETTING_PLATFORM_ID: number = Number(process.env.BETTING_PLATFORM_ID)
export const DIVVY_API_URL = process.env.DIVVY_API_URL || "https://api.divvy.haus";
