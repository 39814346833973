import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";

export const Countdown: React.FC<{ targetDate: Date, onExpire?: () => any }> = ({ targetDate, onExpire = () => {} }) => {
  const timer = useTimer({
    expiryTimestamp: targetDate || new Date(),
    onExpire,
  });

  useEffect(() => {
    if (
      timer && !timer.isRunning && targetDate
      && new Date() < targetDate
    ) {
      // Restarts timer once targetDate was set
      timer.restart(targetDate, true);
    }
  }, [targetDate]);

  return (
    timer.isRunning
    && (
      timer.days > 0
        ? `${timer.days} ${timer.days === 1 ? "Day" : "Days"}`
        : `
          ${timer.days > 0 ? `${timer.days}d ` : ""}
          ${
          timer.hours > 9 ? timer.hours : `0${timer.hours}`
        }:${
          timer.minutes > 9 ? timer.minutes : `0${timer.minutes}`
        }:${
          timer.seconds > 9 ? timer.seconds : `0${timer.seconds
          }
        `}
       `
    )
  );
};
