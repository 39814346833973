import React, { FC, PropsWithChildren } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { twMerge } from "tailwind-merge";
import "./styles.css";
import { OverlayScrollbars, ScrollbarsHidingPlugin } from "overlayscrollbars";

OverlayScrollbars.plugin(ScrollbarsHidingPlugin);

export interface ScrollbarProps {
  className?: string;
  handleColor?: string;
  vertical?: boolean;
}

const Scrollbar: FC<PropsWithChildren<ScrollbarProps>> = ({
  children,
  className,
  handleColor = "var(--gray-450)",
  vertical,
}) => {
  return (
    <OverlayScrollbarsComponent
      defer
      options={{}}
      className={twMerge(vertical ? "Scrollbar-vertical " : "Scrollbar ", className)}
      data-overlayscrollbars-viewport="scrollbarHidden"
      style={
        {
          "--custom-handle-color": handleColor,
        } as React.CSSProperties
      }
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};

export default Scrollbar;
