import { useMemo } from "react";
import Icon from "../../common/icon/Icon";
import { BaseToast } from "../BaseToast";
import AirdropIcon from "./../../../assets/icons/airdrop.svg";

export interface IAirdrop {
  type: "success" | "error";
  token?: {
    icon: any;
    amount: number;
  };
  message?: string;
}

export const Airdrop = ({ type, token, message }: IAirdrop) => {
  const meta = useMemo(() => {
    if (type == "error") {
      return {
        title: undefined,
        message: message != null ? message : "Airdrop failed.",
      };
    } else {
      return {
        title: "Airdrop Successful",
        message: (
          <div className="flex items-center gap-x-1">
            <div className="h-[16px] w-[16px]">{token?.icon}</div>
            <div className="font-normal text-gray-300">{`${token?.amount || 0} Received`}</div>
          </div>
        ),
      };
    }
  }, [type, token]);
  return <BaseToast title={meta.title} message={meta.message} type={type} iconName="download" />;
};
