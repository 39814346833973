import {
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { PublicKey } from "@solana/web3.js";
import { ISolanaRpc } from "../utils/solana/rpc";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { Wallet, useWallet } from "@solana/wallet-adapter-react";
import NonSocialSolanaRpc from "../utils/solana/rpc-non-social";
import { NetworkContext } from "./NetworkContext";
import { WalletName } from "@solana/wallet-adapter-base";
import { getRpcWriteEndpoints } from "../utils/env/env";
import { APP_NETWORK_TYPE } from "../types/chain";
import { getEnabledWalletsForChain } from "../constants/web3Wallet";
import { Wallets } from "../constants/wallets";
import { useLocalStorage } from "../hooks/useLocalStorage";

export interface IWrappedWalletContext {
  // SOLANA
  loggedIn: boolean;
  connect: (wallet: string, provider?: string) => Promise<void>;
  disconnect: () => Promise<void>;
  solanaRpc: ISolanaRpc | undefined;
  walletPubkey: PublicKey | null;
  wallets: Wallet[];
  select: (walletName: WalletName | null) => void;
  wallet: Wallet | null;
  connected: boolean;
  connecting: boolean;
  isWeb3AuthWallet: boolean;
  selectedWalletName: Wallets | null;
  setSelectedWalletName: Function;
}

export const WrappedWalletContext = createContext<IWrappedWalletContext>(
  {} as IWrappedWalletContext
);

interface Props {
  children: any;
}

export const WrappedWalletProvider = ({ children }: Props) => {
  const { walletValidation } = useContext(ErrorHandlingContext);

  const [selectedWalletName, setSelectedWalletName] = useLocalStorage("zeebit-connected-wallet-name", null);
  const nonSocialWallet = useWallet();

  const isWeb3AuthWallet = useMemo(() => {
    return false // used to check if we use session signer or not
  }, []);

  // CHECK WALLET CONNECTED - Change label of button and disable button (Connect to bet)
  useEffect(() => {
    if (
      nonSocialWallet.publicKey == null
    ) {
      walletValidation.addErrorMessage({
        type: ErrorType.WALLET_NOT_CONNECTED,
        title: "Wallet Not Connected",
        message: "You must connect your wallet place a bet.",
      });
    } else {
      walletValidation.removeErrorMessage(ErrorType.WALLET_NOT_CONNECTED);
    }
  }, [nonSocialWallet.publicKey]);

  const { client, chain } = useContext(NetworkContext);
  const rpc = useMemo(() => {
   if (nonSocialWallet.publicKey != null && client != null && nonSocialWallet.wallet != null) {
      // WANT TO RETURN THE RPC CLASS
      const writeEndpoints = getRpcWriteEndpoints(chain);

      return new NonSocialSolanaRpc(
        nonSocialWallet,
        client,
        writeEndpoints || []
      );
    }
  }, [nonSocialWallet, client, chain]);

  // CHECK IF THE WALLET IS ENABLED WHEN CHAIN CHANGES
  useEffect(() => {
    async function disconnectIfNotEnabled(
      chain: APP_NETWORK_TYPE,
      walletName: string
    ) {
      const enabledWallets = getEnabledWalletsForChain(chain);

      if (!enabledWallets?.includes(walletName)) {
        console.warn(`Disconnecting as wallet not connected`);
        await nonSocialWallet.disconnect();
      }
    }

    if (
      chain != null &&
      nonSocialWallet.wallet?.adapter.name &&
      nonSocialWallet.connected == true
    ) {
      disconnectIfNotEnabled(chain, nonSocialWallet.wallet?.adapter.name);
    }
  }, [chain, nonSocialWallet.wallet?.adapter.name, nonSocialWallet.connected, nonSocialWallet.disconnect]);

  return (
    <WrappedWalletContext.Provider
      value={useMemo(
        () => ({
          loggedIn: nonSocialWallet.connected,
          solanaRpc: rpc,
          walletPubkey: nonSocialWallet.publicKey,
          wallets: nonSocialWallet.wallets,
          select: nonSocialWallet.select,
          wallet: nonSocialWallet.wallet,
          disconnect: nonSocialWallet.disconnect,
          connect: nonSocialWallet.connect,
          connected: nonSocialWallet.connected,
          connecting: nonSocialWallet.connecting,
          isWeb3AuthWallet: isWeb3AuthWallet,
          selectedWalletName,
          setSelectedWalletName
        }),
        [
          nonSocialWallet.connect,
          nonSocialWallet.disconnect,
          rpc,
          nonSocialWallet.publicKey,
          nonSocialWallet.connected,
          nonSocialWallet.wallets,
          nonSocialWallet.select,
          nonSocialWallet.wallet,
          nonSocialWallet.connecting,
          isWeb3AuthWallet,
          selectedWalletName,
          setSelectedWalletName
        ]
      )}
    >
      {children}
    </WrappedWalletContext.Provider>
  );
};
