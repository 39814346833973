// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToolTip .tippy-content {
  background-color: var(--gray-500);
  border-radius: 6px;
  padding: 8px 12px;
  font-weight: 400;
}
.ToolTip .tippy-arrow {
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/tooltip/style.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".ToolTip .tippy-content {\n  background-color: var(--gray-500);\n  border-radius: 6px;\n  padding: 8px 12px;\n  font-weight: 400;\n}\n.ToolTip .tippy-arrow {\n  color: var(--gray-500);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
