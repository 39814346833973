import { APP_NETWORK_TYPE } from "../types/chain";
import { defaultNetwork, NetworkType } from "../utils/chain/network";
import { IS_MAINNET } from "../utils/solana/rpc";

export const networksData: {
  [key in APP_NETWORK_TYPE]: {
    name: string,
    icon: string,
    hoverIcon?: string
  }
} = {
  [APP_NETWORK_TYPE.SOLANA]: {
    name: "Solana",
    icon: "/static/networks-icons/sol-white.png",
    hoverIcon: "/static/networks-icons/sol-black.png"
  },
  // },
  [APP_NETWORK_TYPE.MB_AND_SOLANA]: {
    name: "Solana",
    icon: "/static/networks-icons/sol-white.png",
    hoverIcon: "/static/networks-icons/sol-black.png"
  },
};

// ONLY ON MB FOR NOW
export const ENABLED_NETWORKS = [APP_NETWORK_TYPE.MB_AND_SOLANA]
