interface ITabs {
  selectedTab: any;
  updateSelectedTab: Function;
  tabs: any;
  classes?: {
    containerClasses?: string;
    tabClasses?: string;
    activeTabClasses?: string;
  };
}

export const Tabs = ({ selectedTab, updateSelectedTab, tabs, classes }: ITabs) => {
  return (
    <div
      className={`
        flex items-end self-stretch rounded-lg border-2 border-solid border-gray-700
        ${classes?.containerClasses || ""}
      `}
    >
      {Object.values(tabs).map((value, index) => {
        return (
          <Tab
            key={index}
            isActive={selectedTab == value}
            text={value}
            classes={{
              tabClasses: classes?.tabClasses,
              activeTabClasses: classes?.activeTabClasses,
            }}
            onClick={() => {
              updateSelectedTab(value);
            }}
          />
        );
      })}
    </div>
  );
};

interface ITab {
  isActive: boolean;
  text: string;
  classes?: {
    tabClasses?: string;
    activeTabClasses?: string;
  };
  onClick: Function;
}

const Tab = ({ isActive, text, onClick, classes }: ITab) => {
  const isActiveClasses = classes?.activeTabClasses || "bg-white text-gray-800";

  return (
    <div
      onClick={onClick}
      className={`
        flex-1 cursor-pointer flex h-[36px] md:h-[44px] text-sm md:text-base py-0 px-2 md:px-6 justify-center items-center first:rounded-s-md last:rounded-e-md
        ${isActive ? isActiveClasses : "text-gray-50 bg-gray-800"}
        ${classes?.tabClasses || ""}
      `}
    >
      {text}
    </div>
  );
};
