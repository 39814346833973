import React, { FC } from 'react';
import Icon, { IconSizes } from '../icon/Icon';
import SpinnerImage from "../../../assets/icons/loading-spinner.svg";

export interface SpinnerProps {
    size?: IconSizes
}

const Spinner: FC<SpinnerProps> = ({ size }) => {
    return (
        <Icon
            inheritStroke={true}
            inheritFill={false}
            size={size}
            icon={<SpinnerImage />}
            className="shrink-0"
        />
    );
};

export default Spinner;