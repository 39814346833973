import Spinner from "../assets/icons/loading-spinner-gradient.svg";
import Icon from "../components/common/icon/Icon";

interface ILoading {
  title: string;
  message: string;
}

export const Loading = ({ title, message }: ILoading) => {
  return (
    <div className="flex w-[300px] h-[218px] md:w-[360px] md:h-[218px] flex-col justify-center items-center gap-y-6">
      <div>
        <Icon size="xxxl" className="[&>svg]:stroke-current mr-2" icon={<Spinner />} />
      </div>
      <div className="flex flex-col items-center gap-y-1">
        <div>{title}</div>
        <div className="font-normal text-gray-400">{message}</div>
      </div>
    </div>
  );
};
