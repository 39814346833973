import { useMemo } from "react";
import { BaseToast } from "./BaseToast";
import TransferIcon from "./../../assets/icons/arrows-up-down.svg";
import Icon from "../common/icon/Icon";

export interface ITransferToast {
  type: "success" | "error";
  token?: {
    icon: any;
    amount: number;
  };
}

export const TransferToast = ({ type, token }: ITransferToast) => {
  const meta = useMemo(() => {
    if (type == "error") {
      return {
        title: "Transfer failed",
        message: "Sorry there was an issue with the transfer.",
      };
    } else {
      return {
        title: "Transfer Successful",
        message: (
          <div className="flex items-center gap-x-1">
            <div className="h-[16px] w-[16px]">{token?.icon}</div>
            <div className="font-normal text-gray-300">{`${token?.amount || 0} Sent`}</div>
          </div>
        ),
      };
    }
  }, [type, token]);

  return (
    <BaseToast
      message={meta.message}
      title={meta.title}
      icon={<Icon icon={<TransferIcon />} />}
      type={type}
    />
  );
};
