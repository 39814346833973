import { createAvatar } from "@dicebear/core";
import { botttsNeutral } from "@dicebear/collection";
import { PublicKey } from "@solana/web3.js";

import { AvatarType } from "../../../hooks/useUserMeta";

export const generatePlayerAvatar = (playerPublicKey?: PublicKey) => {
  {
    const avatarSvgString = createAvatar(botttsNeutral, {
      seed: playerPublicKey?.toString(),
    });

    return {
      type: AvatarType.SVG_STRING,
      value: avatarSvgString.toString()
    };
  }
};